import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Uvod from "./pages/Uvod";
import PAGE_1_KATERI_SO_MOJI_POTENCIALI from "./pages/_1_KateriSoMojiPotenciali";
import NALOGA_1_1 from "./pages/_1_1_Naloga";
import PAGE_2_KATERE_SO_MOJE_VREDNOTE from "./pages/_2_KatereSoMojeVrednote";
import NALOGA_2_1 from "./pages/_2_1_Naloga";
import NALOGA_2_2 from "./pages/_2_2_Naloga";
import NALOGA_2_3 from "./pages/_2_3_Naloga";
import NALOGA_2_4 from "./pages/_2_4_Naloga";
import PAGE_3_KAJ_JE_MOJE_SPOROCILO from "./pages/_3_KajJeMojeSporocilo";
import NALOGA_3_1 from "./pages/_3_1_Naloga";
import PAGE_4_KAJ_JE_MOJ_MOTO from "./pages/_4_KajJeMojMoto";
import NALOGA_4_1 from "./pages/_4_1_Naloga";
import PAGE_5_OSEBNA_KVANTNA_STRUKTURA from "./pages/_5_OsebnaKvantnaStruktura";
import NALOGA_5_1 from "./pages/_5_1_Naloga";
import NALOGA_5_2 from "./pages/_5_2_Naloga";
import NALOGA_5_3 from "./pages/_5_3_Naloga";
import NALOGA_5_4 from "./pages/_5_4_Naloga";
import NALOGA_5_5 from "./pages/_5_5_Naloga";
import NALOGA_5_6 from "./pages/_5_6_Naloga";
import PAGE_6_SEST_KVANTNIH_VLOG from "./pages/_6_SestKvantnihVlog";
import NALOGA_6_1 from "./pages/_6_1_Naloga";
import NALOGA_6_2 from "./pages/_6_2_Naloga";
import NALOGA_6_3 from "./pages/_6_3_Naloga";
import NALOGA_6_4 from "./pages/_6_4_Naloga";
import PAGE_7_SPODBUJAJ_RADOVEDNOST from "./pages/_7_SpodbujajRadovednost";
import NALOGA_7_1 from "./pages/_7_1_Naloga.js";
import NALOGA_7_2 from "./pages/_7_2_Naloga.js";
import PAGE_8_PREVZEMITE_ODGOVORNOST from "./pages/_8_PrevzemiteOdgovornost.js";
import NALOGA_8_1 from "./pages/_8_1_Naloga.js";
import NALOGA_8_2 from "./pages/_8_2_Naloga.js";
import PAGE_9_USTVARJALNI_IMAGINARIUM from "./pages/_9_UstvarjalniImaginarium";
import NALOGA_9_1 from "./pages/_9_1_Naloga.js";
import PAGE_10_UPORABA_MENTALNIH_ORODIJ from "./pages/_10_UporabaMentalnihOrodij";
import NALOGA_10_1 from "./pages/_10_1_Naloga.js";
import NALOGA_10_2 from "./pages/_10_2_Naloga.js";
import PAGE_11_KREIRANJE_CUDOVITEGA_UMA from "./pages/_11_KreiranjeCudovitegaUma";
import NALOGA_11_1 from "./pages/_11_1_Naloga.js";
// import NALOGA_11_2 from "./pages/_11_2_Naloga.js";
import NALOGA_11_3 from "./pages/_11_3_Naloga.js";
import NALOGA_11_4 from "./pages/_11_4_Naloga.js";
import NALOGA_11_5 from "./pages/_11_5_Naloga.js";
import PAGE_12_TRANSFORMACIJA_MINDSETA from "./pages/_12_TransformacijaMindseta";
import NALOGA_12_1 from "./pages/_12_1_Naloga.js";
import NALOGA_12_2 from "./pages/_12_2_Naloga.js";
import NALOGA_12_3 from "./pages/_12_3_Naloga.js";
import NALOGA_12_4 from "./pages/_12_4_Naloga.js";
import NALOGA_12_5 from "./pages/_12_5_Naloga.js";
import PAGE_13_DOSTOP_DO_KOZMICNEGA_ZNANJA from "./pages/_13_DostopDoKozmicnegaZnanja";
import NALOGA_13_1 from "./pages/_13_1_Naloga.js";
import { Container, Row, Col, Button } from "react-bootstrap";
import { IconButton, Loader } from 'rsuite';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { PdfDocument } from "./components/GeneratePDF";
import { Sidenav, Nav, Icon, Dropdown, Notification } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

import SVG from "./components/OsebnaKvantnaStruktura"

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingReportData: true,
			naloga01_1: {
				a1: "",
				a2: "",
				a3: "",
				a4: false,
				b1: "",
				b2: "",
				b3: "",
				b4: false,
				c1: "",
				c2: "",
				c3: "",
				c4: false,
				d1: "",
				d2: "",
				d3: "",
				d4: false,
				e1: "",
				e2: "",
				e3: "",
				e4: false,
				f1: "",
				f2: "",
				f3: "",
				f4: false
			},
			naloga02_1: "",
			naloga02_2: "",
			naloga02_3: "",
			naloga02_4: "",
			naloga03_1: "",
			naloga04_1: "",
			naloga05_1: {
				ime: "",
				matioce: "",
				zenamoz: "",
				druzina1_ocemati_1: "",
				druzina1_ocemati_2: "",
				druzina1_ocemati_3: "",
				druzina1_ocemati_4: "",
				druzina1_zenamoz_1: "",
				druzina1_zenamoz_2: "",
				dom1_1: "",
				dom1_2: "",
				izobrazba1: "",
				izobrazba1_1: "",
				izobrazba1_2: "",
				izobrazba1_3: "",
				poklic1_1: "",
				poklic1_2: "",
				poklic2_1: "",
				poklic2_2: "",
				hobiji1: "",
				hobiji1_1: "",
				hobiji1_2: "",
				hobiji1_3: "",
				hobiji2: "",
				hobiji2_1: "",
				hobiji2_2: "",
				hobiji3: "",
				hobiji3_1: "",
				hobiji3_2: "",
				hobiji4: "",
				prijatelji1: "",
				prijatelji1_1: "",
				prijatelji2: "",
				prijatelji2_1: "",
				prijatelji3: "",
				prijatelji3_1: "",
				vrednote1_1: "",
				vrednote1_2: "",
				vrednote1_3: "",
				vrednote1_4: "",
				osebnaRast1_1: "",
				osebnaRast1_2: "",
				cilji1_1: "",
				cilji1_2: ""
			},
			naloga05_2: "",
			naloga05_3: "",
			naloga05_4: "",
			naloga05_5: "",
			naloga05_6: "",
			naloga06_1: {
				a1: "",
				a2: "",
				b1: "",
				b2: "",
				c1: "",
				c2: "",
				d1: "",
				d2: "",
				e1: "",
				e2: "",
				f1: "",
				f2: "",
			},
			naloga06_2: {
				ime: "",
				matioce: "",
				zenamoz: "",
				druzina1_ocemati_1: "",
				druzina1_ocemati_2: "",
				druzina1_ocemati_3: "",
				druzina1_ocemati_4: "",
				druzina1_zenamoz_1: "",
				druzina1_zenamoz_2: "",
				dom1_1: "",
				dom1_2: "",
				izobrazba1: "",
				izobrazba1_1: "",
				izobrazba1_2: "",
				izobrazba1_3: "",
				poklic1_1: "",
				poklic1_2: "",
				poklic2_1: "",
				poklic2_2: "",
				hobiji1: "",
				hobiji1_1: "",
				hobiji1_2: "",
				hobiji1_3: "",
				hobiji2: "",
				hobiji2_1: "",
				hobiji2_2: "",
				hobiji3: "",
				hobiji3_1: "",
				hobiji3_2: "",
				hobiji4: "",
				prijatelji1: "",
				prijatelji1_1: "",
				prijatelji2: "",
				prijatelji2_1: "",
				prijatelji3: "",
				prijatelji3_1: "",
				vrednote1_1: "",
				vrednote1_2: "",
				vrednote1_3: "",
				vrednote1_4: "",
				osebnaRast1_1: "",
				osebnaRast1_2: "",
				cilji1_1: "",
				cilji1_2: ""
			},
			naloga06_3: "",
			naloga06_4: "",
			naloga07_1: {
				a1: "",
				a2: "",
				a3: "",
				a4: "",
				a5: ""
			},
			naloga07_2: "",
			naloga08_1: {
				a1: "",
				a2: "",
				b1: "",
				b2: "",
				c1: "",
				c2: "",
				d1: "",
				d2: "",
				e1: "",
				e2: ""
			},
			naloga08_2: {
				a1: "",
				b1: "",
				c1: "",
				d1: "",
				e1: "",
				f1: ""
			},
			naloga09_1: {
				a1: "",
				a2: "",
				a3: "",
				b1: "",
				b2: "",
				b3: "",
				c1: "",
				c2: "",
				c3: "",
				d1: "",
				d2: "",
				d3: "",
				e1: "",
				e2: "",
				e3: "",
				f1: "",
				f2: "",
				f3: ""
			},
			naloga10_1: "",
			naloga10_1_1: "",
			naloga10_2: "",
			naloga10_2_1: "",
			naloga11_1: "",
			naloga11_2: "",
			naloga11_3: {
				a1: "",
				a2: "",
				a3: "",
				a4: "",
				a5: ""
			},
			naloga11_4: {
				a1: "",
				a2: "",
				a3: "",
				a4: "",
				a5: "",
				a6: "",
				a7: "",
				a8: "",
				a9: "",
				a10: "",
				a11: "",
				a12: ""
			},
			naloga11_5: "",
			naloga12_1: "",
			naloga12_2: "",
			naloga12_3: "",
			naloga12_4: "",
			naloga12_5: "",
			naloga13_1: ""
		};
		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(name, value) {
		this.setState({[name]: value})
		this.setState({loadingReportData: true})
		Notification["success"]({
			title: "Shranjeno",
			style: {width: 320}
		});
	}
	

	render() {
		return (
			<div>
				<main className="main">
					<div className="">
						<Container>
							<Router>
								<Row>
									<h1 className="title">TRENING GENIALNOSTI</h1>
								</Row>
								<Row>
									<Col lg={3}>
										<Sidenav defaultOpenKeys={["1"]} appearance="subtle">
											<Sidenav.Body>
												<Nav>
													<Nav.Item componentClass={Link} to="/" eventKey="1" icon={<Icon icon="angle-right" />}>
														0. Uvod
													</Nav.Item>
													<Dropdown eventKey="2" title="1. Kateri so moji potenciali?" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/1-kateri-so-moji-potenciali" eventKey="2-1" icon={<Icon icon="info-circle" />}>1. Kateri so moji potenciali?</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item componentClass={Link} to="/1-1-naloga" eventKey="2-2" icon={<Icon icon="pencil" />}>1.1 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="3" title="2. Katere so moje vrednote?" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/2-katere-so-moje-vrednote" eventKey="3-1" icon={<Icon icon="info-circle" />}>2. Katere so moje vrednote?</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item componentClass={Link} to="/2-1-naloga" eventKey="3-2" icon={<Icon icon="pencil" />}>2.1 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/2-2-naloga" eventKey="3-3" icon={<Icon icon="pencil" />}>2.2 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/2-3-naloga" eventKey="3-4" icon={<Icon icon="pencil" />}>2.3 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/2-4-naloga" eventKey="3-5" icon={<Icon icon="pencil" />}>2.4 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="4" title="3. Kaj je moje sporočilo?" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/3-kaj-je-moje-sporocilo" eventKey="4-1" icon={<Icon icon="info-circle" />}>3. Kaj je moje sporočilo?</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item componentClass={Link} to="/3-1-naloga" eventKey="4-2" icon={<Icon icon="pencil" />}>3.1 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="5" title="4. Kaj je moj moto?" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/4-kaj-je-moj-moto" eventKey="5-1" icon={<Icon icon="info-circle" />}>4. Kaj je moj moto?</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item componentClass={Link} to="/4-1-naloga" eventKey="5-2" icon={<Icon icon="pencil" />}>4.1 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="6" title="5. Osebna kvantna struktura?" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/5-osebna-kvantna-struktura" eventKey="6-1" icon={<Icon icon="info-circle" />}>5. Osebna kvantna struktura?</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item componentClass={Link} to="/5-1-naloga" eventKey="6-2" icon={<Icon icon="pencil" />}>5.1 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/5-2-naloga" eventKey="6-2" icon={<Icon icon="pencil" />}>5.2 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/5-3-naloga" eventKey="6-3" icon={<Icon icon="pencil" />}>5.3 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/5-4-naloga" eventKey="6-4" icon={<Icon icon="pencil" />}>5.4 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/5-5-naloga" eventKey="6-5" icon={<Icon icon="pencil" />}>5.5 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/5-6-naloga" eventKey="6-6" icon={<Icon icon="pencil" />}>5.6 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="7" title="6. Šest kvantnih vlog" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/6-sest-kvantnih-vlog" eventKey="7-1" icon={<Icon icon="info-circle" />}>6. Šest kvantnih vlog</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item componentClass={Link} to="/6-1-naloga" eventKey="7-2" icon={<Icon icon="pencil" />}>6.1 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/6-2-naloga" eventKey="7-3" icon={<Icon icon="pencil" />}>6.2 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/6-3-naloga" eventKey="7-4" icon={<Icon icon="pencil" />}>6.3 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/6-4-naloga" eventKey="7-5" icon={<Icon icon="pencil" />}>6.4 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="8" title="7. Spodbujaj radovednost" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/7-spodbujaj-radovednost" eventKey="8-1" icon={<Icon icon="info-circle" />}>7. Spodbujaj radovednost</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item componentClass={Link} to="/7-1-naloga" eventKey="8-2" icon={<Icon icon="pencil" />}>7.1 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/7-2-naloga" eventKey="8-3" icon={<Icon icon="pencil" />}>7.2 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="9" title="8. Prevzemite odgovornost" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/8-prevzemite-odgovornost" eventKey="9-1" icon={<Icon icon="info-circle" />}>8. Prevzemite odgovornost</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item componentClass={Link} to="/8-1-naloga" eventKey="9-2" icon={<Icon icon="pencil" />}>8.1 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/8-2-naloga" eventKey="9-3" icon={<Icon icon="pencil" />}>8.2 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="10" title="9. Ustvarjalni imaginarium" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/9-ustvarjalni-imaginarium" eventKey="10-1" icon={<Icon icon="info-circle" />}>9. Ustvarjalni imaginarium</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item  componentClass={Link} to="/9-1-naloga" eventKey="10-2" icon={<Icon icon="pencil" />}>9.1 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="11" title="10. Uporaba mentalnih orodij" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item componentClass={Link} to="/10-uporaba-mentalnih-orodij" eventKey="11-1" icon={<Icon icon="info-circle" />}>10. Uporaba mentalnih orodij</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item componentClass={Link} to="/10-1-naloga" eventKey="11-2" icon={<Icon icon="pencil" />}>10.1 Naloga</Dropdown.Item>
														<Dropdown.Item componentClass={Link} to="/10-2-naloga" eventKey="11-2" icon={<Icon icon="pencil" />}>10.2 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="12" title="11. Kreiranje čudovitega uma" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item  componentClass={Link} to="/11-kreiranje-cudovitega-uma" eventKey="12-1" icon={<Icon icon="info-circle" />}>11. Kreiranje čudovitega uma</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item  componentClass={Link} to="/11-1-naloga" eventKey="12-2" icon={<Icon icon="pencil" />}>11.1 Naloga</Dropdown.Item>
														{/* <Dropdown.Item  componentClass={Link} to="/11-2-naloga" eventKey="12-3" icon={<Icon icon="pencil" />}>11.2 Naloga</Dropdown.Item> */}
														<Dropdown.Item  componentClass={Link} to="/11-3-naloga" eventKey="12-4" icon={<Icon icon="pencil" />}>11.3 Naloga</Dropdown.Item>
														<Dropdown.Item  componentClass={Link} to="/11-4-naloga" eventKey="12-5" icon={<Icon icon="pencil" />}>11.4 Naloga</Dropdown.Item>
														<Dropdown.Item  componentClass={Link} to="/11-5-naloga" eventKey="12-6" icon={<Icon icon="pencil" />}>11.5 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="13" title="12. Transformacija mindseta" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item  componentClass={Link} to="/12-transformacija-mindseta" eventKey="13-1" icon={<Icon icon="info-circle" />}>12. Transformacija mindseta</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item  componentClass={Link} to="/12-1-naloga" eventKey="13-2" icon={<Icon icon="pencil" />}>12.1 Naloga</Dropdown.Item>
														<Dropdown.Item  componentClass={Link} to="/12-2-naloga" eventKey="13-3" icon={<Icon icon="pencil" />}>12.2 Naloga</Dropdown.Item>
														<Dropdown.Item  componentClass={Link} to="/12-3-naloga" eventKey="13-4" icon={<Icon icon="pencil" />}>12.3 Naloga</Dropdown.Item>
														<Dropdown.Item  componentClass={Link} to="/12-4-naloga" eventKey="13-5" icon={<Icon icon="pencil" />}>12.4 Naloga</Dropdown.Item>
														<Dropdown.Item  componentClass={Link} to="/12-5-naloga" eventKey="13-6" icon={<Icon icon="pencil" />}>12.5 Naloga</Dropdown.Item>
													</Dropdown>
													<Dropdown eventKey="14" title="13. Dostop do kozmičnega znanja" icon={<Icon icon="angle-right" />}>
														<Dropdown.Item  componentClass={Link} to="/13-dostop-do-kozmicnega-znanja" eventKey="14-1" icon={<Icon icon="info-circle" />}>13. Dostop do kozmičnega znanja</Dropdown.Item>
														<Dropdown.Item divider />
														<Dropdown.Item  componentClass={Link} to="/13-1-naloga" eventKey="14-2" icon={<Icon icon="pencil" />}>13.1 Naloga</Dropdown.Item>
													</Dropdown>
												</Nav>
											</Sidenav.Body>
										</Sidenav>
										
										<Button className="marginTopBottom1" variant="outline-success">Navodila za uporabo interaktivnega delovnega zvezka</Button>{' '}
										{/* <Button onClick={generatePDF} variant="outline-info">Shrani in prenesi pdf</Button>
										<Button onClick={generatePDF2} variant="outline-info">Shrani in prenesi pdf</Button> */}
										<IconButton color="cyan" onClick={() => this.setState({ loadingReportData: !this.state.loadingReportData })} icon={<Icon icon="paperclip" />}>Generiraj PDF datoteko</IconButton>
										{!this.state.loadingReportData && <PDFDownloadLink
											className=""
											document={<PdfDocument data={this.state} />}
											fileName="genialni-zvezek.pdf">
											{({ blob, url, loading, error }) =>
												loading ? <Loader className="marginTopBottom1" content="Priprava dokumenta ..." /> : <IconButton className="marginTopBottom1" color="red" icon={<Icon icon="arrow-circle-down" />}>Prenesi PDF datoteko</IconButton>
											}
										</PDFDownloadLink>}
										

									</Col>
									<Col lg={9}>
										{/* <PDFViewer
											width="100%"
											height="50%"
											children={<PdfDocument data={this.state} />}
											>
										</PDFViewer> */}
										<Switch>
											<Route exact path="/">
												<Uvod />
											</Route>
											<Route path="/1-kateri-so-moji-potenciali">
												<PAGE_1_KATERI_SO_MOJI_POTENCIALI />
											</Route>
											<Route path="/1-1-naloga">
												<NALOGA_1_1 handleSave={this.handleSave} data={this.state.naloga01_1} />
											</Route>
											<Route path="/2-katere-so-moje-vrednote">
												<PAGE_2_KATERE_SO_MOJE_VREDNOTE />
											</Route>
											<Route path="/2-1-naloga">
												<NALOGA_2_1 handleSave={this.handleSave} data={this.state.naloga02_1} />
											</Route>
											<Route path="/2-2-naloga">
												<NALOGA_2_2 handleSave={this.handleSave} data={this.state.naloga02_2} />
											</Route>
											<Route path="/2-3-naloga">
												<NALOGA_2_3 handleSave={this.handleSave} data={this.state.naloga02_3} />
											</Route>
											<Route path="/2-4-naloga">
												<NALOGA_2_4 handleSave={this.handleSave} data={this.state.naloga02_4} />
											</Route>
											<Route path="/3-kaj-je-moje-sporocilo">
												<PAGE_3_KAJ_JE_MOJE_SPOROCILO />
											</Route>
											<Route path="/3-1-naloga">
												<NALOGA_3_1 handleSave={this.handleSave} data={this.state.naloga04_1} />
											</Route>
											<Route path="/4-kaj-je-moj-moto">
												<PAGE_4_KAJ_JE_MOJ_MOTO />
											</Route>
											<Route path="/4-1-naloga">
												<NALOGA_4_1 handleSave={this.handleSave} data={this.state.naloga04_1} />
											</Route>
											<Route path="/5-osebna-kvantna-struktura">
												<PAGE_5_OSEBNA_KVANTNA_STRUKTURA />
											</Route>
											<Route path="/5-1-naloga">
												<NALOGA_5_1 handleSave={this.handleSave} data={this.state.naloga05_1} />
											</Route>
											<Route path="/5-2-naloga">
												<NALOGA_5_2 handleSave={this.handleSave} data={this.state.naloga05_2} />
											</Route>
											<Route path="/5-3-naloga">
												<NALOGA_5_3 handleSave={this.handleSave} data={this.state.naloga05_3} />
											</Route>
											<Route path="/5-4-naloga">
												<NALOGA_5_4 handleSave={this.handleSave} data={this.state.naloga05_4} />
											</Route>
											<Route path="/5-5-naloga">
												<NALOGA_5_5 handleSave={this.handleSave} data={this.state.naloga05_5} />
											</Route>
											<Route path="/5-6-naloga">
												<NALOGA_5_6 handleSave={this.handleSave} data={this.state.naloga05_6} />
											</Route>
											<Route path="/6-sest-kvantnih-vlog">
												<PAGE_6_SEST_KVANTNIH_VLOG />
											</Route>
											<Route path="/6-1-naloga">
												<NALOGA_6_1 handleSave={this.handleSave} data={this.state.naloga06_1} />
											</Route>
											<Route path="/6-2-naloga">
												<NALOGA_6_2 handleSave={this.handleSave} data={this.state.naloga06_2} />
											</Route>
											<Route path="/6-3-naloga">
												<NALOGA_6_3 handleSave={this.handleSave} data={this.state.naloga06_3} />
											</Route>
											<Route path="/6-4-naloga">
												<NALOGA_6_4 handleSave={this.handleSave} data={this.state.naloga06_4} />
											</Route>
											<Route path="/7-spodbujaj-radovednost">
												<PAGE_7_SPODBUJAJ_RADOVEDNOST />
											</Route>
											<Route path="/7-1-naloga">
												<NALOGA_7_1 handleSave={this.handleSave} data={this.state.naloga07_1} />
											</Route>
											<Route path="/7-2-naloga">
												<NALOGA_7_2 handleSave={this.handleSave} data={this.state.naloga07_2} />
											</Route>
											<Route path="/8-prevzemite-odgovornost">
												<PAGE_8_PREVZEMITE_ODGOVORNOST />
											</Route>
											<Route path="/8-1-naloga">
												<NALOGA_8_1 handleSave={this.handleSave} data={this.state.naloga08_1} />
											</Route>
											<Route path="/8-2-naloga">
												<NALOGA_8_2 handleSave={this.handleSave} data={this.state.naloga08_2} />
											</Route>
											<Route path="/9-ustvarjalni-imaginarium">
												<PAGE_9_USTVARJALNI_IMAGINARIUM />
											</Route>
											<Route path="/9-1-naloga">
												<NALOGA_9_1 handleSave={this.handleSave} data={this.state.naloga09_1} />
											</Route>
											<Route path="/10-uporaba-mentalnih-orodij">
												<PAGE_10_UPORABA_MENTALNIH_ORODIJ />
											</Route>
											<Route path="/10-1-naloga">
												<NALOGA_10_1 handleSave={this.handleSave} data={this.state.naloga10_1} data2={this.state.naloga10_1_1} />
											</Route>
											<Route path="/10-2-naloga">
												<NALOGA_10_2 handleSave={this.handleSave} data={this.state.naloga10_2} data2={this.state.naloga10_2_1}/>
											</Route>
											<Route path="/11-kreiranje-cudovitega-uma">
												<PAGE_11_KREIRANJE_CUDOVITEGA_UMA />
											</Route>
											<Route path="/11-1-naloga">
												<NALOGA_11_1 handleSave={this.handleSave} data={this.state.naloga11_1} />
											</Route>
											{/* <Route path="/11-2-naloga">
												<NALOGA_11_2 handleSave={this.handleSave} data={this.state.naloga11_2} />
											</Route> */}
											<Route path="/11-3-naloga">
												<NALOGA_11_3 handleSave={this.handleSave} data={this.state.naloga11_3} />
											</Route>
											<Route path="/11-4-naloga">
												<NALOGA_11_4 handleSave={this.handleSave} data={this.state.naloga11_4} />
											</Route>
											<Route path="/11-5-naloga">
												<NALOGA_11_5 handleSave={this.handleSave} data={this.state.naloga11_5} />
											</Route>
											<Route path="/12-transformacija-mindseta">
												<PAGE_12_TRANSFORMACIJA_MINDSETA />
											</Route>
											<Route path="/12-1-naloga">
												<NALOGA_12_1 handleSave={this.handleSave} data={this.state.naloga13_1} />
											</Route>
											<Route path="/12-2-naloga">
												<NALOGA_12_2 handleSave={this.handleSave} data={this.state.naloga12_2} />
											</Route>
											<Route path="/12-3-naloga">
												<NALOGA_12_3 handleSave={this.handleSave} data={this.state.naloga12_3} />
											</Route>
											<Route path="/12-4-naloga">
												<NALOGA_12_4 handleSave={this.handleSave} data={this.state.naloga12_4} />
											</Route>
											<Route path="/12-5-naloga">
												<NALOGA_12_5 handleSave={this.handleSave} data={this.state.naloga12_5} />
											</Route>
											<Route path="/13-dostop-do-kozmicnega-znanja">
												<PAGE_13_DOSTOP_DO_KOZMICNEGA_ZNANJA />
											</Route>
											<Route path="/13-1-naloga">
												<NALOGA_13_1 handleSave={this.handleSave} data={this.state.naloga13_1} />
											</Route>
										</Switch>
									</Col>
								</Row>
							</Router>
						</Container>
					</div>
				</main>
				<footer className="footer">
					<Container>
						<div className="footer-copy">
							<div>&copy;<a href="https://www.nenadvladic.com" rel="noreferrer" target="_blank">nenadvladic.com</a></div>
							<div>Izdelava spletne aplikacije | <a href="https://dpoint.si" rel="noreferrer" target="_blank">dpoint.si</a></div>
						</div>
					</Container>
				</footer>
			</div>
		)
	}
}

export default App;