import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment12_2(props) {
    const [naloga12_2, set12_2] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga12_2} className="notebook-like" onChange={(e) => set12_2(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga12_2",naloga12_2)} >Shrani</Button>
        </div> 
    )
}

export default Assignment12_2;
