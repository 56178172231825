import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function KatereSoMojeVrednote() {
    return (
        <div>
            <PageHeading heading="2. Katere so moje vrednote?" img="idea" />
            <p>Kaj je za vas najpomembnejše? Vaše temeljne vrednote določajo, kako odgovarjate na to vprašanje. Naše temeljne vrednote poudarjajo, za kaj se zavzemamo. Temeljne vrednote usmerjajo naše vedenje, odločitve in ukrepe.</p>
            <p>Ko veste, kaj cenite, lahko živite v skladu s temi vrednotami. To vodi k večjem zadovoljstvu. Ko ne poznate svojih vrednot, jih vsak dan kršite. To ustvarja notranjo napetost. In ta napetost sproža destruktivne navade in regresivno vedenje. Da bi se temu izognili, se moramo resnično potruditi, da ugotovimo, katere so naše vrednote.</p>
            <ButtonNextSection link={"/2-1-naloga"} nextSection={"2.1 Naloga"} />
        </div>     
    )
}

export default KatereSoMojeVrednote;
