import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT6_4 from "./assignments/Assignment6_4";

function naloga6_4(props) {
    return (
        <div>
            <PageHeading heading="6.4 Naloga" img="pencil" />

            <h5>Izdelajte kvantno strukturo vsakega zaposlenega v svojem timu. Katere pristope pri tem uporabiti upoštevajoč, da so si ljudje med seboj različni? V vsakem primeru pa mora biti čas preživet z njimi prijeten.</h5>

            <ASSIGNMENT6_4 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/7-spodbujaj-radovednost"} nextSection={"7. Spodbujaj radovednost"} />
        </div> 
    )
}

export default naloga6_4;