import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT2_1 from "./assignments/Assignment2_1"

function naloga2_1(props) {
    return (
        <div>
            <PageHeading heading="2.1 Naloga" img="pencil" />

            <h5>Obkrožite 5 vrednot, ki vam pomenijo največ.</h5>

            <ASSIGNMENT2_1 handleSave={props.handleSave} data={props.data}/>
            
            <p><b>Ugotovili boste, da ima vsak</b> posameznik njemu lasten in edinstven urejen sistem vrednot. Vsako njihovo primerjanje in tehtanje katere so boljše nima smisla, saj so globoko zapisane v osebnosti vsakega posameznika in kot takšne predstavljajo njegovo individualno najvišje dobro.</p>
            <p><b>Predstavljajte si kakšen mora biti sistem vrednost znotraj organizacije, v kateri je zaposlenih nekaj deset, sto ali tisoč ljudi. Cilj organizacije je poiskati skupni imenovalec in oblikovati takšen sistem vrednot na makro nivoju, s katerim se boste zaposleni lahko identificirali. Torej vrednote podjetja niso mrtva črka na papirju ali marketinški trik, ampak osnovna frekvenca ustvarjanja.</b></p>
            <p><b>V tem smislu lahko organizacija spodbuja svoje zaposlene k temu, da v kar največji meri uresničujejo svoje notranje težnje, lahko pa je osredotočena predvsem na visoke dosežke na področjih, ki jih določa sama ali so določena od zunaj.</b></p>

            <ButtonNextSection link={"/2-2-naloga"} nextSection={"2.2 Naloga"} />
        </div> 
    )
}

export default naloga2_1;