import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment13_1(props) {
    const [naloga13_1, set13_1] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga13_1} className="notebook-like" onChange={(e) => set13_1(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga13_1",naloga13_1)} >Shrani</Button>
        </div> 
    )
}

export default Assignment13_1;
