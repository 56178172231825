import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT9_1 from "../pages/assignments/Assignment9_1"

function naloga9_1(props) {
    return (
        <div>
            <PageHeading heading="9.1 Naloga" img="pencil" />

            <p>Prepoznajte svoje talente s pomočjo prijetnih slik iz otroštva. Znova se sprehodite po šolskih hodnikih, igriščih, otroških sobah in parkih. Spomnite se najlepših dogodkov, ki imajo posebno mesto v vašem srcu. Spomnite se pozitivnih oseb, ki so soustvarjale vaše dogodivščine. Skratka bodite otroci in sledite magičnim trenutkom sreče, v katerih ste bili nasmejani, energični in zadovoljni.</p>

            <div className="naloge">
                <h3>9. Naloga:</h3>
                <div className="nalogaHeadingItem">
                    <h5>9.1 Kateri je prvi korak, ki ga boste naredili?</h5>
                </div>
                <div className="nalogaHeadingItem">
                    <h5>9.2. Katera znanja rabite?</h5>
                </div>
                <div className="nalogaHeadingItem">
                    <h5>9.3. Katere ljudi boste kontaktirali? Zakaj?</h5>
                </div>
            </div>

            <ASSIGNMENT9_1 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/10-uporaba-mentalnih-orodij"} nextSection={"10. Uporaba mentalnih orodij"} />
        </div> 
    )
}

export default naloga9_1