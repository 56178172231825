import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT10_1 from "../pages/assignments/Assignment10_1"
import ASSIGNMENT10_1_1 from "../pages/assignments/Assignment10_1_1"

function naloga10_1(props) {
    return (
        <div>
            <PageHeading heading="10.1 Naloga" img="pencil" />

            <p>Prepoznajte svoje talente s pomočjo prijetnih slik iz otroštva. Znova se sprehodite po šolskih hodnikih, igriščih, otroških sobah in parkih. Spomnite se najlepših dogodkov, ki imajo posebno mesto v vašem srcu. Spomnite se pozitivnih oseb, ki so soustvarjale vaše dogodivščine. Skratka bodite otroci in sledite magičnim trenutkom sreče, v katerih ste bili nasmejani, energični in zadovoljni.</p>

            <h5>A) Opišite najlepši prizor, ki ste ga kadarkoli v življenju doživeli. Kje je bilo? S kom ste bili?</h5>
            <ASSIGNMENT10_1 handleSave={props.handleSave} data={props.data} />

            <h5>B) Pri tem opazujte vse svoje telesne in psihofizične reakcije. Zapišite kakšni občutki vas pri tem obidejo?</h5>
            <ASSIGNMENT10_1_1 handleSave={props.handleSave} data={props.data2} />
            
            <ButtonNextSection link={"/10-2-naloga"} nextSection={"10.2 Naloga"} />
        </div> 
    )
}

export default naloga10_1