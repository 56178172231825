import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT5_5 from "./assignments/Assignment5_5";

function naloga5_5(props) {
    return (
        <div>
            <PageHeading heading="5.5 Naloga" img="pencil" />

            <h5>Spomnite se svojega največjega uspeha.</h5>
            <h5>A) Kaj je to bilo? Opišite svoje občutke.</h5>
            <h5>B) Ste se še kdaj v življenju podoživeli takšne občutke? Če ste, kdaj?</h5>

            <ASSIGNMENT5_5 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/5-6-naloga"} nextSection={"5.6 Naloga"} />
        </div> 
    )
}

export default naloga5_5;