import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT11_3 from "../pages/assignments/Assignment11_3"

function naloga11_3(props) {
    return (
        <div>
            <PageHeading heading="11.3 Naloga" img="pencil" />

            <p>Zakaj uporabljati izvorne domače besede? Zato ker so te besede del tako naše genske zasnove in našega kolektivnega nezavednega, kot je govoril nemški psiholog Carl Gustav Jung. Takšne besede so veliko bližje našemu razumevanju, kot so tuje besede. Ko izgovorimo domačo besedo prikliče naša zavest iz kolektivno nezavednega tudi sliko, ki vzpodbuja delovanje desne hemisfere.</p>

            <h5>Kakšne slike se vam porodijo, ko izgovorite naslednje besede?</h5>

            <ASSIGNMENT11_3 handleSave={props.handleSave} data={props.data} />
            
            <ButtonNextSection link={"/11-4-naloga"} nextSection={"11.4 Naloga"} />
        </div> 
    )
}

export default naloga11_3