import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT2_4 from "./assignments/Assignment2_4";

function naloga2_4(props) {
    return (
        <div>
            <PageHeading heading="2.4 Naloga" img="pencil" />

            <h5>Zapišite kdo so vaši vzorniki?</h5>
            
            <h5>A) Zakaj?</h5>
            <h5>B) Kakšne so njihove kvalitete? Katere vrednote posedujejo?</h5>

            <ASSIGNMENT2_4 handleSave={props.handleSave} data={props.data}/>

            <ButtonNextSection link={"/3-kaj-je-moje-sporocilo"} nextSection={"3. Kaj je moje sporočilo"} />
        </div> 
    )
}

export default naloga2_4;