import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function SestKvantnihVlog() {
    return (
        <div>
            <PageHeading heading="6. Šest kvantnih vlog" img="idea" />
            <p>Vsaka oseba ima v življenju številne vloge, ki se medsebojno dopolnjujejo in nadgrajujejo. Bolj, ko boste dejavni v eni vlogi večje zadovoljstvo in radost boste v tej vlogi čutili. Posledično se iz uspeha v eni vlogi porodi motivacija za uspeh v katerikoli drugi. Cilj je doseči ravnotežje med vsemi vlogami, kar je edino možno z nenehno aktivnostjo v vseh. Mogoče boste zaradi tega včasih utrujeni, a občutek zadovoljstva in radosti bo veliko močnejši.</p>
            <p>Vse vloge bodo podpirale in prispevale dodano vrednost k vaši glavni vlogi oz. »kdo sem«. Če je bil prej vaš pristop k vašemu poklicu oz. vlogi »kdo sem« linearen, bo od sedaj naprej komplementaren, holističen in celovit. Na vašo poklicno vlogo boste nezavedno in zavedno delovali iz vseh možnih področij: družbenega, sociološkega, psihološkega (čustva in razum) in filozofskega. Tako se boste postopoma razvijali v genialnega ustvarjalca in dosegli obzorja o katerih niste niti pomislili. S časoma boste odkrili tudi druge vloge, ki jih (za zdaj) še ne poznate.</p>

            <p>Osnovno gorivo kvantnih vlog so:</p>
            <ul>
                <li>naši talenti, ki predstavljajo temelj okoli katerega so stkani vsi naši realno dosegljivi cilji;</li>
                <li>vrednote, v okviru katerih delujemo</li>
                <li>komunikacija z vsemi akterji, ki v vlogah nastopajo</li>
            </ul> 

            <ButtonNextSection link={"/6-1-naloga"} nextSection={"6.1 Naloga"} />
        </div>     
    )
}

export default SestKvantnihVlog;
