import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT10_2 from "../pages/assignments/Assignment10_2"
import ASSIGNMENT10_2_1 from "../pages/assignments/Assignment10_2_1"

function naloga10_2(props) {
    return (
        <div>
            <PageHeading heading="10.2 Naloga" img="pencil" />

            <h5>A) Izberite predmet, ki ima v vašem življenju posebno vrednost. To je lahko stara majica, kavbojke, denarnica, zapestnica, ipd.. Postavite ga na mizo pred sebe (ali ga zamislite) in odgovorite na naslednja vprašanja:</h5>

            <div className="naloge">
                <div className="nalogaHeadingItem">
                    <h6>1. Na kaj pomislite? Pretekle izkušnje? Kje je bil izdelan? Kdo je izdelal predmet tvojega opazovanja? Poskusite si zamisliti kako ta človek izgleda.</h6>
                </div>
                <div className="nalogaHeadingItem">
                    <h6>2. Kako predmet vpliva na vseh 5 čutil – tip, vid, voh, okus in sluh?</h6>
                </div>
                <div className="nalogaHeadingItem">
                    <h6>3. Poskusi povezati svoje čutilno izkustvo s svojimi čustvi. Ali najdeš povezavo?</h6>
                </div>
            </div>

            <ASSIGNMENT10_2 handleSave={props.handleSave} data={props.data} />

            <h5>B) Sedaj izberite predmet in isto vajo naredi s predmetom s katerim nimaš povezave oz. ti nič ne pomeni. Kaj je drugače? Zakaj se ne moreš čustveno povezati?</h5>
            <ASSIGNMENT10_2_1 handleSave={props.handleSave} data={props.data2} />
            
            <ButtonNextSection link={"/11-kreiranje-cudovitega-uma"} nextSection={"11. Kreiranje čudovitega uma"} />
        </div> 
    )
}

export default naloga10_2