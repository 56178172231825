import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT8_2 from "./assignments/Assignment8_2";

function naloga8_2(props) {
    return (
        <div>
            <PageHeading heading="8.2 Naloga" img="pencil" />

            <h5>Preučite vsako vlogo in zapišite kaj lahko naredite, da bi bili v njej še boljši.</h5>
            <h6>Tako boste ozavestili svoj napredek in si hkrati tudi zadali bodoče cilje, ki jih boste dosegli. Napredek, ki ga želite uresničiti naj bo objektivno dosegljiv. Zadajte si napredek, ki ga lahko uresničite v 1 mesecu.</h6>

            <ASSIGNMENT8_2 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/9-ustvarjalni-imaginarium"} nextSection={"9 Ustvarjalni imaginarium"} />
        </div> 
    )
}

export default naloga8_2;