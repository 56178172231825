import React, { useState } from 'react';
import "./assignments.css";
import { Form, Button } from "react-bootstrap";

function Assignment11_4(props) {
    const [naloga11_4, set11_4] = useState(props.data)

    return (
        <div className="assignment">
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>1. Originalen - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a1} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a1: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>2. Čao - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a2} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a2: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>3. Kalkulacija - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a3} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a3: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>4. Edukacija - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a4} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a4: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>5. Inspiracija - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a5} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a5: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>6. Empatija - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a6} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a6: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>7. Profesionalec - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a7} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a7: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>8. Nivo - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a8} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a8: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>9. Solucija - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a9} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a9: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>10. Ego - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a10} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a10: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>11. Koncipiran - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a11} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a11: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square25 flex-vertical-center border-none"><h5>12. Kohezija - </h5></div>
                <div className="square75 task-input border-none">
                    <Form.Control value={naloga11_4.a12} type="text" onChange={(evt) => set11_4({ ...naloga11_4, a12: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga11_4",naloga11_4)} >Shrani</Button>
        </div> 
    )
}

export default Assignment11_4;
