import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function DostopDoKozmicnegaZnanja() {
    return (
        <div>
            <PageHeading heading="13. Dostop do kozmičnega znanja" img="idea" />
            <p>Naj se še tako močno trudimo, samo z uporabo logičnega sklepanja ne bomo uspeli razvozlati, kako so se porodile Leonardove nazorne skice letala in robota ali Teslova zasnova brezžične komunikacije. Medtem ko so določene zamisli popolnoma usklajene s časom, v katerem sta ustvarjala, druge od njunega časa odstopajo za več stoletij. Razrešitev ene najzahtevnejših ugank ponuja kvantna mehanika, ki izvor njune genialnosti pripisuje sposobnosti dolgotrajnega delovanja uma v tako imenovani frekvenci alfa (8–12 Hz).</p>
            <p>Na tej frekvenci se na stežaj odprejo vrata podzavesti, s čimer izginejo vsa dotedanja spoznanja o delovanju in urejenosti sveta in človeka v njem. Popolnoma naravno brez kakršnegakoli naprezanja in osvobojeno od stresa nastajajo zamisli, ki so plod sodelovanja ustvarjalnega razmišljanja, izjemne intuicije in najboljših vrlin. Vse, kar je bilo prej nemogoče, postane naenkrat mogoče. Govorimo o stopnji zavesti, na kateri je človekov um sinhroniziran z ionosfero, s čimer se omogoči dostop do univerzalnega informacijskega polja v vesolju, v katerem je shranjeno vse znanje našega sveta. V tem primeru znanje ni omejeno le na časovno obdobje, v katerem ustvarja posameznik, temveč zaobjema univerzalno znanje od nastanka do konca sveta. Preteklost, sedanjost in prihodnost obstajajo sočasno.</p>
            <p>Nasprotno možgani človeka pri vsakdanjih opravilih v glavnem delujejo na frekvenci beta (13–40 Hz). To ne pomeni, da pri tem nimamo možnosti vpogleda v <b>kozmično znanje</b>. Seveda jo imamo, vendar za veliko krajši čas. Govorimo o času, tik preden zaspimo, oziroma času, preden se prebudimo. Takrat lahko v kratkem ustvarjalnem času pridete do epohalnih zamisli, ki lahko vaše življenje obrnejo na glavo. Takrat naši možgani popolnoma naravno delujejo na frekvenci alfa. Pri tem je treba poudariti, da ima posameznik v nasprotju z genijem, ki je lahko v stanju alfa vztrajal tudi po več ur, na voljo zelo malo časa za prenos svoje vizije v resničnost. Samo nekaj sekund, kajti pridobljena slika zaradi nezdružljivosti valovanja alfa in beta, to je vizije in stvarnosti, zelo hitro bledi. Zato je izjemno pomembno, da se ob pojavu slike zbudite in si vizijo zapišete na list papirja. Če tega ne boste storili, boste na vizijo pozabili.</p>
            
            <ButtonNextSection link={"/13-1-naloga"} nextSection={"13.1 Naloga"} />
        </div>     
    )
}

export default DostopDoKozmicnegaZnanja;
