import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT3_1 from "./assignments/Assignment3_1";

function naloga3_1(props) {
    return (
        <div>
            <PageHeading heading="3.1 Naloga" img="pencil" />

            <h5>O čem sanjate? Kaj želiš sporočiti svetu? Kaj vas navdihuje, da greste naprej? Ali kaj bi želeli, da vas navdihuje pa si niste upali o tem nikoli spregovoriti? Strnite sporočilo v enem ali največ dveh stavkih.</h5>

            <ASSIGNMENT3_1 handleSave={props.handleSave} data={props.data} />

            <p>Ko zaživite svoje <b>SPOROČILO</b> boste bolj:</p>
            <ul>
                <li>Motivirani (za učenje, nove tehnologije, pomagati podjetju, sodelavcem, znancem)</li>
                <li>Komunikativni (z vsemi skupinami s ciljem doseganja zastavljenih ciljev v službi in privatnem življenju, predstavili boste svoje ideje, prisluhnili drugim)</li>
                <li>Sočutni (videli boste potrebe drugega: sodelavca, partnerja, otroka, znanca in neznanca)</li>
                <li>učinkoviti (ni blodenja po stranpoteh in če se te začnejo se hitro vrnemo na začrtano pot)</li>
                <li>zadovoljni (delaš za dobro sebe in drugih, za dobro lokalnega in globalnega okolja)</li>
                <li>mirni (ne ukvarjaš se z nepomembnimi stvarmi, spočitost)</li>
            </ul>

            <ButtonNextSection link={"/4-kaj-je-moj-moto"} nextSection={"4. Kaj je moj moto?"} />
        </div> 
    )
}

export default naloga3_1;