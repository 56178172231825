import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT12_1 from "../pages/assignments/Assignment12_1"

function naloga12_1(props) {
    return (
        <div>
            <PageHeading heading="12.1 Naloga" img="pencil" />

            <h5>Ali obstaja <u>konkretno</u> opravilo ali osebnostna lastnost, ki vam je neprijetna? Kaj je to?</h5>

            <ASSIGNMENT12_1 handleSave={props.handleSave} data={props.data} />
            
            <ButtonNextSection link={"/12-2-naloga"} nextSection={"12.2 Naloga"} />
        </div> 
    )
}

export default naloga12_1