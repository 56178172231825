import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function KreiranjeCudovitegaUma() {
    return (
        <div>
            <PageHeading heading="11. Kreiranje čudovitega uma" img="idea" />
            <p>Govorimo o psihofizičnem stanju, ki porazumeva kar najboljšo uskladitev naših možganov, misli in izgovorjenih besed. Gre za izjemno stanje človeškega uma, v katerem prihajajo do izraza še neznane in neodkrite psihofizične sposobnosti posameznika. Tako nastajajo neverjetne, toda uresničljive zamisli. Pogled je razširjen in poglobljen. Um uravnotežen in izostren. Naenkrat ste sposobni boljše razumeti stvarnost in učinkoviteje reševati izzive. Govorimo o stanju, ki presega potrebe za opravljanje vsakdanjih dejavnosti, pri katerih posameznik v glavnem uporablja levo hemisfero možganov. Leva stran temelji na logiki, matematičnih enačbah, številkah, pomenu besed in razumu. Uporabljamo jo pri branju, pisanju, računanju, slovničnih pravilih in naravoslovju.</p>
            <p>Leva hemisfera razmišlja v besedah, zato omogoča učenje na pamet in pomnjenje različnih podatkov. Njene odlike so kritično razmišljanje, analiziranje, objektivnost, pozornost in razumskost. Leva polobla je prispodoba za fizični svet in družbeno ureditev, v kateri živimo. Na drugi strani je sicer pri večini uspavana desna polobla, in prav ta je adut posameznika, ki si želi uresničiti svoje sposobnosti in ustvariti svoj svet, saj je je izvor idejnosti, ustvarjalnosti in izvirnosti. Desna stran možganov za svoje delovanje uporablja miselne slike, čustva, barve in ritem govora. Posledično je zadolžena za domišljijo, tvorjenje prepričanj, intuicijo, subjektivnost, motoriko, glasbo in prepoznavanje obrazov. Cilj ustvarjalca je napredovati pri uporabljanju desne hemisfere in tako doseči usklajeno delovanje z že dodobra razvito levo hemisfero možganov.</p>
            
            <ButtonNextSection link={"/11-1-naloga"} nextSection={"11.1 Naloga"} />
        </div>     
    )
}

export default KreiranjeCudovitegaUma;
