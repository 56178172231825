import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT8_1 from "./assignments/Assignment8_1";

function naloga8_1(props) {
    return (
        <div>
            <PageHeading heading="8.1 Naloga" img="pencil" />

            <h5>Kako oz. na kakšen način lahko vsako izmed prvih 5 vprašanj, ki se pojavljajo razrešim?</h5>

            <ASSIGNMENT8_1 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/8-2-naloga"} nextSection={"8.1 Naloga"} />
        </div> 
    )
}

export default naloga8_1;