import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT11_5 from "../pages/assignments/Assignment11_5"

function naloga11_5(props) {
    return (
        <div>
            <PageHeading heading="11.5 Naloga" img="pencil" />

            <h5>Zapišite vse besede, ki se začenjajo na črko T.</h5>

            <ASSIGNMENT11_5 handleSave={props.handleSave} data={props.data} />
            
            <ButtonNextSection link={"/12-transformacija-mindseta"} nextSection={"12. Transformacija mindseta"} />
        </div> 
    )
}

export default naloga11_5