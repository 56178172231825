import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function UporabaMentalnihOrodij() {
    return (
        <div>
            <PageHeading heading="10. Uporaba mentalnih orodij" img="idea" />
            <p>Vsa mentalna orodja delujejo skozi naša čutila, s katerimi izkustveno preverjamo in hkrati oblikujemo svojo sliko sveta. V biološkem smislu čutila soustvarjajo čutilni sistem, ki tvori niz čutilnih receptorjev, zadolženih za prejemanje različnih dražljajev. Receptorji vsebujejo senzorične nevrone, ki zbirajo informacije o stanju našega telesa in okolice. Prejete informacije se nato prek živčnega sistema prenašajo do organov, ki se neposredno odzovejo na prejete informacije.</p>
            <p>Kako kakovosten bo naš odziv, je odvisno od stopnje ozaveščenosti čutov. Bolj ko so naši čuti ozaveščeni, večje so možnosti za pridobivanje novih znanj in veščin, osebno rast in oblikovanje pravilnih odločitev v danih okoliščinah.</p>
            <p>Glavna ovira do ozaveščanja čutov je kombinacija hitrega načina življenja z nepredvidenimi šumi iz okolja. Telefoni, računalniki, televizija in oglasni panoji na vsakem koraku ustvarjajo hrup, ki jemlje prostor in energijo za intenzivnejšo uporabo naših čutov. Možgani sodobnega človeka vsakodnevno prejmejo tako zelo veliko količino informacij, ki jo niso zmožni obdelati. Posledica tega je utrujenost in padec stopnje osredotočenosti. Tako se sčasoma v posamezniku vzpostavi občutek nezavzetosti, naveličanosti in nekritičnosti do vseh zunanjih dražljajev. Med drugim tudi tistih, ki imajo potencialno vrednost za pozitivne spremembe v njegovem življenju. Posameznik gleda, toda ne vidi. Posluša, toda ne sliši. Hrani se, toda ne zaznava okusa. Tipa, toda ne čuti.</p>
            <p>Ozavestiti čute pomeni vdihniti življenje izbranim dražljajem iz okolice, ki so bili še včeraj le hladne in nepomembne sence med tisoč drugimi brezčutnimi podobami. Z ozaveščanjem čutov spreminjamo odnos do tistih zunanjih dražljajev, ki imajo zmogljivost za preobrazbo in dolgotrajni razvoj naše osebnosti in ustvarjalnosti.</p>
            
            <ButtonNextSection link={"/10-1-naloga"} nextSection={"10.1 Naloga"} />
        </div>     
    )
}

export default UporabaMentalnihOrodij;
