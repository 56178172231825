import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment7_2(props) {
    const [naloga7_2, set7_2] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga7_2} className="notebook-like" onChange={(e) => set7_2(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga07_2",naloga7_2)} >Shrani</Button>
        </div> 
    )
}

export default Assignment7_2;
