import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment10_1_1(props) {
    const [naloga10_1_1, set10_1_1] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga10_1_1} className="notebook-like" onChange={(e) => set10_1_1(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga10_1_1",naloga10_1_1)} >Shrani</Button>
        </div> 
    )
}

export default Assignment10_1_1;
