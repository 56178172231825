import React from 'react';
import "./mindmap.css";

function OsebnaKvantnaStruktura(props) {

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 814.94 501.06">
                <path d="M466.52 165.77c2.79.91 2.26-3.67 2.57-8.94M320.52 201.07c-1.36-2.57 3.25-2.81 8.4-4M458.95 107.89c-11.67 17.77-.8 43.27 17.13 48.84 16.94 5.27 34.63-10.95 33.61-29s-16-31.1-33.23-27.7a24.82 24.82 0 00-19.86 19.31" fill="none" stroke="#2dabe3" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(471.04 135.38) scale(1.04 1)" fontSize="21.717" fill="#2dabe3" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    DOM
                </text>
                <path d="M259.67 189.95c-13.79 17.78-.95 43.28 20.24 48.85 20 5.27 40.89-11 39.69-29s-18.88-31.08-39.25-27.73-23.45 19.32-23.45 19.32" fill="none" stroke="#2dabe3" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(262.82 217.44) scale(1.04 1)" fontSize="21.717" fill="#2dabe3" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    VREDNOTE
                </text>
                <path d="M469.21 209.07c19.95-.57 22.05 33.59 46.72 40.72" fill="none" stroke="#e7267a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(536.72 245.48) scale(1.04 1)" fontSize="21.717" fill="#e7267a" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    POKLIC
                </text>
                <g fill="none" stroke="#e7267a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M528.66 218.26c-13.75 3.21-14 16-13.29 28.65s19.71 11.23 35.06 10.54 23.61-.23 30.94-.46 19.48-2.29 17.64-19.48-5.5-20.85-29.33-20.62-42.16-5-51.33 2.75M570.52 220.38c7 0 18.91-1 22.69 6.36M594.2 229.07a7.19 7.19 0 011 3.61M518.58 245.48c-.34 6.35 5.56 8.65 11.23 8.65M532.79 254.58c1.72 0 2.06.12 4.12.12M548.26 255.16c3.26.17 6.35-.69 9.62-.18"/>
                </g>
                <path fill="none" stroke="#e7287a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M599.2 216.37L629 194.2"/>
                <text transform="translate(643.87 190.34) scale(1.04 1)" fontSize="19.33" fill="#e7287a" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    PROJEKTI
                </text>
                <g fill="none" stroke="#e7287a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M634.23 168.6c-6.42 15.11-4.88 33.64 14.89 35.83s60.64-3.92 49.85-27.08-55-21.91-68.08-8.26M634.87 190.93c.17 6.19 8.59 11.34 14.26 11.34M652.05 202.62c1.38.17 3.44.17 4.64.34M658.07 161.89c7.73 0 14.26.17 21.31 4M682.99 166.87a15.54 15.54 0 013.95 1.55"/>
                </g>
                <path fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M481.5 328.36l5.44 29.12"/>
                <text transform="translate(478.51 386.17) scale(1.04 1)" fontSize="19.33" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji4}
                </text>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M475.63 367.78c-6.42 15.11-4.88 33.63 14.89 35.82s60.64-3.92 49.86-27.07-55-21.91-68.09-8.26M476.27 390.07c.17 6.18 8.59 11.34 14.26 11.34M493.46 401.79c1.37.17 3.43.17 4.64.35M499.52 361.07c7.74 0 14.27.17 21.31 3.95M524.39 366.07a16.16 16.16 0 014 1.55"/>
                </g>
                <path fill="none" stroke="#00ac9f" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M180.91 283.38l49.21 6.59M193.95 327.24l45.19-21.71M214.57 371.29l32.62-53.16"/>
                <text transform="translate(127.5 288.42) scale(1.04 1)" fontSize="15" fill="#00ac9f" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.prijatelji3}
                </text>
                <g fill="none" stroke="#00ac9f" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M107.4 278.34c-6.42 14.34-4.88 31.92 14.89 34s60.64-3.73 49.86-25.71-55-20.8-68.09-7.84M108.04 299.53c.17 5.88 8.59 10.77 14.26 10.77M125.23 310.63c1.37.16 3.43.16 4.64.32M131.24 271.96c7.74 0 14.27.16 21.31 3.75M156.16 276.69a16 16 0 014 1.47"/>
                </g>
                <text transform="translate(145.71 339.22) scale(1.04 1)" fontSize="14" fill="#00ac9f" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.prijatelji2}
                </text>
                <g fill="none" stroke="#00ac9f" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M127.41 330c-6.42 13.6-4.88 30.26 14.9 32.24s60.63-3.53 49.85-24.36-55-19.72-68.09-7.44M128.05 350.07c.17 5.57 8.59 10.21 14.27 10.21M145.24 360.61c1.37.15 3.44.15 4.64.31M151.25 323.96c7.74 0 14.27.15 21.31 3.56M176.17 328.44a16.76 16.76 0 014 1.4"/>
                </g>
                <text transform="translate(171.59 388.53) scale(1.04 1)" fontSize="17" fill="#00ac9f" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.prijatelji1}
                </text>
                <g fill="none" stroke="#00ac9f" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M155.52 379.12c-6.42 14.18-4.88 31.56 14.89 33.62s60.64-3.68 49.86-25.41-55-20.56-68.09-7.75M156.16 400.07c.17 5.81 8.59 10.65 14.26 10.65M173.35 411.07c1.37.16 3.43.16 4.64.32M179.36 372.82c7.74 0 14.27.16 21.31 3.71M204.28 377.49a16.35 16.35 0 014 1.45"/>
                </g>
                <path d="M610.58 287.52c-.68.46-52.7 6.82-52.7 6.82" fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(627.63 294.34) scale(1.04 1)" fontSize="19.33" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji1}
                </text>
                <text transform="translate(711.53 305.53) scale(1.04 1)" fontSize="14" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji1_1}
                </text>
                <text transform="translate(709.68 386.9) scale(1.04 1)" fontSize="14" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                   {props.hobiji1_3}
                </text>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M618.69 273.46c-8 13.75-3.44 33.92 23.15 36.9s34.69-20.22 29.1-30.71c-5.5-10.31-41.94-22-51.79-3.21M625.39 274.55c2.58-2.23 9.63-5.84 22.35-4.12M649.97 270.94a9.61 9.61 0 013.95 1"/>
                    <path d="M668.36 283.15c1.38 2.4.17 7.9-.69 10.48-2.4 7.39-9.62 10.14-16.32 12.72"/>
                </g>
                <path d="M365.32 329.53c-2.61 1.39-27.85 29.21-27.85 29.21" fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(302.96 390.36) scale(1.04 1)" fontSize="15" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">MATI / OČE</text>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M299.17 366.67c-8 13.75-3.44 33.92 23.15 36.9s34.69-20.22 29.1-30.71c-5.5-10.31-41.94-22-51.79-3.21M305.87 367.76c2.58-2.23 9.63-5.84 22.35-4.12M330.45 364.15a9.58 9.58 0 013.95 1"/>
                    <path d="M348.84 376.35c1.38 2.41.17 7.91-.69 10.49-2.4 7.39-9.62 10.14-16.32 12.72"/>
                </g>
                <path fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M538.64 327.02l30 47.71M575.52 433.68l2.87 20.61M628.72 385.3l8.24 24.51M678.21 291.83l23.29 7.15M671.35 307.05l41.75 36.01M661.63 316.28l33.99 58.45M645.17 375.3l44.69 38.31M596.49 426.38l28.33 40.43"/>
                <text transform="translate(563.06 412.37) scale(1.04 1)" fontSize="19.33" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji3}
                </text>
                <text transform="translate(563.06 480.5) scale(1.04 1)" fontSize="19.33" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji3_2}
                </text>
                <text transform="translate(620.76 490.83) scale(1.04 1)" fontSize="19.33" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji3_1}
                </text>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M567.83 388.9c-11.46.92-19.48 11-14 28.87s39.42 14.44 46.29-3-1.83-35.52-36-27M559.29 395.6c-4.64 4-4.46 12.2-2.92 17.7M557.06 416.4a10.37 10.37 0 001.2 3.43M586.11 388.55a10.51 10.51 0 018.07 4M589.43 392.74a5.4 5.4 0 013.78 2.92M595.39 393.88c2.06 2.24 3.61 5.5 3.43 8.25"/>
                </g>
                <path fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M413.7 362.66l-5.84-20.54"/>
                <text transform="translate(395.21 397.18) scale(1.04 1)" fontSize="15" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    ŽENA / MOŽ
                </text>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M402.66 371.64c-11.46.92-19.48 11-14 28.88s39.42 14.43 46.29-3-1.83-35.52-36-27M394.12 378.35c-4.64 4-4.46 12.2-2.92 17.7M391.89 399.14a10.43 10.43 0 001.2 3.44M420.94 371.3a10.53 10.53 0 018.07 3.95M424.26 375.48a5.43 5.43 0 013.78 2.92M430.22 376.63c2.06 2.23 3.61 5.5 3.43 8.25"/>
                </g>
                <path d="M467.39 280.77c-3.43-3.13-23.28-28.35-23.28-28.35M343.52 161.49c-3.44-3.13-18.4-21.31-18.4-21.31" fill="none" stroke="#434496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(489.68 305.53) scale(1.04 1)" fontSize="21.717" fill="#434496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    HOBIJI
                </text>
                <g fill="none" stroke="#434496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M462.13 283.88c5.95-7.33 58.66-14.21 77-5.73s26.55 39.56-6.41 46.75c-55.69 12.14-90.06-16-69-39.88M463.27 294.53c-3.78 12.72 4.47 17.53 7.39 19.43M472.52 314.99a9 9 0 004 2.57M527.72 279.93c10.31-.52 20.28 6.7 21.83 17M514.14 323.92c9.63 0 31.28-2.4 32.49-15.12M551.27 301.07a14.63 14.63 0 01-1.21 8.93"/>
                </g>
                <text transform="translate(245.9 134.27) scale(1.04 1)" fontSize="21.717" fill="#434496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    OSEBNA RAST
                </text>
                <g fill="none" stroke="#434496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M234.78 111.48c6-7.34 58.66-14.21 77-5.73s26.54 39.56-6.42 46.74c-55.68 12.15-90.06-16-69-39.87M235.93 122.13c-3.78 12.72 4.47 17.53 7.39 19.42M245.21 142.58a8.93 8.93 0 003.95 2.58M300.38 107.52c10.31-.51 20.28 6.71 21.82 17M286.8 151.52c9.62 0 31.28-2.41 32.48-15.12M323.92 128.66a14.66 14.66 0 01-1.2 8.94"/>
                </g>
                <path fill="none" stroke="#48b386" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M582.65 154.56l36.47-15.73"/>
                <text transform="translate(634.17 118.1) scale(1.04 1)" fontSize="16" fill="#48b386" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.izobrazba1}
                </text>
                <g fill="none" stroke="#48b386" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M613.76 115.38c9.88-17 29.64-19.71 48.62-13.29s15.6 36.66-17.16 40.56-31.7-22.69-25.7-30.25M648.04 102.32c4.58-.69 14.78 1.95 17.76 5.61M637.27 103.23a59.21 59.21 0 016.19-.8M667.63 109.19a9.11 9.11 0 012.29 2.75"/>
                </g>
                <path fill="none" stroke="#e7287a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M649.14 234.89l-45.32 1.72"/>
                <text transform="translate(668.73 238.64) scale(1.04 1)" fontSize="19.33" fill="#e7287a" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    VLOGE
                </text>
                <g fill="none" stroke="#e7287a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M652.88 225.3c9.88-17 29.64-19.71 48.62-13.29s15.6 36.66-17.16 40.56-31.72-22.69-25.74-30.25M687.16 212.24c4.58-.69 14.78 1.95 17.76 5.61M676.39 213.16a57 57 0 016.19-.81M706.75 219.07a9.11 9.11 0 012.29 2.75"/>
                </g>
                <path fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M597.09 332.52l-39.9-19.36"/>
                <text transform="translate(603.82 365.01) scale(1.04 1)" fontSize="19.33" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji2}
                </text>
                <text transform="translate(628.41 433.68) scale(1.04 1)" fontSize="18" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji2_1}
                </text>
                <text transform="translate(715.56 435.93) scale(1.04 1)" fontSize="18" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji2_2}
                </text>
                <text transform="translate(730.74 353.7) scale(1.04 1)" fontSize="15" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.hobiji1_2}
                </text>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M589 352.88c9.89-17 29.65-19.71 48.63-13.3s15.6 36.67-17.16 40.57-31.73-22.69-25.75-30.25M623.29 339.81c4.58-.68 14.78 1.95 17.75 5.62M612.52 340.73a57.29 57.29 0 016.19-.8M642.88 346.69a9.28 9.28 0 012.29 2.75"/>
                </g>
                <path d="M343.4 173.79c14.1-46.41 97.39-48.93 117.67.23s-9.62 95.9-69.77 92.12-76.66-75.62-43.31-107.59" fill="none" stroke="#eb5b28" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(356.01 215.36) scale(1.04 1)" fontSize="36.27" fill="#eb5b28" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.ime}
                </text>
                <path d="M340.65 224.89c-20.16-50.64 19-86.39 70.35-81.58s57.75 34.37 59.35 57.75-14.24 61-75.16 63.24c-68.75 2.53-94.18-123.74-1.14-126 38.26-.94 66.22 28.64 68.74 50.87" fill="none" stroke="#eb5b28" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path d="M474.52 195.37c9-2 29.73-10.06 29.73-10.06" fill="none" stroke="#47b385" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(518.1 184.14) scale(1.04 1)" fontSize="21.717" fill="#47b385" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    IZOBRAZBA
                </text>
                <g fill="none" stroke="#47b385" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M513.25 157.27c-6.91 1.6-6.91 19.93-2.71 31.85s46.1 7.1 62.13 5.73 10.85-27.5 9.62-34.37-59.67-9.4-70.28-.23M543.34 157.84c7-.69 18-.57 22.62 1M568.94 159.56a12.45 12.45 0 015.05 0M515.09 189.58c3 4.58 14.67 3.89 19.48 3.66M537.78 194.16a23.42 23.42 0 014.12.23"/>
                </g>
                <path d="M623.31 98.2c-.45 7.56-8.66-51.9 8.76-54.19" fill="none" stroke="#48b386" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(645.82 42.92) scale(1.04 1)" fontSize="19.33" fill="#48b386" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.izobrazba1_1}
                </text>
                <g fill="none" stroke="#48b386" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M638.03 27.07c-2.06 8.48-6 24.52 4.58 25s42.4 1.37 42.4-7.11 3-22.22-7.8-22.91-36.89-1.88-41.69 5.23M663.01 24.01c7-.51 15.29 0 16.5 1.38M638.09 43.43c.17 3.27.86 6.71 8.42 6.54M650.12 49.62l4.47.34"/>
                </g>
                <path d="M658.08 95.41c0-12.34 17.21-11.63 34.63-13.92" fill="none" stroke="#48b386" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(700.54 80.4) scale(1.04 1)" fontSize="16" fill="#48b386" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.izobrazba1_2}
                </text>
                <text transform="translate(753.83 154.3) scale(1.04 1)" fontSize="16" fill="#e7287a" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.poklic1_1}
                </text>
                <text transform="translate(769.11 189.58) scale(1.04 1)" fontSize="16" fill="#e7287a" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.poklic1_2}
                </text>
                <text transform="translate(747.68 226.74) scale(1.04 1)" fontSize="14" fill="#e7287a" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.poklic2_1}
                </text>
                <text transform="translate(761.58 261.68) scale(1.04 1)" fontSize="16" fill="#e7287a" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.poklic2_2}
                </text>
                <g fill="none" stroke="#48b386" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M698.66 64.53c-2.06 8.48-5.95 24.52 4.59 25s42.39 1.37 42.39-7.11 3-22.23-7.79-22.91-36.89-1.84-41.71 5.27M723.64 61.49c7-.51 15.3 0 16.5 1.38M698.72 80.91c.17 3.27.86 6.71 8.42 6.53M710.76 87.1l4.47.34"/>
                </g>
                <path d="M676.34 120.07c7.44-.69 7.36 2.17 24.78-.12" fill="none" stroke="#48b386" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path d="M715.52 242.33c6.28 4.4 15.19 10.17 22.63 12.85M718.21 221.86c8.07-2.41 10.81-4.42 21.62-2.75M703.83 188.82c7.51.58 20.43-7.53 36-4.11M695.61 168.3c7.44-.69 15.26-11.71 32.68-14" fill="none" stroke="#e7287a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(711.3 118.9) scale(1.04 1)" fontSize="16" fill="#48b386" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.izobrazba1_3}
                </text>
                <g fill="none" stroke="#48b386" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M707.07 103.07c-2.06 8.48-5.95 24.52 4.59 25s42.39 1.38 42.39-7.1 3-22.23-7.79-22.92-36.9-1.83-41.71 5.27M732.05 100c7.05-.52 15.3 0 16.5 1.37M707.13 119.42c.17 3.26.86 6.7 8.42 6.53M719.17 125.61l4.46.34"/>
                </g>
                <path d="M512.75 117.54a42.38 42.38 0 0112.77-4.66" fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(542.63 105.07) scale(1.04 1)" fontSize="19.33" fill="#30aae2" fontFamily="Amatic-Bold,Amatic" fontWeight="700" letterSpacing="-.042em">
                    {props.dom1_2}
                </text>
                <g fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M536.87 87.47c-8 4.58-10.16 22.91-1.33 23.83s42 2.29 49.45-1.15 6.94-19 .8-22.45-44.11-3.67-50.52 4.12M533 96.35c-.4 2.92-1.81 11.51 2.6 12.72M538.61 109.41l3.21.69M572.09 87.75c3 0 12.23.86 13.63 3.1"/>
                </g>
                <g fill="none" stroke="#e7287a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M748.71 246.44c-6.87 4.59-8.7 22.92-1.14 23.84s36 2.29 42.39-1.15 6-19 .69-22.46-37.81-3.66-43.31 4.13M745.39 255.32c-.34 2.93-1.55 11.52 2.24 12.72M750.21 268.39l2.75.68M778.9 246.73c2.58 0 10.49.86 11.69 3.09"/>
                </g>
                <path d="M473.82 92.07c-2.06-7.34-12.14-17.42 12.38-18.34" fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(497.73 67.8) scale(1.04 1)" fontSize="19.33" fill="#30aae2" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                   {props.dom1_1}
                </text>
                <g fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M491.32 55.24c-3.8 9.63-6.56 20.4 13.46 20.85s76.3 1.15 77.68-8.47-4.83-18.57-19.33-18.79-68.36-4.36-74.61 6.18M492.7 65.78c0 2.92 1.56 8.42 14.5 8.6M552.77 50.83c5.18-.34 17.86-.52 21.49 4.13" stroke="#30aae2"/>
                    <path d="M576.33 57.36s2.33 3.44 2.07 4.13" stroke="#36a9e1"/>
                </g>
                <g fill="none" stroke="#e7287a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M744.44 215.07c-2.52 9.62-4.35 20.39 8.94 20.85s50.64 1.14 51.56-8.48-3.21-18.56-12.83-18.79-45.37-4.35-49.5 6.19M745.36 225.57c0 2.92 1 8.42 9.62 8.59M785.23 210.61c3.44-.34 11.86-.51 14.27 4.13M800.87 217.15s1.55 3.43 1.38 4.12"/>
                </g>
                <path d="M430.02 139.41c2.29-20.62 8.94-41.24-11.46-42.62M370.69 300.66c-4.57-15.67-12.44-30.75 2.83-34.59" fill="none" stroke="#f39323" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(375 104.71) scale(1.04 1)" fontSize="21.717" fill="#f39323" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    CILJI
                </text>
                <text transform="translate(381.75 319.1) scale(1.04 1)" fontSize="21.717" fill="#f39323" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                   DRUŽINA
                </text>
                <g fill="none" stroke="#f39323" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M365.17 72.96c-12.6 7.56-15.81 36.89-.23 44.91s48.35 5.5 50.19-10.08 3.66-34.6-9.63-37.12-36.2-1.61-42.62 7.1M386.52 71.87c8.94 0 22.35.52 24.92 6.53M412.26 81.67a56.92 56.92 0 01.86 6M357.61 94.9c-.52 4.3 1.89 15.3 6.19 18.22M360.88 92.15a30.26 30.26 0 00-.35 6.19"/>
                </g>
                <g fill="none" stroke="#f39323" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M381.75 287.07c-12.6 7.56-15.81 36.89-.23 44.91s48.35 5.5 50.18-10.08 3.67-34.6-9.62-37.12-36.21-1.61-42.62 7.1M403.06 285.99c8.94 0 22.34.52 24.92 6.53M428.84 295.79a56.92 56.92 0 01.86 6M374.19 309.07c-.52 4.3 1.89 15.3 6.18 18.22M377.45 306.27a30.75 30.75 0 00-.34 6.19"/>
                </g>
                <path d="M249.16 208.53c-12.51 2-61.75-16.09-72.51-12.57" fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path d="M369.61 68.99c0-7.81-8.63-22.25-14.32-27.94M401.33 33.86c-5.61 5.43-9.94 21.69-10.05 29.74" fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(302.03 34.39) scale(1.04 1)" fontSize="15" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.cilji1_1}
                </text>
                <text transform="translate(380.51 19.84) scale(1.06 1)" fontSize="11.946" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.cilji1_2}
                </text>
                <g fill="none" stroke="#e7287a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M733.52 143.31c-3.61 6-1 16.67 4.47 17.7s40.73 1 47.95-1 7.39-10.83 5.16-18.9-47.78-9.46-59.81 1.37M762.23 137.81c4.3-.34 8.6-.69 12.89.35M777.87 138.16c1.21 0 2.24-.18 3.44-.18M740.58 140.56c-5 1.89-8.08 10.49-4.64 14.61"/>
                </g>
                <g fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M92.34 153.51c-3.61 6-1 16.67 4.47 17.7s40.73 1 48-1 7.39-10.83 5.16-18.9-47.78-9.46-59.81 1.37M121.04 148.01c4.3-.34 8.6-.69 12.89.34M136.68 148.35c1.21 0 2.24-.17 3.44-.17M99.39 150.76c-5 1.89-8.08 10.48-4.64 14.61"/>
                </g>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M374.76 9.64c-3.61 6-1 16.67 4.47 17.7s40.73 1 48-1 7.39-10.83 5.15-18.91-47.86-9.48-59.86 1.35M403.46 4.14c4.3-.35 8.59-.69 12.89.34M419.1 4.48c1.2 0 2.23-.17 3.44-.17M381.8 6.89c-5 1.89-8.07 10.48-4.64 14.61"/>
                </g>
                <path d="M156.27 154.29c11-3.53 89.8 39.57 92.89 39.4" fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(18.32 355.85) scale(1.04 1)" fontSize="12" fill="#00ac9f" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.prijatelji2_1}
                </text>
                <text transform="translate(55.99 413.61) scale(1.04 1)" fontSize="16" fill="#00ac9f" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.prijatelji1_1}
                </text>
                <text transform="translate(13.23 301.06) scale(1.04 1)" fontSize="16" fill="#00ac9f" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.prijatelji3_1}
                </text>
                <text transform="translate(161.2 72.15) scale(1.04 1)" fontSize="12" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.osebnaRast1_1}
                </text>
                <text transform="translate(214.57 29.84) scale(1.04 1)" fontSize="17" fill="#444496" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.osebnaRast1_2}
                </text>
                <g fill="none" stroke="#e7287a" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M748.69 174.21c-3.27 6.36-.46 21.14 6.41 20.63s56.38 1.08 57.75-4.59 2.35-19.36-3-21.08-59-1.32-64.62 5.21M756.52 173.64c3.95-2.06 10.83-1.55 15.3-2.06M773.84 172.07a13.17 13.17 0 013.61-.51"/>
                </g>
                <g fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M106.22 190.07c-3.26 6.36-.45 21.14 6.42 20.63s56.37 1.08 57.75-4.59 2.35-19.36-3-21.08-58.95-1.32-64.62 5.21M114.02 189.48c3.95-2.06 10.82-1.55 15.29-2.06M131.37 187.93a13.28 13.28 0 013.61-.51"/>
                </g>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M209.78 14.13c-3.27 6.36-.46 21.14 6.41 20.63s56.33 1.08 57.75-4.59 2.35-19.36-3-21.08-58.95-1.32-64.62 5.21M217.57 13.56c3.95-2.06 10.82-1.55 15.29-2.06M234.93 12.01a13.15 13.15 0 013.6-.51"/>
                </g>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M294.24 20.42c-3.27 6.36-.46 21.14 6.41 20.62s56.38 1.09 57.75-4.58 2.35-19.37-3-21.08-58.88-1.31-64.6 5.21M302.03 19.84c3.95-2.06 10.83-1.54 15.3-2.06M319.39 18.3a12.81 12.81 0 013.61-.52"/>
                </g>
                <path d="M146.87 393.76c-7.27-2.84-23.86 0-31.22 3.25M88.73 348.07c4.13-.17 18.22 2.24 21.31 2.06M99.52 292.96c-1.55 3.65-10 8.21-31.28 8" fill="none" stroke="#00ac9f" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path d="M158.52 126.47c33.75-.6 70.92 29.36 96.55 57.67M249.16 231.83c-3.35 5.33-26.31 7.12-72.51 6.78" fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path d="M260.78 39.46c5.92 1.57 15.6 14.68 22.73 51.39M226.91 103.15c-5.08 2.63-15.85 1.16-30.14-14.65" fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(245.82 385.12) scale(1.04 1)" fontSize="19.33" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.druzina1_ocemati_4}
                </text>
                <text transform="translate(222.3 432.05) scale(1.04 1)" fontSize="19.33" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.druzina1_ocemati_3}
                </text>
                <text transform="translate(251.29 473.52) scale(1.04 1)" fontSize="19.33" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.druzina1_ocemati_2}
                </text>
                <text transform="translate(400.38 464.95) scale(1.04 1)" fontSize="15" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.druzina1_zenamoz_2}
                </text>
                <text transform="translate(490.1 462.69) scale(1.04 1)" fontSize="15" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.druzina1_zenamoz_1}
                </text>
                <text transform="translate(99.39 165.37) scale(1.04 1)" fontSize="16" fill="#30aae2" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.vrednote1_3}
                </text>
                <text transform="translate(110.18 204.83) scale(1.04 1)" fontSize="18" fill="#30aae2" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.vrednote1_2}
                </text>
                <text transform="translate(121.67 244.42) scale(1.04 1)" fontSize="19.33" fill="#30aae2" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.vrednote1_1}
                </text>
                <text transform="translate(97.84 126) scale(1.04 1)" fontSize="14" fill="#30aae2" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.vrednote1_4}
                </text>
                <path d="M345.12 466.54c-1.54-41.92-3.94-52.48-7.35-60.79" fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M234.02 461.99c-3.6 6-1 16.67 4.47 17.7s40.74 1 48-1 7.39-10.83 5.16-18.91-47.78-9.45-59.81 1.38M262.73 456.49c4.29-.35 8.59-.69 12.89.34M278.37 456.83c1.2 0 2.23-.17 3.43-.17M241.07 459.24c-5 1.89-8.08 10.48-4.64 14.6"/>
                </g>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M545.24 468.65c-3.61 6-1 16.67 4.46 17.7s40.74 1 48-1 7.39-10.83 5.15-18.91-47.78-9.45-59.81 1.38M573.94 463.15c4.29-.35 8.59-.69 12.89.34M589.58 463.49c1.2 0 2.23-.17 3.44-.17M552.28 465.9c-5 1.89-8.08 10.48-4.64 14.6"/>
                </g>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M621.67 421.82c-3.61 6-1 16.67 4.47 17.7s40.73 1 48-1 7.39-10.83 5.15-18.9-47.78-9.46-59.81 1.37M650.37 416.32c4.3-.34 8.59-.69 12.89.35M666.01 416.67c1.2 0 2.23-.18 3.44-.18M628.72 419.07c-5 1.89-8.08 10.49-4.65 14.61"/>
                </g>
                <path d="M289.17 373.74h4.63" fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M199.21 418.14c-3.26 6.35-.46 21.14 6.42 20.62s56.37 1.09 57.75-4.58 2.34-19.37-3-21.09-58.95-1.31-64.63 5.22M207 417.56c4-2.06 10.83-1.54 15.3-2.06M224.36 416.07a12.86 12.86 0 013.61-.52"/>
                </g>
                <path d="M481.52 435.93c-7.66-21.06-29-32.31-39.33-29.44" fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M474.45 454.97c-3.61 11.17-1 31 4.47 32.89s40.73 1.91 48-1.92 7.39-20.11 5.16-35.12-47.78-17.56-59.81 2.55M503.15 444.75c4.3-.63 8.6-1.27 12.89.64M518.79 445.39c1.21 0 2.24-.32 3.44-.32M481.52 449.86c-5 3.51-8.08 19.48-4.64 27.14"/>
                </g>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M700.3 379.49c-4.56 9.15-1.31 25.37 5.64 26.94s51.43 1.57 60.54-1.57 9.33-16.48 6.51-28.77-60.33-14.39-75.52 2.09M736.52 371.12c5.42-.52 10.85-1.05 16.27.52M756.28 371.64c1.52 0 2.82-.26 4.34-.26M709.19 375.3c-6.29 2.88-10.2 16-5.86 22.24"/>
                </g>
                <path d="M407.8 416.26c2.14 3.53 6.81 17 8.45 19.67" fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M387.66 451.17c-3.27 12.22-.46 40.62 6.42 39.63s56.37 2.09 57.74-8.8 2.35-37.21-3-40.51-58.95-2.54-64.62 10M395.45 450.07c3.95-4 10.83-3 15.3-4M412.81 447.07a7.45 7.45 0 013.61-1"/>
                </g>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M707.27 293.27c-3.26 10.59-.46 35.21 6.42 34.35s56.37 1.82 57.74-7.63 2.35-32.26-3-35.12-59-2.2-64.63 8.68M715.06 292.31c4-3.43 10.83-2.58 15.3-3.43M732.42 289.73a8.36 8.36 0 013.61-.85"/>
                </g>
                <text transform="translate(325.27 493.56) scale(1.04 1)" fontSize="19.33" fill="#f39324" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    {props.druzina1_ocemati_1}
                </text>
                <path d="M292.11 453.74c3.19-8.66 17.5-36.87 19.84-43.38" fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M300.11 479.43c-3.26 6.36-.46 21.14 6.42 20.62s56.37 1.09 57.75-4.58 2.35-19.37-3-21.08-58.95-1.32-64.62 5.21M307.9 478.85c4-2.06 10.83-1.54 15.3-2.06M325.26 477.31a12.92 12.92 0 013.61-.52"/>
                </g>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M612.96 476.2c-3.26 6.36-.46 21.14 6.42 20.62s56.37 1.09 57.75-4.58 2.34-19.36-3-21.08-59-1.32-64.62 5.21M620.75 475.63c4-2.06 10.83-1.55 15.3-2.06M638.11 474.07a13.28 13.28 0 013.61-.51"/>
                </g>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M696.22 420.28c-3.26 6.36-.46 21.14 6.42 20.62s56.37 1.09 57.75-4.58 2.34-19.36-3-21.08-58.95-1.32-64.62 5.21M704.01 419.71c4-2.07 10.83-1.55 15.3-2.07M721.37 418.16a13.29 13.29 0 013.61-.52"/>
                </g>
                <path d="M299.9 404.7c-1.55 5.5-10 12.37-31.28 12" fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <g fill="none" stroke="#f39324" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M227.04 370.23c-4.82 7-2 19.59 6.58 19.25s45.2 1.37 49.5-.86 6.36-13.06 1.2-18.39-52.07-4.81-55 2.23M228.18 375.9c-2.23 2.75-.17 10.48 3.61 10.83M235.4 387.07a7.48 7.48 0 002.75 0M275.62 370.23c5.15.86 7.73 1.2 8.76 6.36M284.35 380.71c.17 2.07-.35 3.61-.18 4.64"/>
                </g>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M722.37 339.88c-4.81 7-2 19.59 6.59 19.25s45.2 1.37 49.5-.86 6.36-13.07 1.2-18.39-52.07-4.82-55 2.23M723.52 345.55c-2.24 2.75-.17 10.48 3.61 10.83M730.74 356.72a7.48 7.48 0 002.75 0M770.95 339.88c5.16.86 7.74 1.2 8.77 6.36M779.68 350.36c.17 2.06-.34 3.61-.17 4.64"/>
                </g>
                <g fill="none" stroke="#00ac9f" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M46.52 401.75c-3.61 6-1 16.67 4.46 17.7s40.74 1 48-1 7.39-10.83 5.15-18.91-47.83-9.47-59.86 1.35M75.21 396.25c4.29-.34 8.59-.69 12.89.34M90.85 396.59c1.2 0 2.23-.17 3.44-.17M53.52 399c-5 1.89-8.07 10.48-4.64 14.61"/>
                </g>
                <g fill="none" stroke="#00ac9f" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M12.17 342.73c-3.26 6.36-.46 21.14 6.42 20.62s56.37 1.09 57.75-4.58 2.34-19.36-3-21.08-58.95-1.32-64.62 5.21M19.96 342.16c4-2.07 10.83-1.55 15.3-2.07M37.32 340.61a13.29 13.29 0 013.61-.52"/>
                </g>
                <g fill="none" stroke="#00ac9f" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M3.34 288.95c-4.82 7.05-2 19.59 6.58 19.25s45.2 1.38 49.5-.86 6.36-13.06 1.2-18.39-52.07-4.81-55 2.24M4.52 294.62c-2.23 2.75-.17 10.49 3.61 10.83M11.7 305.79a7.07 7.07 0 002.75 0M51.92 288.95c5.15.86 7.73 1.2 8.76 6.36M60.65 299.43c.17 2.07-.35 3.61-.18 4.65"/>
                </g>
                <g fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M107.69 229.3c-4.82 7-2 19.59 6.58 19.25s45.2 1.37 49.5-.86 6.36-13.07 1.2-18.39-52.07-4.82-55 2.23M108.83 234.97c-2.23 2.75-.17 10.48 3.61 10.83M116.05 246.14a7.48 7.48 0 002.75 0M156.27 229.3c5.15.86 7.73 1.2 8.76 6.36M165 239.78c.17 2.06-.35 3.61-.18 4.64"/>
                </g>
                <g fill="none" stroke="#30aae2" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M91.65 113.07c-4.82 7-2 19.6 6.58 19.25s45.2 1.38 49.5-.86 6.36-13.06 1.2-18.39-52.07-4.81-55 2.24M92.79 118.74c-2.23 2.75-.17 10.48 3.61 10.82M100.01 129.91a7.48 7.48 0 002.75 0M140.23 113.07c5.15.86 7.73 1.21 8.76 6.36M148.96 123.55c.17 2.06-.35 3.61-.18 4.64"/>
                </g>
                <g fill="none" stroke="#444496" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M154.23 58.57c-4.81 7-2 19.59 6.59 19.25s45.2 1.37 49.49-.86 6.36-13.06 1.21-18.39-52.08-4.81-55 2.23M155.37 64.24c-2.23 2.75-.17 10.48 3.61 10.83M162.59 75.41a7.48 7.48 0 002.75 0M202.81 58.57c5.15.86 7.73 1.2 8.76 6.36M211.52 69.07c.17 2.06-.35 3.61-.17 4.64"/>
                </g>
                <path d="M338.97 252.42c-3.81 2-39.61 18.14-39.61 18.14" fill="none" stroke="#05ab9e" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <text transform="translate(247.03 298.98) scale(1.04 1)" fontSize="19.33" fill="#05ab9e" fontFamily="Amatic-Bold,Amatic" fontWeight="700">
                    PRIJATELJI
                </text>
                <g fill="none" stroke="#05ab9e" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
                    <path d="M239.14 287.15c-.27-21.1 37.3-27.37 51.36-16.53s14.86 44.95-23.52 43.89c-31.09-.85-25.95-31.64-22.44-32.78M256.03 271.07c5.27-2.56 15.41-3.2 15.41-3.2M253.4 271.97a25.11 25.11 0 00-4.06 2.78"/>
                </g>
            </svg>
        </div>
    )
}

export default OsebnaKvantnaStruktura
