import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment11_5(props) {
    const [naloga11_5, set11_5] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga11_5} className="notebook-like" onChange={(e) => set11_5(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga11_5",naloga11_5)} >Shrani</Button>
        </div> 
    )
}

export default Assignment11_5;
