import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT11_4 from "../pages/assignments/Assignment11_4"

function naloga11_4(props) {
    return (
        <div>
            <PageHeading heading="11.4 Naloga" img="pencil" />

            <h5>Poiščite sopomenke v slovenskem jeziku naslednjim besedam.</h5>

            <ASSIGNMENT11_4 handleSave={props.handleSave} data={props.data} />

            <p>Besedne igre koristijo možganom, ker aktivirajo dele možganov, ki se ukvarjajo z iskanjem jezika in besed, kar možgane prisili, da telovadijo, delajo in so aktivni. Tako se v možganih ustvarjajo nove nevronske poti, krepi kritično razmišljanje in sposobnost reševanja problemov.</p>
            
            <ButtonNextSection link={"/11-5-naloga"} nextSection={"11.5 Naloga"} />
        </div> 
    )
}

export default naloga11_4