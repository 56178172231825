import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment3_1(props) {
    const [naloga3_1, set3_1] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga3_1} className="notebook-like" onChange={(e) => set3_1(e.target.value)}>
                </textarea>
            </div> 
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga03_1",naloga3_1)} >Shrani</Button>
        </div>

    )
}

export default Assignment3_1;
