import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT1_1 from "./assignments/Assignment1_1";


function _1_1_Naloga(props) {
    
        return (
            <div>
                <PageHeading heading="1.1 Naloga" img="pencil" />

                <p>Prepoznajte svoje talente s pomočjo prijetnih slik iz otroštva. Znova se sprehodite po šolskih hodnikih, igriščih, otroških sobah in parkih. Spomnite se najlepših dogodkov, ki imajo posebno mesto v vašem srcu. Spomnite se pozitivnih oseb, ki so soustvarjale vaše dogodivščine. Skratka bodite otroci in sledite magičnim trenutkom sreče, v katerih ste bili nasmejani, energični in zadovoljni.</p>

                <div className="naloge">
                    <h3>1. Naloga:</h3>
                    <div className="nalogaHeadingItem">
                        <h5>1.1. Zapišite vse svoje darove, potenciale, talente.</h5>
                    </div>
                    <div className="nalogaHeadingItem">
                        <h5>1.2. Z oceno od 1 do 10 ocenite stopnjo razvitosti vašega talenta/talentov. Poskusite biti objektivni.</h5>
                    </div>
                    <div className="nalogaHeadingItem">
                        <h5>1.3. Ocenite intenzivnost uporabe in zapišite ali talent uporabljate:<br />
                    zmerno, občasno, pogosto ali vedno.</h5>
                    </div>
                    <div className="nalogaHeadingItem">
                    </div>
                </div>

                <ASSIGNMENT1_1 handleSave={props.handleSave} data={props.data} />

                <ButtonNextSection link={"/2-katere-so-moje-vrednote"} nextSection={"2. Katere so moje vrednote?"} />
            </div> 
        )
    
}

export default _1_1_Naloga;