import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT12_4 from "../pages/assignments/Assignment12_4"

function naloga12_4(props) {
    return (
        <div>
            <PageHeading heading="12.4 Naloga" img="pencil" />

            <h5>Zapišite vse kar lahko VI naredite, da bi svoje prepričanje spremenili?</h5>

            <ASSIGNMENT12_4 handleSave={props.handleSave} data={props.data} />
            
            <ButtonNextSection link={"/12-5-naloga"} nextSection={"12.5 Naloga"} />
        </div> 
    )
}

export default naloga12_4