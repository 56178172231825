import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function KajJeMojeSporocilo() {
    return (
        <div>
            <PageHeading heading="3. Kaj je moje sporočilo?" img="idea" />
            <p>Gre za najpomembnejše sporočilo na svetu, ki ga je nedvomno genialni Mahatma Gandhi definiral takole: »Tvoje življenje je tvoje sporočilo.« Od vzpostavitve in aktivnosti v duhu vašega sporočila je odvisno ali bo vaše življenje navdih vašim otrokom, prijateljem in naključnim ljudem v okolici ali opozorilo.</p>
            <p>Da bi prepoznali svoje sporočilo je potrebno, da pogledate sami sebe v oči in si odgovorite na vprašanje: Kaj je tvoje sporočilo za katerega si želiš, da ga svet sliši? Kakšen odtis želiš pustiti na svetu? Gre za sporočilo, ki ga hranite globoko v sebi. Sporočilo, ki lahko spremeni življenje na boljše 10, 100 ali tisočim ljudem.</p>
            
            <ButtonNextSection link={"/3-1-naloga"} nextSection={"3.1 Naloga"} />
        </div>     
    )
}

export default KajJeMojeSporocilo;
