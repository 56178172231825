import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT6_2 from "./assignments/Assignment6_2";

function naloga6_2(props) {
    return (
        <div>
            <PageHeading heading="6.2 Naloga" img="pencil" />

            <h5>KVANTNA STRUKTURA ZAPOSLENEGA</h5>

            <ASSIGNMENT6_2 handleSave={props.handleSave} data={props.data} />

            <p>Če poznate 40 % ali manj osebnosti zaposlenega posledično pomeni, da zaposleni:</p>
            <ul>
                <li>V svoje delo ne vključujejo in ne koristijo vseh svojih potencialov</li>
                <li>Niso medsebojno povezani v meri kot bi lahko bili</li>
                <li>Ne dosegajo maksimalnih rezultatov</li>
            </ul>

            <p>Ker ste zagotovo izbrali zaposlenega, ki ga najbolj poznate pomeni, da preostale člane tima poznate še manj. Namreč kjer ni komunikacije ne more biti zadostnega timskega duha izraženega v medsebojni povezanosti, požrtvovalnosti za drugega, izmenjevanju idej, zagnanosti in sočutju kot temeljem za doseganje izrednih rezultatov.</p>
            <p>A tudi v tem primeru ni nobene potrebe za razočaranje. Eventualen slab rezultat pomeni, da imate še veliko prostora za napredek.</p>

            <ButtonNextSection link={"/6-3-naloga"} nextSection={"6.3 Naloga"} />
        </div> 
    )
}

export default naloga6_2;