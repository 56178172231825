import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT2_2 from "./assignments/Assignment2_2";

function naloga2_2(props) {
    return (
        <div>
            <PageHeading heading="2.2 Naloga" img="pencil" />

            <h5>Spomnite se trenutka, ko ste bili v službi srečni, zadovoljni in ponosni nase? Vzemite si čas in opišite navedeno situacijo.</h5>
            
            <h5>A) Identificirajte vzroke, ki so pripeljali do zadovoljstva.</h5>
            <h5>B) Katere vaše vrednote so se tedaj potrdile.</h5>

            <ASSIGNMENT2_2 handleSave={props.handleSave} data={props.data}/>

            <ButtonNextSection link={"/2-3-naloga"} nextSection={"2.3 Naloga"} />
        </div> 
    )
}

export default naloga2_2;