import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function KajJeMojMoto() {
    return (
        <div>
            <PageHeading heading="4. Kaj je moj moto?" img="idea" />
            <p>Zakaj je tako enostavno pozabiti svoje plemenite cilje – ustvarjati, telovaditi, zdravo jesti, meditirati, učiti se? Spopadanje z neposrednimi težavami večkrat povzroči v nas tolikšen stres, da enostavno odpovemo in ne najdemo motivacije za realizacijo nam še tako ljubih aktivnosti.</p>
            <p>Kaj lahko naredimo? Imejmo moto, ki ima nenavadno moč, ki hipno deluje. V trenutku nas lahko poveže z našimi najglobljimi vrednotami in cilji.</p>
            <p>Po definiciji je moto kratka izjava, ki se uporablja za izražanje načela, cilja ali ideje" ali izjava, "sprejeta kot vodnik za vedenje." Zapletene ideje se izgubijo v prostoru medtem, ko se s preprostim motom lahko v hipu spomnite, kaj je za vas resnično pomembno. Izgovorite ga nekajkrat - občutili boste mir in dobili motivacijo za življenje svojega sporočila.</p>
            <p>Zakaj imeti <b>MOTO?</b></p>
            <ul>
                <li>Destruktivno razmišljanje spremeni v zdrav pogovor s samim seboj.</li>
                <li>Slabo navado spremeni v dobro.</li>
                <li>Opomni na tisto kar je pomembno.</li>
                <li>Pomirja.</li>
                <li>Navdihuje.</li>
                <li>Poveča produktivnost.</li>
                <li>Poveča vztrajnost.</li>
            </ul>
            <ButtonNextSection link={"/4-1-naloga"} nextSection={"4.1 Naloga"} />
        </div>     
    )
}

export default KajJeMojMoto;
