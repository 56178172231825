import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT5_3 from "./assignments/Assignment5_3";

function naloga5_3(props) {
    return (
        <div>
            <PageHeading heading="5.3 Naloga" img="pencil" />

            <h5>Spomnite se trenutka, ko ste spoznali vašega partnerja/partnerico. Kaj je takrat na vas naredilo največji vtis? Kaj bi izpostavili kot njegove/njene najboljše lastnosti?</h5>

            <ASSIGNMENT5_3 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/5-4-naloga"} nextSection={"5.4 Naloga"} />
        </div> 
    )
}

export default naloga5_3;