import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment11_1(props) {
    const [naloga11_1, set11_1] = useState(props.data)

    return (
        <div className="assignment">
            <div onClick={ () => (set11_1("a.) 50 do 60 %"))}><h5><span className={naloga11_1 === "a.) 50 do 60 %" ? "circled-number" : ""}>a.)</span> 50 do 60 %</h5></div>
            <div onClick={ () => (set11_1("b.) 35 do 45 %"))}><h5><span className={naloga11_1 === "b.) 35 do 45 %" ? "circled-number" : ""}>b.)</span> 35 do 45 %</h5></div>
            <div onClick={ () => (set11_1("c.) 23 do 29 %"))}><h5><span className={naloga11_1 === "c.) 23 do 29 %" ? "circled-number" : ""}>c.)</span> 23 do 29 %</h5></div>
            <div onClick={ () => (set11_1("d.) 15 do 20 %"))}><h5><span className={naloga11_1 === "d.) 15 do 20 %" ? "circled-number" : ""}>d.)</span> 15 do 20 %</h5></div>
            <div className="flex margin-top-1">
                <h5>Odgovor:</h5>
                <div className="answer-underlined"><h6>{naloga11_1}</h6></div>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga11_1",naloga11_1)} >Shrani</Button>
        </div> 
    )
}

export default Assignment11_1;
