import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT11_1 from "../pages/assignments/Assignment11_1"

function naloga11_1(props) {
    return (
        <div>
            <PageHeading heading="11.1 Naloga" img="pencil" />

            <h5>V kolikšni meri IQ določa uspeh v karieri?</h5>

            <ASSIGNMENT11_1 handleSave={props.handleSave} data={props.data} />
            
            <ButtonNextSection link={"/11-3-naloga"} nextSection={"11.3 Naloga"} />
        </div> 
    )
}

export default naloga11_1