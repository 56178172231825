import React from "react";
import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import genialniZvezekNaslovnica from "./genialniZvezekNaslovnica.jpg";
import osebnaKvantnaStrukturaImg from "./osebna-kvantna-struktura.png"
import osebnaKvantnaStrukturaSteveJobs from "../images/osebna-kvantna-struktura-jobs.jpg"
import { naloga2_1_vrednote } from "../pages/constants";
import { v4 as uuid_v4 } from "uuid";

Font.register({
    family: "Roboto",
    src:
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
  });
const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COL4_WIDTH = 25
const COL60_WIDTH = 60
const COL50_WIDTH = 50
const COL40_WIDTH = 40
const COL30_WIDTH = 30
const COL20_WIDTH = 20
const COLN_WIDTH = (100 - COL1_WIDTH) / 3
const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        fontFamily: 'Roboto',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    pageNoPadding: {
        backgroundColor: "#ffffff",
        fontFamily: 'Roboto',
        paddingTop: 0,
        paddingBottom: 0,
        paddingHorizontal: 0
    },
    container: {
        marginBottom: 10
    },
    col: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
        alignSelf: "stretch"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35
    },
    active: {
        borderColor: "#16264c",
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 25,
    },
    title: {
        fontSize: 22,
        fontWeight: 600,
        marginBottom: 30,
        color: "#009599",
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 10,
        color: "#009599",
    },
    subsubtitleClean: {
        fontSize: 14,
        fontWeight: 600,
    },
    subsubtitle: {
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 5
    },
    text: {        
        fontSize: 12,
        textAlign: 'justify',
        marginBottom: 15,
    },
    textList: {
        fontSize: 12,
        marginBottom: 0,
        paddingLeft: 20
    },
    textListHead: {
        fontSize: 12,
        textAlign: 'justify',
        marginBottom: 5,
    },
    bulletList: {
        marginTop: 10,
        marginBottom: 20,
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        padding: 5,
    },
    flexColRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        padding: 5,
    },
    flexItem: {
        fontSize: 12,
        marginLeft: 5,
        marginRight: 5,
    },
    flexCol3: {
        display: "flex",
        flexWrap: "wrap",
        marginRight: "2pt",
        marginLeft: "2pt"
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        marginTop: 30,
        marginBottom: 30
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableCol1Header: { 
        width: COL1_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
    },     
    tableCol4Header: { 
        width: COL4_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
    },     
    tableCol60Header: { 
        width: COL60_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
    },     
    tableCol50Header: { 
        width: COL50_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
    },     
    tableCol40Header: { 
        width: COL40_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
    },     
    tableCol30Header: { 
        width: COL30_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
    },     
    tableCol20Header: { 
        width: COL20_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
    },     
    tableColHeader: { 
        width: COLN_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
    },   
    tableCol1: { 
        width: COL1_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },   
    tableCol4: { 
        width: COL4_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },   
    tableCol: { 
        width: COLN_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCol60: { 
        width: COL60_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCol50: { 
        width: COL50_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCol40: { 
        width: COL40_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCol30: { 
        width: COL30_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCol20: { 
        width: COL20_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCellHeader: {
        margin: 5, 
        fontSize: 12,
        fontWeight: 600
    },  
    tableCell: { 
        margin: 5, 
        fontSize: 12,
        height: 30 + "px",
        maxLines: 3
    },
    textBox: {
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        padding: 10,
        marginTop: 30,
        marginBottom: 30,
        height: 5 + "cm"
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    viewImg: {
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },
    imageCover: {
        objectFit: 'cover',
    },
});

export function PdfDocument(props) {

    return (
        <Document>
            <Page object-fit="fill" style={styles.pageNoPadding} size="A4">
                <View style={styles.viewImg}>
                    <Image style={styles.imageCover} src={genialniZvezekNaslovnica} alt="naslovnica" />
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.text}>Najuspešnejši posamezniki so navdihujoči voditelji, pametni strategi in kreativni misleci. V vseh poslovnih funkcijah je kreativnost ključna pri reševanju zapletenih problemov, razvoju novih strategij, omogočanju inovacij in spodbujanju sprememb v organizaciji.</Text>
                <Text style={styles.text}>Trening genialnosti je nastal na podlagi dolgoletnega preučevanja življenja in dela največjih genijev človeštva kot so Leonardo da Vinci, Nikola Tesla, Steve Jobs, Hedy Lammar, idr.. V trening so vključeni tudi najpomembnejši »know how« prijemi uspešnih Slovencev, kot so Ivo Boscarol, Peter Florjančič, Robert Lešnik, Lara Bohinc, Katarina Venturini, Dejan Zavec in drugi.</Text>
                <Text style={styles.text}>Program je osredotočen na to, kako ustvariti konkurenčno prednost s spodbujanjem ustvarjalne kulture, ki v sebi združuje navdih, sodelovanje in inovacije. Z implementacijo treninga genialnosti se boste naučili, kako z večjo kreativnostjo in inovativnostjo pristopiti k zapletenim poslovnim težavam.</Text>
                <Text style={styles.subtitle}>IZZIVI</Text>
                <Text style={styles.text}>Vse v vesolju je v gibanju. Zemlja, sonce, planeti, naravni sateliti. Vsa narava je v gibanju. Izmenjujejo se letni časi. Rojevajo se živali in vzklijejo rastline. Vsako gibanje ima smisel. In ves smisel je v gibanju. V človekovo podzavest je torej vgrajen naravni mehanizem gibanja; svojevrsten kompas, ki nas vodi skozi življenje. Za razliko od vseh živalskih vrst in vsega živega sveta, ki imajo svojo gotovo vnaprej zaokroženo in določeno naravo, ima na drugi strani človek svobodo, da sam iz sebe naredi tisto kar si želi biti. Človek je tisti, ki oblikuje in ustvarja samega sebe.</Text>
                <Text style={styles.text}>Spremembe so torej povsem naravne in neizbežne. Vsako stanje, ki se nam zdi stagnacija ni mirovanje, ampak je v resnici nazadovanje. K spremembam nas vodijo vizije, ki nas privlačijo in obljubljajo nagrade po koncu prehojene poti. V tem smislu preobrazba ne pomeni postati nekaj novega, ampak odvreči tisto kar je zastarelo. Vrniti se v svojo bistvo ter poiskati nov ustvarjalni naboj in navdih, da gremo v življenju naprej.</Text>
                <Text style={styles.text}>Po psihologu C.G. Jungu ima vsak človek v sebi arhetip homo-kreatorja. To pomeni, da cilj našega življenja ni trpljenje, ampak ciljana ustvarjalnost z namenom reševanja problemov, zdravljenja bolečine in ustvarjanja tehnologij, ki nam olajšujejo vsakdanje življenje. Kje se tega najbolje učiti, če ne od genialnih ustvarjalcev, ki so izumili svet v katerem danes živimo.</Text>
                <Text style={styles.subtitle}>CILJI</Text>
                <Text style={styles.text}>V okviru treninga boste šli skozi tri temeljne faze sprememb: 1. rentgen ali samospoznanje, 2. radio frekvence ali način delovanja, 3. tele-avtomatika ali akcija. Vsaka faza vsebuje praktične primere, orodja in tehnike, ki so jih uporabljali uspešni ustvarjalci. Iste boste v okviru treninga preizkusili in se jih naučili uporabljati tudi vi.</Text>
                <Text style={styles.text}>Cilj je opolnomočiti vas, da prepoznate in prevzamete kontrolo nad vsemi svojimi potenciali in jih povzdignete na nivo genialnosti. Naučili se boste reševati poslovne probleme in sprejemajte boljše odločitve s kreativnim razmišljanjem. Pridobili boste praktična orodja za vključevanje kreativnega razmišljanja v vsakodnevne procese. Naučili se boste kako izvajati in upravljati ustvarjalni postopek na vseh področjih vašega življenja.</Text>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>1. KATERI SO MOJI POTENCIALI?</Text>
                <Text style={styles.text}>Ne obstaja človek na svetu, ki ne bi imel prav nobenega talenta. Vsakdo se rodi z določenimi darovi oz. dragocenostmi, ki so svojstvene samo njemu in drugačne kot pri nekom drugem. Enako kot skozi genski zapis podedujemo naš fizični izgled in karakterne lastnosti, na isti način od svojih prednikov prejmemo talente. Gre za specifično sposobnost, ki imetniku omogoča, da doseže maksimalne rezultate na določenem življenjskem in poklicnem področju.</Text>
                <Text style={styles.text}>Pomislite kaj najraje počnete oz. kaj vas v vsakdanjem življenju osrečuje? Preučite odnose s svojimi bližnjimi, prijatelji in sodelavci. Pomislite ali vas je kdo izmed njih v čem določenem kadarkoli pohvalil? Ali vas prosijo za kakšno specifično pomoč pri aktivnostih, katerih sami niso dovolj vešči? V vaših očeh je morda samoumevna, čeprav gre v resnici za talent. Pomislite ali obstaja kakšna aktivnost ob kateri povsem pozabite na čas? Toliko ste ji posvečeni, da ne uspete postoriti številnih drugih obveznosti? Zaradi nje ostajate budni dolgo v noč? Morda ste pravkar odkrili svoj talent. Ali obstaja kakšna tematika, ki je za vas pomembnejša od vseh drugih? O kateri se lahko pogovarjate, čeprav ste utrujeni? Za vas ostaja sveža in aktualna kljub temu, da ste o njej svojim prijateljem razlagali že neštetokrat?</Text>
                <Text style={styles.text}>Pomislite kako preživljate prosti čas. Kakšno vrsto televizijskih ali radijskih oddaj radi spremljate? Ali obstajajo kakšne tematske revije, ki jih strastno prebirate? Ali komaj čakate izid naslednje številke? Izostrite čute in poskusite najti usklajenost med vašo osebnostjo, sposobnostmi in občudovano vsebino. Na obzorju je vaš talent.</Text>
                <Text style={styles.text}>Pomislite v čem ste dobri. Kaj je za vas zlahka dosegljivo medtem ko imajo ostali pri tem težave? Kaj je za vas preprosto medtem, ko se zdi drugim zapleteno? Ali se počutite izpolnjeno, ko prisluhnete osebam v vaši okolici? Jim z veseljem v čemerkoli pomagate? Ali dobronamerno popravljate njihove napake? V tem primeru očitno razpolagate z določenimi znanji, sposobnostmi in veščinami, ki so v funkciji določenega talenta. Poizvedite v čem ste dobri iz zornega kota okolice. Povprašajte za mnenje o vaših mogočih talentih pri vaših bližnjih, prijateljih, sodelavcih in znancih. Kako vas vidijo? Naredite svojevrstne intervjuje ena na ena, iz oči v oči. Vaše slabosti naj tokrat zadržijo zase, povejo naj vam zgolj vaše dobre lastnosti. Največ dve, ki sta najbolj prepoznavni. Naj bodo v svoji oceni realni in iskreni.</Text>
                <Text style={styles.text}>Pogovorite se s starši in starimi starši. Mogoče imajo oni skrite talente, katerih se sicer zavedajo, a jim ne pripisujejo kakšnega posebnega pomena. Vprašajte jih o njihovem pogledu na življenje. Kaj so radi počeli, v čem so bili dobri, o čem so sanjali? Poskusite izvedeti zakaj svojih talentov niso uresničili?</Text>
                <Text style={styles.text}>Vrnite se v preteklost in še enkrat sedite v šolske klopi. Pomislite za katere predmete v osnovni in srednji šoli ste kazali izrazit interes. Kateri so tisti predmeti, ki so vas navduševali bolj kot drugi? Katerih predmetov ste se vedno znova veselili? Poskusite opredeliti področje, h kateremu je vaša osebnost nagnjena bolj kot k drugim. Ali ste ljubitelj družboslovja in humanističnih ved? Mogoče kakšnega specifičnega področja? Vas bolj zanima naravoslovje in fizikalni zakoni? Mogoče ste bili izrazito likovno, glasbeno ali športno nadarjeni. So vas učitelji kadarkoli pohvalili zaradi vaše idejnosti, zanimivih zaključkov ali dobrega razmišljanja? Poskusite se spomniti kaj so vam rekli?</Text>
                <Text style={styles.text}>Prepoznajte svoje talente s pomočjo prijetnih slik iz otroštva. Znova se sprehodite po šolskih hodnikih, igriščih, otroških sobah in parkih. Spomnite se najlepših dogodkov, ki imajo posebno mesto v vašem srcu. Spomnite se pozitivnih oseb, ki so soustvarjale vaše dogodivščine. Skratka bodite otroci in sledite magičnim trenutkom sreče, v katerih ste bili nasmejani, energični in zadovoljni.</Text>
                <Text style={styles.text}>Prepoznajte svoje talente s pomočjo prijetnih slik iz otroštva. Znova se sprehodite po šolskih hodnikih, igriščih, otroških sobah in parkih. Spomnite se najlepših dogodkov, ki imajo posebno mesto v vašem srcu. Spomnite se pozitivnih oseb, ki so soustvarjale vaše dogodivščine. Skratka bodite otroci in sledite magičnim trenutkom sreče, v katerih ste bili nasmejani, energični in zadovoljni.</Text>
                <Text style={styles.subtitle}>1. NALOGA</Text>
                <Text style={styles.subsubtitle}>1.1 Zapišite vse svoje darove, potenciale, talente.</Text>
                <Text style={styles.subsubtitle}>1.2 Z oceno od 1 do 10 ocenite stopnjo razvitosti vašega talenta/talentov. Poskusite biti objektivni.</Text>
                <Text style={styles.subsubtitle}>1.3 Ocenite intenzivnost uporabe in zapišite ali talent uporabljate: zmerno, občasno, pogosto ali vedno.</Text>
                <Text style={styles.subsubtitle}>1.4 V tabeli označite katere talente aktivno uporabljate ali pa vam občasno pridejo prav pri vašem delu.</Text>
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4Header}> 
                            <Text style={styles.tableCellHeader}>1. TALENTI, DAROVI, POTENCIALI</Text> 
                        </View> 
                        <View style={styles.tableCol4Header}> 
                            <Text style={styles.tableCellHeader}>2. STOPNJA RAZVITOSTI</Text> 
                        </View> 
                        <View style={styles.tableCol4Header}> 
                            <Text style={styles.tableCellHeader}>3. INTENZIVNOST UPORABE</Text> 
                        </View> 
                        <View style={styles.tableCol4Header}> 
                            <Text style={styles.tableCellHeader}>4. KATERE UPORABLJATE PRI SVOJEM DELU</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.a1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.a2}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.a3}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.a4 && "X"}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.b1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.b2}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.b3}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.b4 && "X"}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.c1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.c2}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.c3}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.c4 && "X"}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.d1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.d2}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.d3}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.d4 && "X"}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.e1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.e2}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.e3}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.e4 && "X"}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.f1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.f2}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.f3}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.f4 && "X"}</Text> 
                        </View> 
                    </View>    
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>2. KATERE SO MOJE VREDNOTE</Text>
                <Text style={styles.text}>Kaj je za vas najpomembnejše? Vaše temeljne vrednote določajo, kako odgovarjate na to vprašanje. Naše temeljne vrednote poudarjajo, za kaj se zavzemamo. Temeljne vrednote usmerjajo naše vedenje, odločitve in ukrepe.</Text>
                <Text style={styles.text}>Ko veste, kaj cenite, lahko živite v skladu s temi vrednotami. To vodi k večjem zadovoljstvu. Ko ne poznate svojih vrednot, jih vsak dan kršite. To ustvarja notranjo napetost. In ta napetost sproža destruktivne navade in regresivno vedenje. Da bi se temu izognili, se moramo resnično potruditi, da ugotovimo, katere so naše vrednote.</Text>
                <Text style={styles.subsubtitle}>2.1 NALOGA: Obkrožite 5 vrednot, ki vam pomenijo največ.</Text>
                <View style={{
                    fontSize: 10,
                    width: 550,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    }}>
                    {
                        naloga2_1_vrednote.map((item) => 
                            <Text key={uuid_v4()} style={[{flexBasis: 150, marginBottom: 1, padding: 1}, (props.data.naloga02_1.includes(item) && styles.active)]}>{item}</Text>   
                        )}
                        {/* <View style={styles.row}>
                            <Text style={[styles.col, {props.data.naloga02_1.includes(naloga2_1_vrednote[0]) && styles.active}]}>{naloga2_1_vrednote[0]}</Text>
                            <Text style={[styles.col, {props.data.naloga02_1.includes(naloga2_1_vrednote[0]) && styles.active}]}>{naloga2_1_vrednote[1]}</Text>
                            <Text style={[styles.col, {props.data.naloga02_1.includes(naloga2_1_vrednote[0]) && styles.active}]}>{naloga2_1_vrednote[2]}</Text>
                        </View> */}
                    
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.text}>Ugotovili boste, da ima vsak posameznik njemu lasten in edinstven urejen sistem vrednot. Vsako njihovo primerjanje in tehtanje katere so boljše nima smisla, saj so globoko zapisane v osebnosti vsakega posameznika in kot takšne predstavljajo njegovo individualno najvišje dobro.</Text>
                <Text style={styles.text}>Predstavljajte si kakšen mora biti sistem vrednost znotraj organizacije, v kateri je zaposlenih nekaj deset, sto ali tisoč ljudi. Cilj organizacije je poiskati skupni imenovalec in oblikovati takšen sistem vrednot na makro nivoju, s katerim se boste zaposleni lahko identificirali. Torej vrednote podjetja niso mrtva črka na papirju ali marketinški trik, ampak osnovna frekvenca ustvarjanja.</Text>
                <Text style={styles.text}>V tem smislu lahko organizacija spodbuja svoje zaposlene k temu, da v kar največji meri uresničujejo svoje notranje težnje, lahko pa je osredotočena predvsem na visoke dosežke na področjih, ki jih določa sama ali so določena od zunaj.</Text>
                <Text style={styles.subsubtitle}>2.2 NALOGA: </Text>
                <Text style={styles.subsubtitle}>Spomnite se trenutka, ko ste bili v službi srečni, zadovoljni in ponosni nase? Vzemite si čas in opišite navedeno situacijo.</Text>
                <Text style={styles.subsubtitle}>A) Identificirajte vzroke, ki so pripeljali do zadovoljstva.</Text>
                <Text style={styles.subsubtitle}>B) Katere vaše vrednote so se tedaj potrdile.</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga02_2}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.subsubtitle}>2.3 NALOGA: </Text>
                <Text style={styles.subsubtitle}>Zdaj opišite trenutek, ko ste se v službi počutili najbolj razočarani. Opišite detajle te situacije;</Text>
                <Text style={styles.subsubtitle}>A) Kaj konkretno je pripeljalo do občutka obžalovanja in žalosti?</Text>
                <Text style={styles.subsubtitle}>B) Razmislite in zapišite zakaj?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga02_3}</Text>
                </View>
                <Text style={styles.subsubtitle}>2.4 NALOGA: </Text>
                <Text style={styles.subsubtitle}>Zapišite kdo so vaši vzorniki?</Text>
                <Text style={styles.subsubtitle}>A) Zakaj?</Text>
                <Text style={styles.subsubtitle}>B) Kakšne so njihove kvalitete? Katere vrednote posedujejo?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga02_4}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>3. KAJ JE MOJE SPOROČILO?</Text>
                <Text style={styles.text}>Gre za najpomembnejše sporočilo na svetu, ki ga je nedvomno genialni Mahatma Gandhi definiral takole: »Tvoje življenje je tvoje sporočilo.« Od vzpostavitve in aktivnosti v duhu vašega sporočila je odvisno ali bo vaše življenje navdih vašim otrokom, prijateljem in naključnim ljudem v okolici ali opozorilo.</Text>
                <Text style={styles.text}>Da bi prepoznali svoje sporočilo je potrebno, da pogledate sami sebe v oči in si odgovorite na vprašanje: Kaj je tvoje sporočilo za katerega si želiš, da ga svet sliši? Kakšen odtis želiš pustiti na svetu? Gre za sporočilo, ki ga hranite globoko v sebi. Sporočilo, ki lahko spremeni življenje na boljše 10, 100 ali tisočim ljudem.</Text>
                <Text style={styles.subsubtitle}>3. NALOGA: </Text>
                <Text style={styles.subsubtitle}>O čem sanjate? Kaj želiš sporočiti svetu? Kaj vas navdihuje, da greste naprej? Ali kaj bi želeli, da vas navdihuje pa si niste upali o tem nikoli spregovoriti? Strnite sporočilo v enem ali največ dveh stavkih.</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga03_1}</Text>
                </View>
                <View style={styles.bulletList}>
                    <Text style={styles.textListHead}>Ko zaživite svoje SPOROČILO boste bolj:</Text>
                    <Text style={styles.textList}>•	Motivirani (za učenje, nove tehnologije, pomagati podjetju, sodelavcem, znancem)</Text>
                    <Text style={styles.textList}>•	Komunikativni (z vsemi skupinami s ciljem doseganja zastavljenih ciljev v službi in privatnem življenju, predstavili boste svoje ideje, prisluhnili drugim)</Text>
                    <Text style={styles.textList}>•	Sočutni (videli boste potrebe drugega: sodelavca, partnerja, otroka, znanca in neznanca)</Text>
                    <Text style={styles.textList}>•	Učinkoviti (ni blodenja po stranpoteh in če se te začnejo se hitro vrnemo na začrtano pot)</Text>
                    <Text style={styles.textList}>•	Zadovoljni (delaš za dobro sebe in drugih, za dobro lokalnega in globalnega okolja)</Text>
                    <Text style={styles.textList}>•	Mirni (ne ukvarjaš se z nepomembnimi stvarmi, spočitost)</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>4. KAJ JE MOJ MOTO?</Text>
                <Text style={styles.text}>Zakaj je tako enostavno pozabiti svoje plemenite cilje – ustvarjati, telovaditi, zdravo jesti, meditirati, učiti se? Spopadanje z neposrednimi težavami večkrat povzroči v nas tolikšen stres, da enostavno odpovemo in ne najdemo motivacije za realizacijo nam še tako ljubih aktivnosti.</Text>
                <Text style={styles.text}>Kaj lahko naredimo? Imejmo moto, ki ima nenavadno moč, ki hipno deluje. V trenutku nas lahko poveže z našimi najglobljimi vrednotami in cilji.</Text>
                <Text style={styles.text}>Po definiciji je moto kratka izjava, ki se uporablja za izražanje načela, cilja ali ideje" ali izjava, "sprejeta kot vodnik za vedenje." Zapletene ideje se izgubijo v prostoru medtem, ko se s preprostim motom lahko v hipu spomnite, kaj je za vas resnično pomembno. Izgovorite ga nekajkrat - občutili boste mir in dobili motivacijo za življenje svojega sporočila.</Text>
                <View style={styles.bulletList}>
                    <Text style={styles.textListHead}>Zakaj imeti MOTO?</Text>
                    <Text style={styles.textList}>•	Destruktivno razmišljanje spremeni v zdrav pogovor s samim seboj.</Text>
                    <Text style={styles.textList}>•	Slabo navado spremeni v dobro.</Text>
                    <Text style={styles.textList}>•	Opomni na tisto kar je pomembno.</Text>
                    <Text style={styles.textList}>•	Pomirja.</Text>
                    <Text style={styles.textList}>•	Navdihuje.</Text>
                    <Text style={styles.textList}>•	Poveča produktivnost.</Text>
                    <Text style={styles.textList}>•	Poveča vztrajnost.</Text>
                </View>
                <Text style={styles.subsubtitle}>4. NALOGA: </Text>
                <Text style={styles.subsubtitle}>Poiščite in zapišite svoj moto. Stavek, ki vam vlije upanje in navda s pogumom. Ki vas drži pokonci in spodbuja, da vztrajate.</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga04_1}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>5. OSEBNA KVANTNA STRUKTURA</Text>
                <Text style={styles.text}>Da bi posameznik lahko v maksimalni meri dosegel svoje cilje in vplival na svet okoli sebe mora najprej poznati sebe. V tem primeru lahko načrtno in usmerjeno deluje tam, kjer tudi potencialno lahko da največji doprinos. Tako se ne bo lotil področij, kjer objektivno nima možnosti in kjer bi po nepotrebnem zgubljal dragoceno energijo, zdravje in čas.</Text>
                <Text style={styles.text}>Človek lahko zelo dobro razume ali pač misli, da razume svet okoli sebe oz. ima lahko skladiščenega v svojem umu ogromno intelektualnega materiala, ki pa je zaradi nerazumevanja samega sebe neuporaben. Kajti prav od poznavanja samega sebe je odvisno v kolikšni meri bo uresničil vse svoje potenciale.</Text>
            </Page>
            <Page orientation="landscape" style={styles.page}>
                <Text style={styles.title}>KVANTNA STRUKTURA STEVE JOBSA</Text>
                <Image style={{width: "623", height: "388", marginLeft: 40}} src={osebnaKvantnaStrukturaSteveJobs} alt="Osebna kvantna struktura Steve Jobsa" />
            </Page>
            <Page orientation="landscape" style={styles.page}>
                <Text style={styles.title}>5.1 NALOGA: LASTNA KVANTNA STRUKTURA</Text>
                
                {/* <Image style={{width: "608", height: "430", marginLeft: 40}} src={osebnaKvantnaStrukturaImg} alt="Lastna kvantna struktura" /> */}
            </Page>
            <Page style={styles.page}>
                <Text style={styles.subsubtitle}>5.2 Prikličite v misli svoje rojstno mestno. Kje se najrajši zadržujete? Kje vam je najprijetnejše? Kakšni so vaši občutki?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga05_2}</Text>
                </View>

                <Text style={styles.subsubtitle}>5.3 Spomnite se trenutka, ko ste spoznali vašega partnerja/partnerico. Kaj je takrat na vas naredilo največji vtis? Kaj bi izpostavili kot njegove/njene najboljše lastnosti?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga05_3}</Text>
                </View>

                <Text style={styles.subsubtitle}>5.4 Spomnite se svojih otrok. Njihovih obrazov, obrazne mimike in gibov. Kateri trenutki so vam se najbolj vtisnili v spomin? Zakaj?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga05_4}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.subsubtitle}>5.5 Spomnite se svojega največjega uspeha.</Text>
                <Text style={styles.subsubtitle}>A) Kaj je to bilo? Opišite svoje občutke.</Text>
                <Text style={styles.subsubtitle}>B) Ste se še kdaj v življenju podoživeli takšne občutke? Če ste, kdaj?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga05_5}</Text>
                </View>

                <Text style={styles.subsubtitle}>5.6 Opišite svoje občutke, ko pomislite na uresničitev svojih življenjskih ciljev.</Text>
                <Text style={styles.subsubtitle}>Kaj boste takrat naredili?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga05_6}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>6. ŠEST KVANTNIH VLOG</Text>
                <Text style={styles.text}>Vsaka oseba ima v življenju številne vloge, ki se medsebojno dopolnjujejo in nadgrajujejo. Bolj, ko boste dejavni v eni vlogi večje zadovoljstvo in radost boste v tej vlogi čutili. Posledično se iz uspeha v eni vlogi porodi motivacija za uspeh v katerikoli drugi. Cilj je doseči ravnotežje med vsemi vlogami, kar je edino možno z nenehno aktivnostjo v vseh. Mogoče boste zaradi tega včasih utrujeni, a občutek zadovoljstva in radosti bo veliko močnejši.</Text>
                <Text style={styles.text}>Vse vloge bodo podpirale in prispevale dodano vrednost k vaši glavni vlogi oz. »kdo sem«. Če je bil prej vaš pristop k vašemu poklicu oz. vlogi »kdo sem« linearen, bo od sedaj naprej komplementaren, holističen in celovit. Na vašo poklicno vlogo boste nezavedno in zavedno delovali iz vseh možnih področij: družbenega, sociološkega, psihološkega (čustva in razum) in filozofskega. Tako se boste postopoma razvijali v genialnega ustvarjalca in dosegli obzorja o katerih niste niti pomislili. S časoma boste odkrili tudi druge vloge, ki jih (za zdaj) še ne poznate.</Text>
                <View style={styles.bulletList}>
                    <Text style={styles.textListHead}>Osnovno gorivo kvantnih vlog so:</Text>
                    <Text style={styles.textList}>•	naši talenti, ki predstavljajo temelj okoli katerega so stkani vsi naši realno dosegljivi cilji;</Text>
                    <Text style={styles.textList}>•	vrednote, v okviru katerih delujemo</Text>
                    <Text style={styles.textList}>•	komunikacija z vsemi akterji, ki v vlogah nastopajo</Text>
                </View>
                <Text style={styles.subsubtitle}>6.1 Ocenite kako dobri ste trenutno v vsaki vlogi. Poskusite biti čim bolj objektivni.</Text>
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol60Header}> 
                            <Text style={styles.tableCellHeader}>ŽIVLJENJSKA VLOGA</Text> 
                        </View> 
                        <View style={styles.tableCol40Header}> 
                            <Text style={styles.tableCellHeader}>VAŠA OCENA (1 - 10)</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.a1}</Text> 
                        </View> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.a2}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.b1}</Text> 
                        </View> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.b2}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.c1}</Text> 
                        </View> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.c2}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.d1}</Text> 
                        </View> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.d2}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.e1}</Text> 
                        </View> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.e2}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.f1}</Text> 
                        </View> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>{props.data.naloga06_1.f2}</Text> 
                        </View> 
                    </View>    
                </View>
            </Page>
            <Page orientation="landscape" style={styles.page} >
                <Text style={styles.title}>6.2 KVANTNA STRUKTURA ZAPOSLENEGA</Text>
            </Page>
            <Page style={styles.page} >
                <View style={styles.bulletList}>
                    <Text style={styles.textListHead}>Če poznate 40 % ali manj osebnosti zaposlenega posledično pomeni, da zaposleni:</Text>
                    <Text style={styles.textList}>•	V svoje delo ne vključujejo in ne koristijo vseh svojih potencialov</Text>
                    <Text style={styles.textList}>•	Niso medsebojno povezani v meri kot bi lahko bili</Text>
                    <Text style={styles.textList}>•	Ne dosegajo maksimalnih rezultatov</Text>
                </View>
                <Text style={styles.text}>Ker ste zagotovo izbrali zaposlenega, ki ga najbolj poznate pomeni, da preostale člane tima poznate še manj. Namreč kjer ni komunikacije ne more biti zadostnega timskega duha izraženega v medsebojni povezanosti, požrtvovalnosti za drugega, izmenjevanju idej, zagnanosti in sočutju kot temeljem za doseganje izrednih rezultatov.</Text>
                <Text style={styles.text}>Ker ste zagotovo izbrali zaposlenega, ki ga najbolj poznate pomeni, da preostale člane tima poznate še manj. Namreč kjer ni komunikacije ne more biti zadostnega timskega duha izraženega v medsebojni povezanosti, požrtvovalnosti za drugega, izmenjevanju idej, zagnanosti in sočutju kot temeljem za doseganje izrednih rezultatov.</Text>
                <Text style={styles.subsubtitle}>6.3 NALOGA:</Text>
                <Text style={styles.subsubtitle}>Razmislite kako lahko izboljšate komunikacijo s to osebo, da bi jo bolje spoznali in dopolnili njeno kvantno strukturo?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga06_3}</Text>
                </View>
            </Page>
            <Page style={styles.page} >
                <Text style={styles.subsubtitle}>6.4 NALOGA:</Text>
                <Text style={styles.subsubtitle}>Izdelajte kvantno strukturo vsakega zaposlenega v svojem timu. Katere pristope pri tem uporabiti upoštevajoč, da so si ljudje med seboj različni? V vsakem primeru pa mora biti čas preživet z njimi prijeten.</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga06_4}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>7. SPODBUJAJ RADOVEDNOST</Text>
                <Text style={styles.text}>Radost ni minljivo stanje, ki se pojavlja v odvisnosti od življenjskega obdobja in neodvisno od človekove volje. Radost je večna mladost duha, premo sorazmerna je stopnji zainteresiranosti posameznika zase in za svet okoli sebe. Bolj ko izražamo radovednost, večji je občutek radosti in obratno. Če imate možnost, se ob prvi priložnosti pogovorite s katerimkoli govorečim otrokom. Začetno sramežljivost malega sogovornika bo hitro nadomestila odkritost, ki bo tlakovana s številnimi vprašanji izzivala izbruh radosti na otrokovem obrazu. Toda otrok ne postavlja vprašanj iz dolgčasa ali želje, da bi preizkušal vašo potrpežljivost, kot se večkrat popolnoma napačno misli. Želi pridobiti čim več informacij, na podlagi katerih si oblikuje sliko sveta, ki ga obdaja. Tako se otrokova slika sveta nenehno spreminja in dopolnjuje, s čimer se ustvarjajo pogoji za napredek na vseh področjih: telesnem, duševnem, duhovnem in družbenem. Nasprotno pa je večina odraslih izgubila sposobnost postavljanja vprašanj in tako omejila možnosti za razvoj svojega jaza. Danes odrasli ne postavljajo vprašanj. Tudi v najbolj potrebnih ali kritičnih okoliščinah ne.</Text>
                <Text style={styles.subsubtitle}>7.1 NALOGA:</Text>
                <Text style={styles.subsubtitle}>Zapišite misli oz. vprašanja o katerih že dalj časa razmišljate? Mnoga vprašanja se bodo ponavljala ali podvajala. To pomeni, da so tam večja zanimanja, problemi, interesi in da jih je treba resno preučiti. Zapišite pet najpomembnejših in jih skladno s prioritetami ovrednotite z oceno od 1 od 5 (1 kot najpomembnejše).</Text>
                <View style={styles.container}>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>1.</Text>
                        <Text style={styles.flexItem}>{props.data.naloga07_1.a1}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>2.</Text>
                        <Text style={styles.flexItem}>{props.data.naloga07_1.a2}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>3.</Text>
                        <Text style={styles.flexItem}>{props.data.naloga07_1.a3}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>4.</Text>
                        <Text style={styles.flexItem}>{props.data.naloga07_1.a4}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>5.</Text>
                        <Text style={styles.flexItem}>{props.data.naloga07_1.a5}</Text>
                    </View>
                </View>
            </Page>
            <Page style={styles.page} >
                <Text style={styles.subsubtitle}>7.2 NALOGA:</Text>
                <Text style={styles.subsubtitle}>Poskusite odgovoriti na vprašanje oz. ugotoviti: »Zakaj o tem razmišljam«?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga07_2}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>8. PREVZEMITE ODGOVORNOST</Text>
                <Text style={styles.text}>Vsak izmed nas je popolnoma odgovoren za svoje življenje. To je temeljno načelo, ki ga morate sprejeti, če želite biti uspešni v svojem življenju in delu. Za mnoge ljudi je kriv nekdo drug, ker je tako pač lažje. Vsak problem je mogoče razložiti z razlogi, zakaj ne moremo vplivati na situacijo ali rezultat. Zlasti v službi, kjer je enostavno najti izgovore, ker so ljudje tako tesno prepleteni - in vsak projekt ima verigo notranjih strank, ki so odvisne od drugih.</Text>
                <Text style={styles.text}>Toda brez prevzemanja odgovornosti za svoje postopke in dejanja ni moč doseči napredka. Kajti vse v življenju je pravzaprav povezano z dajanjem odgovorov. Odgovori na posamezne probleme, izzive in preizkušnje vodijo v napredek in zadovoljstvo. Odgovornost je direktno povezana s svobodno voljo, ki nam daje moč odločanja. Če se ne bomo odločali sami, bodo o nas odločali drugi.</Text>
                <View style={styles.bulletList}>
                    <Text style={styles.textListHead}>Kako do vzpostavitve večje odgovornosti:</Text>
                    <Text style={styles.textList}>•	Prevzemite odgovornost za svoje misli, občutke, besede in dejanja</Text>
                    <Text style={styles.textList}>•	Prenehajte obsojati</Text>
                    <Text style={styles.textList}>•	Prenehajte jamrati</Text>
                    <Text style={styles.textList}>•	Ne jemljite stvari osebno</Text>
                    <Text style={styles.textList}>•	Živite v tem trenutku</Text>
                    <Text style={styles.textList}>•	Uporabite moč namena</Text>
                    <Text style={styles.textList}>•	Iščite v ljudeh dobro</Text>
                </View>
            </Page>
            <Page style={styles.page} >
                <Text style={styles.subsubtitle}>8.1 NALOGA:</Text>
                <Text style={styles.subsubtitle}>Kako oz. na kakšen način lahko vsako izmed prvih 5 vprašanj, ki se pojavljajo razrešim?</Text>
                
                <Text style={[styles.subtitle, {textAlign: "center", marginTop: 30, marginBottom: 1}]}>... KAKO LAHKO</Text>
                
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol50Header}> 
                            <Text style={styles.tableCellHeader}>VPRAŠANJE / PODROČJE</Text> 
                        </View> 
                        <View style={styles.tableCol50Header}> 
                            <Text style={styles.tableCellHeader}>RAZREŠIM?</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.a1}</Text> 
                        </View> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.a2}</Text> 
                        </View> 
                    </View>      
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.b1}</Text> 
                        </View> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.b2}</Text> 
                        </View> 
                    </View>      
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.c1}</Text> 
                        </View> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.c2}</Text> 
                        </View> 
                    </View>      
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.d1}</Text> 
                        </View> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.d2}</Text> 
                        </View> 
                    </View>      
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.e1}</Text> 
                        </View> 
                        <View style={styles.tableCol50}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_1.e2}</Text> 
                        </View> 
                    </View>           
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.subsubtitle}>8.2 NALOGA:</Text>
                <Text style={styles.subsubtitle}>Preučite vsako vlogo in zapišite kaj lahko naredite, da bi bili v njej še boljši.</Text>
                <Text style={styles.subsubtitle}>Tako boste ozavestili svoj napredek in si hkrati tudi zadali bodoče cilje, ki jih boste dosegli. Napredek, ki ga želite uresničiti naj bo objektivno dosegljiv. Zadajte si napredek, ki ga lahko uresničite v 1 mesecu.</Text>
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>POKLIC</Text> 
                        </View> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_2.a1}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>PARTNERSTVO</Text> 
                        </View> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_2.b1}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>STARŠEVSTVO</Text> 
                        </View> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_2.c1}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>PRIJATELJSTVO</Text> 
                        </View> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_2.d1}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>OSEBNI RAZVOJ</Text> 
                        </View> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_2.e1}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol40}> 
                            <Text style={styles.tableCell}>DRUŽBENA VLOGA</Text> 
                        </View> 
                        <View style={styles.tableCol60}> 
                            <Text style={styles.tableCell}>{props.data.naloga08_2.f1}</Text> 
                        </View> 
                    </View>    
                        
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>9. USTVARJALNI IMAGINARIUM</Text>
                <Text style={styles.text}>Človekov biološko vgrajeni mehanizem za prepoznavanje nevarnosti nas ob srečanju s potencialnim problemom zaradi obrambnega mehanizma strahu, večkrat paralizira in postavi v stanje nemoči. To se zgodni vsakič, ko se srečamo s situacijo, ki je nekoliko drugačna od situacij s katerimi imamo običajno opravka. Nova situacija zahteva nov pristop, zaradi česar naši možgani hitro pridejo v stisko. Posledično se na problem osredotočimo tako zelo, da izgubimo širšo sliko in zaradi tega ne najdemo hitre in učinkovite rešitve.</Text>
                <Text style={styles.text}>Da bi lažje razumeli vzemimo za primer liste na drevesu, ki se je iz neznanega razloga začelo sušiti. Želimo mu pomagati. Toda če se bomo ukvarjali zgolj z zdravljenjem lista mu ne bomo pomagali. Za celovito – holistično rešitev moramo preučiti tudi stanje debla in krošnje. Preveriti v kakšnem stanju so korenine. Ali ima dovolj svetlobe in kakšna je kakovost prti.</Text>
                <Text style={styles.text}>Po isti analogiji je potrebno pristopiti reševanju vsakega problema, poslovnega ali osebnega. Kajti izvorni problemi in rešitve so običajno izven našega vidnega polja.</Text>
                <Text style={styles.subsubtitle}>9. NALOGA:</Text>
                <Text style={styles.subsubtitle}>9.1 Kateri je prvi korak, ki ga boste naredili?</Text>
                <Text style={styles.subsubtitle}>9.2 Katera znanja rabite?</Text>
                <Text style={styles.subsubtitle}>9.3 Katere ljudi boste kontaktirali? Zakaj?</Text>
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>POKLIC</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.a1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.a2}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.a3}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>PARTNERSTVO</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.b1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.b2}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.b3}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>STARŠEVSTVO</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.c1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.c2}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.c3}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>PRIJATELJSTVO</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.d1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.d2}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.d3}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>OSEBNI RAZVOJ</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.e1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.e2}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.e3}</Text> 
                        </View> 
                    </View>    
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>DRUŽBENA VLOGA</Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.f1}</Text> 
                        </View> 
                        <View style={styles.tableCol4}>
                            <Text style={styles.tableCell}>{props.data.naloga01_1.f2}</Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}>{props.data.naloga01_1.f3}</Text> 
                        </View> 
                    </View>    
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>10. UPORABA MENTALNIH ORODIJ</Text>
                <Text style={styles.text}>Vsa mentalna orodja delujejo skozi naša čutila, s katerimi izkustveno preverjamo in hkrati oblikujemo svojo sliko sveta. V biološkem smislu čutila soustvarjajo čutilni sistem, ki tvori niz čutilnih receptorjev, zadolženih za prejemanje različnih dražljajev. Receptorji vsebujejo senzorične nevrone, ki zbirajo informacije o stanju našega telesa in okolice. Prejete informacije se nato prek živčnega sistema prenašajo do organov, ki se neposredno odzovejo na prejete informacije.</Text>
                <Text style={styles.text}>Kako kakovosten bo naš odziv, je odvisno od stopnje ozaveščenosti čutov. Bolj ko so naši čuti ozaveščeni, večje so možnosti za pridobivanje novih znanj in veščin, osebno rast in oblikovanje pravilnih odločitev v danih okoliščinah.</Text>
                <Text style={styles.text}>Glavna ovira do ozaveščanja čutov je kombinacija hitrega načina življenja z nepredvidenimi šumi iz okolja. Telefoni, računalniki, televizija in oglasni panoji na vsakem koraku ustvarjajo hrup, ki jemlje prostor in energijo za intenzivnejšo uporabo naših čutov. Možgani sodobnega človeka vsakodnevno prejmejo tako zelo veliko količino informacij, ki jo niso zmožni obdelati. Posledica tega je utrujenost in padec stopnje osredotočenosti. Tako se sčasoma v posamezniku vzpostavi občutek nezavzetosti, naveličanosti in nekritičnosti do vseh zunanjih dražljajev. Med drugim tudi tistih, ki imajo potencialno vrednost za pozitivne spremembe v njegovem življenju. Posameznik gleda, toda ne vidi. Posluša, toda ne sliši. Hrani se, toda ne zaznava okusa. Tipa, toda ne čuti.</Text>
                <Text style={styles.text}>Ozavestiti čute pomeni vdihniti življenje izbranim dražljajem iz okolice, ki so bili še včeraj le hladne in nepomembne sence med tisoč drugimi brezčutnimi podobami. Z ozaveščanjem čutov spreminjamo odnos do tistih zunanjih dražljajev, ki imajo zmogljivost za preobrazbo in dolgotrajni razvoj naše osebnosti in ustvarjalnosti..</Text>
            </Page>
            <Page style={styles.page} >
                <Text style={styles.subsubtitle}>10.1 NALOGA:</Text>
                <Text style={styles.subsubtitle}>A) Opišite najlepši prizor, ki ste ga kadarkoli v življenju doživeli. Kje je bilo? S kom ste bili?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga10_1}</Text>
                </View>
                <Text style={styles.subsubtitle}>B) Pri tem opazujte vse svoje telesne in psihofizične reakcije. Zapišite kakšni občutki vas pri tem obidejo?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga10_1_1}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.subsubtitle}>10.2 NALOGA:</Text>
                <Text style={styles.subsubtitle}>A) Izberite predmet, ki ima v vašem življenju posebno vrednost. To je lahko stara majica, kavbojke, denarnica, zapestnica, ipd.. Postavite ga na mizo pred sebe (ali ga zamislite) in odgovorite na naslednja vprašanja:</Text>
                <Text style={styles.subsubtitle}>1. Na kaj pomislite? Pretekle izkušnje? Kje je bil izdelan? Kdo je izdelal predmet tvojega opazovanja? Poskusite si zamisliti kako ta človek izgleda.</Text>
                <Text style={styles.subsubtitle}>2. Kako predmet vpliva na vseh 5 čutil – tip, vid, voh, okus in sluh?</Text>
                <Text style={styles.subsubtitle}>3. Poskusi povezati svoje čutilno izkustvo s svojimi čustvi. Ali najdeš povezavo?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga10_2}</Text>
                </View>
                <Text style={styles.subsubtitle}>C) Sedaj izberite predmet in isto vajo naredi s predmetom s katerim nimaš povezave oz. ti nič ne pomeni. Kaj je drugače? Zakaj se ne moreš čustveno povezati?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga10_2_1}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>11. KREIRANJE ČUDOVITEGA UMA</Text>
                <Text style={styles.text}>Govorimo o psihofizičnem stanju, ki porazumeva kar najboljšo uskladitev naših možganov, misli in izgovorjenih besed. Gre za izjemno stanje človeškega uma, v katerem prihajajo do izraza še neznane in neodkrite psihofizične sposobnosti posameznika. Tako nastajajo neverjetne, toda uresničljive zamisli. Pogled je razširjen in poglobljen. Um uravnotežen in izostren. Naenkrat ste sposobni boljše razumeti stvarnost in učinkoviteje reševati izzive. Govorimo o stanju, ki presega potrebe za opravljanje vsakdanjih dejavnosti, pri katerih posameznik v glavnem uporablja levo hemisfero možganov. Leva stran temelji na logiki, matematičnih enačbah, številkah, pomenu besed in razumu. Uporabljamo jo pri branju, pisanju, računanju, slovničnih pravilih in naravoslovju.</Text>
                <Text style={styles.text}>Leva hemisfera razmišlja v besedah, zato omogoča učenje na pamet in pomnjenje različnih podatkov. Njene odlike so kritično razmišljanje, analiziranje, objektivnost, pozornost in razumskost. Leva polobla je prispodoba za fizični svet in družbeno ureditev, v kateri živimo. Na drugi strani je sicer pri večini uspavana desna polobla, in prav ta je adut posameznika, ki si želi uresničiti svoje sposobnosti in ustvariti svoj svet, saj je je izvor idejnosti, ustvarjalnosti in izvirnosti. Desna stran možganov za svoje delovanje uporablja miselne slike, čustva, barve in ritem govora. Posledično je zadolžena za domišljijo, tvorjenje prepričanj, intuicijo, subjektivnost, motoriko, glasbo in prepoznavanje obrazov. Cilj ustvarjalca je napredovati pri uporabljanju desne hemisfere in tako doseči usklajeno delovanje z že dodobra razvito levo hemisfero možganov.</Text>
                <Text style={styles.subsubtitle}>11.1 NALOGA:</Text>
                <Text style={styles.subsubtitle}>V kolikšni meri IQ določa uspeh v karieri?</Text>
                <Text style={styles.subsubtitle}>a.) 50 do 60%</Text>
                <Text style={styles.subsubtitle}>b.) 35 do 45 %</Text>
                <Text style={styles.subsubtitle}>c.) 23 do 29%</Text>
                <Text style={styles.subsubtitle}>d.) 15 do 20%</Text>
                <Text style={styles.subsubtitle}>Odgovor: {props.data.naloga11_1}</Text>
            </Page>
            <Page style={styles.page} >
                <Text style={[styles.subsubtitle, {marginTop: 30}]}>11.2 NALOGA:</Text>
                <Text style={styles.subsubtitle}>Zapišite nekajkrat svoje ime z nasprotno roko.</Text>
                <Text style={styles.subsubtitle}>Kakšni so vaši občutki?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga11_2}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.text}>Zakaj uporabljati izvorne domače besede? Zato ker so te besede del tako naše genske zasnove in našega kolektivnega nezavednega, kot je govoril nemški psiholog Carl Gustav Jung. Takšne besede so veliko bližje našemu razumevanju, kot so tuje besede. Ko izgovorimo domačo besedo prikliče naša zavest iz kolektivno nezavednega tudi sliko, ki vzpodbuja delovanje desne hemisfere.</Text>
                <Text style={styles.subsubtitle}>11.3 Kakšne slike se vam porodijo, ko izgovorite naslednje besede?</Text>
                <View style={styles.container}>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>1. Ljubezen:</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_3.a1}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>2. Izviren:</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_3.a2}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>3. Zdravo:</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_3.a3}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>4. Sreča:</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_3.a4}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>5. Gozd:</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_3.a5}</Text>
                    </View>
                </View>
                <Text style={[styles.subsubtitle, {marginTop: 30}]}>11.4 Poiščite sopomenke v slovenskem jeziku naslednjim besedam.</Text>
                <View style={styles.container}>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>1. Originalen -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a1}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>2. Čao -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a2}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>3. Kalkulacija -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a3}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>4. Edukacija -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a4}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>5. Inspiracija -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a5}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>6. Empatija -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a6}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>7. Profesionalec -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a7}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>8. Nivo -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a8}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>9. Solucija -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a9}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>10. Ego -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a10}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>11. Koncipiran -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a11}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.subsubtitleClean}>12. Kohezija -</Text>
                        <Text style={styles.flexItem}>{props.data.naloga11_4.a12}</Text>
                    </View>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.text}>Besedne igre koristijo možganom, ker aktivirajo dele možganov, ki se ukvarjajo z iskanjem jezika in besed, kar možgane prisili, da telovadijo, delajo in so aktivni. Tako se v možganih ustvarjajo nove nevronske poti, krepi kritično razmišljanje in sposobnost reševanja problemov.</Text>
                <Text style={styles.subsubtitle}>11.5 Zapišite vse besede, ki se začenjajo na črko T.</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga11_5}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>12. TRANSFORMACIJA MINDSETA</Text>
                <Text style={styles.text}>Zakaj v določenih okoliščinah namesto resničnosti vidimo to, kar si želimo? Preprosto. Gre za to, da naše oči velikokrat vidijo tisto, kar si želijo videti naša čustva. Zato nismo sposobni prepoznati razhajanj med dejanskim in čustveno obarvanim doživetjem. Tako se nam zaradi številnih programov, ki upravljajo našo podzavest, okoliščina zazdi tako pristna, da ustvarimo projekcijo stvarnosti. Novooblikovana stvarnost je torej fikcija, podprta z določenimi lastnostmi, ki niso resnične, temveč vsebujejo samo vzorce resničnosti. Na tem temeljijo gledališke predstave, glasbeni spoti, filmi in računalniške igre.</Text>
                <Text style={styles.text}>Najlažje je reči: jaz sem takšen in ne morem se spreminjati. Ali je temu res tako? Zalotili smo vas pri letih, ki jih imate in sedaj ste taki. Dvajset let nazaj ste bili drugačni. Že jutri se vam lahko zgodi nekaj zaradi česar lahko za delček, deloma ali pa popolnoma spremenite svoj pogled na svet okoli sebe. Torej, treba je spoznati, da so vsa čustva nekaj nestalnega in spremenljivega.</Text>
                <Text style={styles.text}>Naloga posameznika je, da se v procesu transformacije, tj. individuacije nauči razlikovati svojo masko, ki ga ščiti pred pritiski in zahtevami zunanjega sveta in svojo avtentično osebnostjo. Pri celotnem procesu gre za odpiranje posameznika k čustvovanju in širši duhovnosti s ciljem oblikovanja novega zavedanja, ki zaobjema intuitivne procese, ustvarjalnost, domišljijo in večjo psihično občutljivost v odnosu do sebe in drugih.</Text>
                <Text style={styles.subsubtitle}>12.1 Ali obstaja konkretno opravilo ali osebnostna lastnost, ki vam je neprijetna? Kaj je to?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga12_1}</Text>
                </View>
            </Page>
            <Page style={styles.page} >
                <Text style={styles.subsubtitle}>12.2 Zapišite vse razloge zakaj ravno to „sovražite“?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga12_2}</Text>
                </View>
                <Text style={styles.subsubtitle}>12.3 Vam je neprijetno opravilo/lastnost ali oseba?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga12_3}</Text>
                </View>
                <Text style={styles.subsubtitle}>12.4 Zapišite vse kar lahko VI naredite, da bi svoje prepričanje spremenili?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga12_4}</Text>
                </View>
            </Page>
            <Page style={styles.page} >
                <Text style={styles.subsubtitle}>12.5 Kako oz. po čem želiš, da te se ljudje spominjajo po tvoji smrti?</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga12_5}</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <Text style={styles.title}>13. DOSTOP DO KOZMIČNEGA ZNANJA</Text>
                <Text style={styles.text}>Naj se še tako močno trudimo, samo z uporabo logičnega sklepanja ne bomo uspeli razvozlati, kako so se porodile Leonardove nazorne skice letala in robota ali Teslova zasnova brezžične komunikacije. Medtem ko so določene zamisli popolnoma usklajene s časom, v katerem sta ustvarjala, druge od njunega časa odstopajo za več stoletij. Razrešitev ene najzahtevnejših ugank ponuja kvantna mehanika, ki izvor njune genialnosti pripisuje sposobnosti dolgotrajnega delovanja uma v tako imenovani frekvenci alfa (8–12 Hz).</Text>
                <Text style={styles.text}>Na tej frekvenci se na stežaj odprejo vrata podzavesti, s čimer izginejo vsa dotedanja spoznanja o delovanju in urejenosti sveta in človeka v njem. Popolnoma naravno brez kakršnegakoli naprezanja in osvobojeno od stresa nastajajo zamisli, ki so plod sodelovanja ustvarjalnega razmišljanja, izjemne intuicije in najboljših vrlin. Vse, kar je bilo prej nemogoče, postane naenkrat mogoče. Govorimo o stopnji zavesti, na kateri je človekov um sinhroniziran z ionosfero, s čimer se omogoči dostop do univerzalnega informacijskega polja v vesolju, v katerem je shranjeno vse znanje našega sveta. V tem primeru znanje ni omejeno le na časovno obdobje, v katerem ustvarja posameznik, temveč zaobjema univerzalno znanje od nastanka do konca sveta. Preteklost, sedanjost in prihodnost obstajajo sočasno.</Text>
                <Text style={styles.text}>Nasprotno možgani človeka pri vsakdanjih opravilih v glavnem delujejo na frekvenci beta (13–40 Hz). To ne pomeni, da pri tem nimamo možnosti vpogleda v kozmično znanje. Seveda jo imamo, vendar za veliko krajši čas. Govorimo o času, tik preden zaspimo, oziroma času, preden se prebudimo. Takrat lahko v kratkem ustvarjalnem času pridete do epohalnih zamisli, ki lahko vaše življenje obrnejo na glavo. Takrat naši možgani popolnoma naravno delujejo na frekvenci alfa. Pri tem je treba poudariti, da ima posameznik v nasprotju z genijem, ki je lahko v stanju alfa vztrajal tudi po več ur, na voljo zelo malo časa za prenos svoje vizije v resničnost. Samo nekaj sekund, kajti pridobljena slika zaradi nezdružljivosti valovanja alfa in beta, to je vizije in stvarnosti, zelo hitro bledi. Zato je izjemno pomembno, da se ob pojavu slike zbudite in si vizijo zapišete na list papirja. Če tega ne boste storili, boste na vizijo pozabili.</Text>
            </Page>
            <Page style={styles.page} >
                <Text style={styles.subsubtitle}>13. NALOGA:</Text>
                <Text style={styles.subsubtitle}>Danes zvečer, preden zaspite, razmislite o svojih ciljih in željah ter vseh tistih vzgibih, ki ste jih občutili na današnji delavnici. Naslednje jutro, ko se boste zbudili, bodite zelo pozorni na svoje prve misli. Ne dovolite jim, da uidejo, ampak jih zadržite in zapišite na list papirja. Številni med vami bodo prepoznali svojo zamisel.</Text>
                <View style={styles.textBox}>
                    <Text style={styles.text}>{props.data.naloga13_1}</Text>
                </View>
            </Page>
        </Document>
    );
}
