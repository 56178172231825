import Pencil from "../images/pencil.svg";
import Exclamation from "../images/exclamation.svg";
import Idea from "../images/idea.svg";
import Question from "../images/question.svg";
import "./pageHeading.css";

const PageHeading = ({img = "idea", heading = "Naslov"}) => {
    return (
        <div className="heading">
            <h1>{heading}</h1>
            <img alt={`${img}`} src={ img === "idea" ? Idea : img === "pencil" && Pencil } height={125} width={125} />

        </div>
    )
}

export default PageHeading
