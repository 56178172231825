import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT5_1 from "./assignments/Assignment5_1";

function naloga5_1(props) {
    return (
        <div>
            <PageHeading heading="5.1 Naloga" img="pencil" />

            <h5>LASTNA KVANTNA STRUKTURA</h5>

            <ASSIGNMENT5_1 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/5-2-naloga"} nextSection={"5.2 Naloga"} />
        </div> 
    )
}

export default naloga5_1;