import React, { useState } from 'react';
import { Button, Form, Col, Row } from "react-bootstrap";
import { Steps, Button as ButtonRS, Panel, ButtonGroup } from 'rsuite';
import OsebnaKvantnaStruktura from "../../components/OsebnaKvantnaStruktura";
import "./assignments.css";



function Assignment5_1(props) {
    const [step, setStep] = useState(0);
    const [naloga5_1, set5_1] = useState(props.data);

    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 10 ? 10 : nextStep);
    };

    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    const styles = {
        width: '200px',
        display: 'inline-table',
        verticalAlign: 'top'
      };

    return (
        <div className="assignment">
            <OsebnaKvantnaStruktura
                ime={naloga5_1.ime}
                zenamoz={naloga5_1.zenamoz}
                matioce={naloga5_1.matioce}
                druzina1_ocemati_1={naloga5_1.druzina1_ocemati_1}
                druzina1_ocemati_2={naloga5_1.druzina1_ocemati_2}
                druzina1_ocemati_3={naloga5_1.druzina1_ocemati_3}
                druzina1_ocemati_4={naloga5_1.druzina1_ocemati_4}
                druzina1_zenamoz_1={naloga5_1.druzina1_zenamoz_1}
                druzina1_zenamoz_2={naloga5_1.druzina1_zenamoz_2}
                dom1_1={naloga5_1.dom1_1}
                dom1_2={naloga5_1.dom1_2}
                izobrazba1={naloga5_1.izobrazba1}
                izobrazba1_1={naloga5_1.izobrazba1_1}
                izobrazba1_2={naloga5_1.izobrazba1_2}
                izobrazba1_3={naloga5_1.izobrazba1_3}
                poklic1_1={naloga5_1.poklic1_1}
                poklic1_2={naloga5_1.poklic1_2}
                poklic2_1={naloga5_1.poklic2_1}
                poklic2_2={naloga5_1.poklic2_2}
                hobiji1={naloga5_1.hobiji1}
                hobiji1_1={naloga5_1.hobiji1_1}
                hobiji1_2={naloga5_1.hobiji1_2}
                hobiji1_3={naloga5_1.hobiji1_3}
                hobiji2={naloga5_1.hobiji2}
                hobiji2_1={naloga5_1.hobiji2_1}
                hobiji2_2={naloga5_1.hobiji2_2}
                hobiji3={naloga5_1.hobiji3}
                hobiji3_1={naloga5_1.hobiji3_1}
                hobiji3_2={naloga5_1.hobiji3_2}
                hobiji4={naloga5_1.hobiji4}
                prijatelji1={naloga5_1.prijatelji1}
                prijatelji1_1={naloga5_1.prijatelji1_1}
                prijatelji2={naloga5_1.prijatelji2}
                prijatelji2_1={naloga5_1.prijatelji2_1}
                prijatelji3={naloga5_1.prijatelji3}
                prijatelji3_1={naloga5_1.prijatelji3_1}
                vrednote1_1={naloga5_1.vrednote1_1}
                vrednote1_2={naloga5_1.vrednote1_2}
                vrednote1_3={naloga5_1.vrednote1_3}
                vrednote1_4={naloga5_1.vrednote1_4}
                osebnaRast1_1={naloga5_1.osebnaRast1_1}
                osebnaRast1_2={naloga5_1.osebnaRast1_2}
                cilji1_1={naloga5_1.cilji1_1}
                cilji1_2={naloga5_1.cilji1_2}
            />

            <div style={{display: "flex"}}>
                <Steps current={step} vertical style={styles}>
                    <Steps.Item title="Vi in vaša družina" />
                    <Steps.Item title="Dom" />
                    <Steps.Item title="Izobrazba" />
                    <Steps.Item title="Poklic" />
                    <Steps.Item title="Hobiji" />
                    <Steps.Item title="Prijatelji" />
                    <Steps.Item title="Vrednote" />
                    <Steps.Item title="Osebna rast" />
                    <Steps.Item title="Cilji" />
                </Steps>
                <div style={{width: "100%"}}>
                    <Panel header={`Korak: ${step + 1}`}>
                        {step === 0 && 
                        <div>
                            <h3>Vi in vaša družina</h3>
                            <Form.Label>Vaše ime</Form.Label>
                            <Form.Control value={naloga5_1.ime} onChange={(evt) => set5_1({ ...naloga5_1, ime: evt.target.value })} type="text" />
                            <hr />
                            <Row>
                                <Col>
                                    <h6>Mati / oče</h6>
                                    <Form.Label>1. otrok</Form.Label>
                                    <Form.Control value={naloga5_1.druzina1_ocemati_1} onChange={(evt) => set5_1({ ...naloga5_1, druzina1_ocemati_1: evt.target.value })} type="text" />
                                    <Form.Label>2. otrok</Form.Label>
                                    <Form.Control value={naloga5_1.druzina1_ocemati_2} onChange={(evt) => set5_1({ ...naloga5_1, druzina1_ocemati_2: evt.target.value })} type="text" />
                                    <Form.Label>3. otrok</Form.Label>
                                    <Form.Control value={naloga5_1.druzina1_ocemati_3} onChange={(evt) => set5_1({ ...naloga5_1, druzina1_ocemati_3: evt.target.value })} type="text" />
                                    <Form.Label>4. otrok</Form.Label>
                                    <Form.Control value={naloga5_1.druzina1_ocemati_4} onChange={(evt) => set5_1({ ...naloga5_1, druzina1_ocemati_4: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    <h6>Žena / mož</h6>
                                    <Form.Label>Partner</Form.Label>
                                    <Form.Control value={naloga5_1.druzina1_zenamoz_1} onChange={(evt) => set5_1({ ...naloga5_1, druzina1_zenamoz_1: evt.target.value })} type="text" />
                                    <Form.Label>Partner</Form.Label>
                                    <Form.Control value={naloga5_1.druzina1_zenamoz_2} onChange={(evt) => set5_1({ ...naloga5_1, druzina1_zenamoz_2: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                        </div>}
                        {step === 1 && 
                        <div>
                            <h3>Dom</h3>
                            <Row>
                                <Col>
                                    <Form.Label>1. dom</Form.Label>
                                    <Form.Control value={naloga5_1.dom1_1} onChange={(evt) => set5_1({ ...naloga5_1, dom1_1: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    <Form.Label>2. dom</Form.Label>
                                    <Form.Control value={naloga5_1.dom1_2} onChange={(evt) => set5_1({ ...naloga5_1, dom1_2: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                        </div>}
                        {step === 2 && 
                        <div>
                            <h3>Izobrazba</h3>
                            <Row>
                                <Col>
                                    <Form.Label>Šola</Form.Label>
                                    <Form.Control value={naloga5_1.izobrazba1} onChange={(evt) => set5_1({ ...naloga5_1, izobrazba1: evt.target.value })} type="text" />
                                    
                                </Col>
                                <Col>
                                    <Form.Label>1</Form.Label>
                                    <Form.Control value={naloga5_1.izobrazba1_1} onChange={(evt) => set5_1({ ...naloga5_1, izobrazba1_1: evt.target.value })} type="text" />
                                    <Form.Label>2</Form.Label>
                                    <Form.Control value={naloga5_1.izobrazba1_2} onChange={(evt) => set5_1({ ...naloga5_1, izobrazba1_2: evt.target.value })} type="text" />
                                    <Form.Label>3</Form.Label>
                                    <Form.Control value={naloga5_1.izobrazba1_3} onChange={(evt) => set5_1({ ...naloga5_1, izobrazba1_3: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                        </div>}
                        {step === 3 && 
                        <div>
                            <h3>Poklic</h3>
                            <Row>
                                <Col>
                                    <h6>Projekti</h6>
                                    <Form.Label>1. projekt</Form.Label>
                                    <Form.Control value={naloga5_1.poklic1_1} onChange={(evt) => set5_1({ ...naloga5_1, poklic1_1: evt.target.value })} type="text" />
                                    <Form.Label>2. projekt</Form.Label>
                                    <Form.Control value={naloga5_1.poklic1_2} onChange={(evt) => set5_1({ ...naloga5_1, poklic1_2: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    <h6>Vloge</h6>
                                    <Form.Label>1. vloga</Form.Label>
                                    <Form.Control value={naloga5_1.poklic2_1} onChange={(evt) => set5_1({ ...naloga5_1, poklic2_1: evt.target.value })} type="text" />
                                    <Form.Label>2. vloga</Form.Label>
                                    <Form.Control value={naloga5_1.poklic2_2} onChange={(evt) => set5_1({ ...naloga5_1, poklic2_2: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                        </div>}
                        {step === 4 && 
                        <div>
                            <h3>Hobiji</h3>
                            <Row>
                                <Col>
                                    <h6>1. hobij</h6>
                                    <Form.Control value={naloga5_1.hobiji1} onChange={(evt) => set5_1({ ...naloga5_1, hobiji1: evt.target.value })} type="text" />
                                    <hr />
                                    <Form.Label>1</Form.Label>
                                    <Form.Control value={naloga5_1.hobiji1_1} onChange={(evt) => set5_1({ ...naloga5_1, hobiji1_1: evt.target.value })} type="text" />
                                    <Form.Label>2</Form.Label>
                                    <Form.Control value={naloga5_1.hobiji1_2} onChange={(evt) => set5_1({ ...naloga5_1, hobiji1_2: evt.target.value })} type="text" />
                                    <Form.Label>3</Form.Label>
                                    <Form.Control value={naloga5_1.hobiji1_3} onChange={(evt) => set5_1({ ...naloga5_1, hobiji1_3: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    <h6>2. hobij</h6>
                                    <Form.Control value={naloga5_1.hobiji2} onChange={(evt) => set5_1({ ...naloga5_1, hobiji2: evt.target.value })} type="text" />
                                    <hr />
                                    <Form.Label>1</Form.Label>
                                    <Form.Control value={naloga5_1.hobiji2_1} onChange={(evt) => set5_1({ ...naloga5_1, hobiji2_1: evt.target.value })} type="text" />
                                    <Form.Label>2</Form.Label>
                                    <Form.Control value={naloga5_1.hobiji2_2} onChange={(evt) => set5_1({ ...naloga5_1, hobiji2_2: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                            <Row><p></p><hr /></Row>
                            <Row>
                                <Col>
                                    <h6>3. hobij</h6>
                                    <Form.Control value={naloga5_1.hobiji3} onChange={(evt) => set5_1({ ...naloga5_1, hobiji3: evt.target.value })} type="text" />
                                    <hr />
                                    <Form.Label>1</Form.Label>
                                    <Form.Control value={naloga5_1.hobiji3_1} onChange={(evt) => set5_1({ ...naloga5_1, hobiji3_1: evt.target.value })} type="text" />
                                    <Form.Label>2</Form.Label>
                                    <Form.Control value={naloga5_1.hobiji3_2} onChange={(evt) => set5_1({ ...naloga5_1, hobiji3_2: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    <h6>4. hobij</h6>
                                    <Form.Control value={naloga5_1.hobiji4} onChange={(evt) => set5_1({ ...naloga5_1, hobiji4: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                        </div>}
                        {step === 5 && 
                        <div>
                            <h3>Prijatelji</h3>
                            <Row>
                                <Col>
                                    <h6>1. prijatelj</h6>
                                    <Form.Control value={naloga5_1.prijatelji1} onChange={(evt) => set5_1({ ...naloga5_1, prijatelji1: evt.target.value })} type="text" />
                                    <Form.Label>Poklic / vloga</Form.Label>
                                    <Form.Control value={naloga5_1.prijatelji1_1} onChange={(evt) => set5_1({ ...naloga5_1, prijatelji1_1: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    <h6>2. prijatelj</h6>
                                    <Form.Control value={naloga5_1.prijatelji2} onChange={(evt) => set5_1({ ...naloga5_1, prijatelji2: evt.target.value })} type="text" />
                                    <Form.Label>Poklic / vloga</Form.Label>
                                    <Form.Control value={naloga5_1.prijatelji2_1} onChange={(evt) => set5_1({ ...naloga5_1, prijatelji2_1: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                            <Row><p></p><hr /></Row>
                            <Row>
                                <Col>
                                    <h6>3. prijatelj</h6>
                                    <Form.Control value={naloga5_1.prijatelji3} onChange={(evt) => set5_1({ ...naloga5_1, prijatelji3: evt.target.value })} type="text" />
                                    <Form.Label>Poklic / vloga</Form.Label>
                                    <Form.Control value={naloga5_1.prijatelji3_1} onChange={(evt) => set5_1({ ...naloga5_1, prijatelji3_1: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    
                                </Col>
                            </Row>
                        </div>}
                        {step === 6 && 
                        <div>
                            <h3>Vrednote</h3>
                            <Row>
                                <Col>
                                    <Form.Label>1. vrednota</Form.Label>
                                    <Form.Control value={naloga5_1.vrednote1_1} onChange={(evt) => set5_1({ ...naloga5_1, vrednote1_1: evt.target.value })} type="text" />
                                    <Form.Label>2. vrednota</Form.Label>
                                    <Form.Control value={naloga5_1.vrednote1_2} onChange={(evt) => set5_1({ ...naloga5_1, vrednote1_2: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    <Form.Label>3. vrednota</Form.Label>
                                    <Form.Control value={naloga5_1.vrednote1_3} onChange={(evt) => set5_1({ ...naloga5_1, vrednote1_3: evt.target.value })} type="text" />
                                    <Form.Label>4. vrednota</Form.Label>
                                    <Form.Control value={naloga5_1.vrednote1_4} onChange={(evt) => set5_1({ ...naloga5_1, vrednote1_4: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                        </div>}
                        {step === 7 && 
                        <div>
                            <h3>Osebna rast</h3>
                            <Row>
                                <Col>
                                    <Form.Label>1</Form.Label>
                                    <Form.Control value={naloga5_1.osebnaRast1_1} onChange={(evt) => set5_1({ ...naloga5_1, osebnaRast1_1: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    <Form.Label>2</Form.Label>
                                    <Form.Control value={naloga5_1.osebnaRast1_2} onChange={(evt) => set5_1({ ...naloga5_1, osebnaRast1_2: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                        </div>}
                        {step === 8 && 
                        <div>
                            <h3>Cilji</h3>
                            <Row>
                                <Col>
                                    <Form.Label>1. cilj</Form.Label>
                                    <Form.Control value={naloga5_1.cilji1_1} onChange={(evt) => set5_1({ ...naloga5_1, cilji1_1: evt.target.value })} type="text" />
                                </Col>
                                <Col>
                                    <Form.Label>2. cilj</Form.Label>
                                    <Form.Control value={naloga5_1.cilji1_2} onChange={(evt) => set5_1({ ...naloga5_1, cilji1_2: evt.target.value })} type="text" />
                                </Col>
                            </Row>
                        </div>}
                        <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga05_1",naloga5_1)} >Shrani</Button>
                    </Panel>
                    <hr />
                    <ButtonGroup>
                        <ButtonRS onClick={onPrevious} disabled={step === 0}>
                        Nazaj
                        </ButtonRS>
                        <ButtonRS onClick={onNext} disabled={step === 8}>
                        Naprej
                        </ButtonRS>
                    </ButtonGroup>
                </div>
            </div>
        </div> 
    )
}

export default Assignment5_1;
