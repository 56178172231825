export const naloga2_1_vrednote = [
    "Avtentičnost",
    "Odgovornost",
    "Natančnost",
    "Cilj-naravnanost",
    "Avanturizem",
    "Ambicioznost",
    "Asertivnost",
    "Uravnoteženost",
    "Pripadnost",
    "Drznost",
    "Umirjenost",
    "Previdnost",
    "Gotovost",
    "Izziv",
    "Sprememba",
    "Jasna miselnost",
    "Zavzetost",
    "Skupnost",
    "Sočutje",
    "Povezanost",
    "Doslednost",
    "Zadovoljstvo",
    "Prispevek",
    "Kreativnost",
    "Odločnost",
    "Zanesljivost",
    "Odločujoč",
    "Pobožnost",
    "Marljivost",
    "Disciplina",
    "Uvidevnost",
    "Učinkovitost",
    "Zmogljivost",
    "Empatija",
    "Navdušenost",
    "Enakost",
    "Odličnost",
    "Vznemirjenost",
    "Raziskovanje",
    "Izraznost",
    "Pravičnost",
    "Vera",
    "Svoboda",
    "Izpolnitev",
    "Hvaležnost",
    "Dajanje",
    "Dobrota",
    "Rast",
    "Trdo delo",
    "Iskrenost",
    "Upanje",
    "Ponižnost",
    "Neodvisnost",
    "Iznajdljivost",
    "Identiteta",
    "Vodenje",
    "Zapuščina",
    "Ljubezen",
    "Zvestoba",
    "Poslušnost",
    "Odprtost",
    "Mir",
    "Praktičnost",
    "Smisel",
    "Profesionalnost",
    "Napredek",
    "Zanesljivost",
    "Analitičnost",
    "Odnosi",
    "Tveganje",
    "Varnost",
    "Samokontrola",
    "Samo-izboljšanje",
    "Deli",
    "Nesebičnost",
    "Samo-vrednost",
    "Spontanost",
    "Stabilnost",
    "Timsko delo",
    "Zmernost",
    "Temeljitost",
    "Pravočasnost",
    "Toleranca",
    "Tradicija",
    "Zaupljivost",
    "Resnica",
    "Negotovost",
    "Razumevanje",
    "Optimizem",
    "Raznolikost",
    "Vizija",
    "Vitalnost"
];