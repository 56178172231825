import React, { useState } from 'react';
import "./assignments.css";
import { Form, Button } from "react-bootstrap";

function Assignment9_1(props) {
    const [naloga9_1, set9_1] = useState(props.data)

    return (
        <div className="assignment">
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-25 text-center">
                    <h4>POKLIC</h4>
                </div>
                <div className="flex flex-row flex-75 ">
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.a1} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, a1: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.a2} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, a2: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.a3} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, a3: evt.target.value })}></Form.Control>
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-25 text-center">
                    <h4>PARTNERSTVO</h4>
                </div>
                <div className="flex flex-row flex-75 ">
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.b1} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, b1: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.b2} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, b2: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.b3} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, b3: evt.target.value })}></Form.Control>
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-25 text-center">
                    <h4>STARŠEVSTVO</h4>
                </div>
                <div className="flex flex-row flex-75 ">
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.c1} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, c1: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.c2} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, c2: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.c3} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, c3: evt.target.value })}></Form.Control>
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-25 text-center">
                    <h4>PRIJATELJSTVO</h4>
                </div>
                <div className="flex flex-row flex-75 ">
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.d1} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, d1: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.d2} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, d2: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.d3} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, d3: evt.target.value })}></Form.Control>
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-25 text-center">
                    <h4>OSEBNI RAZVOJ</h4>
                </div>
                <div className="flex flex-row flex-75 ">
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.e1} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, e1: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.e2} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, e2: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.e3} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, e3: evt.target.value })}></Form.Control>
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-25 text-center">
                    <h4>DRUŽBENA VLOGA</h4>
                </div>
                <div className="flex flex-row flex-75 ">
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.f1} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, f1: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.f2} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, f2: evt.target.value })}></Form.Control>
                    </div>
                    <div className="border-tbrl padding-05rem task-input">
                        <Form.Control value={naloga9_1.f3} as="textarea" rows={3} onChange={(evt) => set9_1({ ...naloga9_1, f3: evt.target.value })}></Form.Control>
                    </div>
                </div>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga09_1",naloga9_1)} >Shrani</Button>
        </div> 
    )
}

export default Assignment9_1;
