import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT7_2 from "./assignments/Assignment7_2";

function naloga7_2(props) {
    return (
        <div>
            <PageHeading heading="7.2 Naloga" img="pencil" />

            <h5>Poskusite odgovoriti na vprašanje oz. ugotoviti: »Zakaj o tem razmišljam«?</h5>

            <ASSIGNMENT7_2 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/8-prevzemite-odgovornost"} nextSection={"8. Prevzemite odgovornost"} />
        </div> 
    )
}

export default naloga7_2;