import React, { useState } from 'react';
import "./assignments.css";
import { Button, Form } from "react-bootstrap";

function Assignment8_1(props) {
    const [naloga8_1, set8_1] = useState(props.data)

    return (
        <div className="assignment">
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-20">
                    <h2>KAKO <br />LAHKO</h2>
                </div>
                <div className="flex flex-column flex-80">
                    <div className="flex">
                        <div className="width50 border-tbrl text-center"><h3>VPRAŠANJE / PODROČJE</h3></div>
                        <div className="width50 border-tbrl center-center-flex"><h3>RAZREŠIM?</h3></div>
                    </div>
                    <div className="flex">
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.a1} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, a1: evt.target.value })}></Form.Control>
                        </div>
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.a2} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, a2: evt.target.value })}></Form.Control>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.b1} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, b1: evt.target.value })}></Form.Control>
                        </div>
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.b2} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, b2: evt.target.value })}></Form.Control>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.c1} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, c1: evt.target.value })}></Form.Control>
                        </div>
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.c2} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, c2: evt.target.value })}></Form.Control>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.d1} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, d1: evt.target.value })}></Form.Control>
                        </div>
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.d2} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, d2: evt.target.value })}></Form.Control>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.e1} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, e1: evt.target.value })}></Form.Control>
                        </div>
                        <div className="width50 task-input task-input-size-normal border-tbrl">
                            <Form.Control value={naloga8_1.e2} as="textarea" rows={2} onChange={(evt) => set8_1({ ...naloga8_1, e2: evt.target.value })}></Form.Control>
                        </div>
                    </div>
                </div>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga08_1",naloga8_1)} >Shrani</Button>
        </div> 
    )
}

export default Assignment8_1;
