import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment6_4(props) {
    const [naloga6_4, set6_4] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga6_4} className="notebook-like" onChange={(e) => set6_4(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga06_4",naloga6_4)} >Shrani</Button>
        </div> 
    )
}

export default Assignment6_4;
