import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT12_5 from "../pages/assignments/Assignment12_5"

function naloga12_5(props) {
    return (
        <div>
            <PageHeading heading="12.5 Naloga" img="pencil" />

            <h5>Kako oz. po čem želiš, da te se ljudje spominjajo po tvoji smrti?</h5>

            <ASSIGNMENT12_5 handleSave={props.handleSave} data={props.data} />
            
            <ButtonNextSection link={"/13-dostop-do-kozmicnega-znanja"} nextSection={"13. Dostop do kozmičnega znanja"} />
        </div> 
    )
}

export default naloga12_5