import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT5_4 from "./assignments/Assignment5_4";

function naloga5_4(props) {
    return (
        <div>
            <PageHeading heading="5.4 Naloga" img="pencil" />

            <h5>Spomnite se svojih otrok. Njihovih obrazov, obrazne mimike in gibov. Kateri trenutki so vam se najbolj vtisnili v spomin? Zakaj?</h5>

            <ASSIGNMENT5_4 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/5-5-naloga"} nextSection={"5.5 Naloga"} />
        </div> 
    )
}

export default naloga5_4;