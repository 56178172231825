import React, { useState } from 'react';
import "./assignments.css";
import { Button, Form } from "react-bootstrap";

function Assignment8_2(props) {
    const [naloga8_2, set8_2] = useState(props.data)

    return (
        <div className="assignment">
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-30">
                    <h3>POKLIC</h3>
                </div>
                <div className="flex flex-column border-tbrl padding-05rem flex-70 task-input">
                    <Form.Control value={naloga8_2.a1} as="textarea" rows={3} onChange={(evt) => set8_2({ ...naloga8_2, a1: evt.target.value })}></Form.Control>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-30">
                    <h3>PARTNERSTVO</h3>
                </div>
                <div className="flex flex-column border-tbrl padding-05rem flex-70 task-input">
                    <Form.Control value={naloga8_2.b1} as="textarea" rows={3} onChange={(evt) => set8_2({ ...naloga8_2, b1: evt.target.value })}></Form.Control>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-30">
                    <h3>STARŠEVSTVO</h3>
                </div>
                <div className="flex flex-column border-tbrl padding-05rem flex-70 task-input">
                    <Form.Control value={naloga8_2.c1} as="textarea" rows={3} onChange={(evt) => set8_2({ ...naloga8_2, c1: evt.target.value })}></Form.Control>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-30">
                    <h3>PRIJATELJSTVO</h3>
                </div>
                <div className="flex flex-column border-tbrl padding-05rem flex-70 task-input">
                    <Form.Control value={naloga8_2.d1} as="textarea" rows={3} onChange={(evt) => set8_2({ ...naloga8_2, d1: evt.target.value })}></Form.Control>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-30">
                    <h3>OSEBNI RAZVOJ</h3>
                </div>
                <div className="flex flex-column border-tbrl padding-05rem flex-70 task-input">
                    <Form.Control value={naloga8_2.e1} as="textarea" rows={3} onChange={(evt) => set8_2({ ...naloga8_2, e1: evt.target.value })}></Form.Control>
                </div>
            </div>
            <div className="flex">
                <div className="border-tbrl center-center-flex flex-30 text-center">
                    <h3>DRUŽBENA VLOGA</h3>
                </div>
                <div className="flex flex-column border-tbrl padding-05rem flex-70 task-input">
                    <Form.Control value={naloga8_2.f1} as="textarea" rows={3} onChange={(evt) => set8_2({ ...naloga8_2, f1: evt.target.value })}></Form.Control>
                </div>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga08_2",naloga8_2)} >Shrani</Button>
        </div> 
    )
}

export default Assignment8_2;
