import React, { useState } from 'react';
import { Modal, Button, ButtonToolbar } from 'rsuite';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import osebnaKvantnaStrukturaSteveJobs from "../images/osebna-kvantna-struktura-jobs.jpg";
import "./pages.css";

function OsebnaKvantnaStruktura() {
    const [show, setShow] = useState(false);
    return (
        <div>
            <PageHeading heading="5. Osebna kvantna struktura" img="idea" />
            <p>Da bi posameznik lahko v maksimalni meri dosegel svoje cilje in vplival na svet okoli sebe mora najprej poznati sebe. V tem primeru lahko načrtno in usmerjeno deluje tam, kjer tudi potencialno lahko da največji doprinos. Tako se ne bo lotil področij, kjer objektivno nima možnosti in kjer bi po nepotrebnem zgubljal dragoceno energijo, zdravje in čas.</p>
            <p>Človek lahko zelo dobro razume ali pač misli, da razume svet okoli sebe oz. ima lahko skladiščenega v svojem umu ogromno intelektualnega materiala, ki pa je zaradi nerazumevanja samega sebe neuporaben. Kajti prav od poznavanja samega sebe je odvisno v kolikšni meri bo uresničil vse svoje potenciale.</p>
            <div id="jsmind_container"></div>
            <h3>KVANTNA STRUKTURA STEVE JOBSA</h3>
            
            <img src={osebnaKvantnaStrukturaSteveJobs} width="100%" alt="KVANTNA STRUKTURA STEVE JOBSA" />
            <div className="modal-container">
                <ButtonToolbar>
                    <Button color="violet" size="lg" onClick={() => setShow(true)}>
                        Odpri sliko v celozaslonskem načinu
                    </Button>
                </ButtonToolbar>
                <Modal full show={show} onHide={() => setShow(false)}>
                    <Modal.Header>
                        <Modal.Title>KVANTNA STRUKTURA STEVE JOBSA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={osebnaKvantnaStrukturaSteveJobs} width="100%" alt="KVANTNA STRUKTURA STEVE JOBSA" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShow(false)} appearance="primary">
                            Zapri
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <ButtonNextSection link={"/5-1-naloga"} nextSection={"5.1 Naloga"} />
        </div>     
    )
}

export default OsebnaKvantnaStruktura;
