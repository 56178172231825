import React from "react";
import ButtonNextSection from "../components/ButtonNextSection";
import "./pages.css";

function Uvod() {
    return (
        <div>

            <p>Najuspešnejši posamezniki so navdihujoči voditelji, pametni strategi in kreativni misleci. V vseh poslovnih funkcijah je kreativnost ključna pri reševanju zapletenih problemov, razvoju novih strategij, omogočanju inovacij in spodbujanju sprememb v organizaciji.</p>
            <p>Trening genialnosti je nastal na podlagi dolgoletnega preučevanja življenja in dela največjih genijev človeštva kot so Leonardo da Vinci, Nikola Tesla, Steve Jobs, Hedy Lammar, idr..V trening so vključeni tudi najpomembnejši »know how« prijemi uspešnih Slovencev, kot so Ivo Boscarol, Peter Florjančič, Robert Lešnik, Lara Bohinc, Katarina Venturini, Dejan Zavec in drugi.</p>
            <p>Program je osredotočen na to, kako ustvariti konkurenčno prednost s spodbujanjem ustvarjalne kulture, ki v sebi združuje navdih, sodelovanje in inovacije. Z implementacijo treninga genialnosti se boste naučili, kako z večjo kreativnostjo in inovativnostjo pristopiti k zapletenim poslovnim težavam.</p>
            
            <h2>Izzivi</h2>

            <p>Vse v vesolju je v gibanju. Zemlja, sonce, planeti, naravni sateliti. Vsa narava je v gibanju. Izmenjujejo se letni časi. Rojevajo se živali in vzklijejo rastline. Vsako gibanje ima smisel. In ves smisel je v gibanju. V človekovo podzavest je torej vgrajen <b>naravni mehanizem gibanja</b>; svojevrsten kompas, ki nas vodi skozi življenje. Za razliko od vseh živalskih vrst in vsega živega sveta, ki imajo svojo gotovo vnaprej zaokroženo in določeno naravo, ima na drugi strani človek svobodo, da sam iz sebe naredi tisto kar si želi biti. Človek je tisti, ki oblikuje in ustvarja samega sebe.</p>
            <p>Spremembe so torej povsem naravne in neizbežne. Vsako stanje, ki se nam zdi stagnacija ni mirovanje, ampak je v resnici nazadovanje.  K spremembam nas vodijo vizije, ki nas privlačijo in obljubljajo nagrade po koncu prehojene poti. V tem smislu preobrazba ne pomeni postati nekaj novega, ampak odvreči tisto kar je zastarelo. Vrniti se v svojo bistvo ter poiskati nov ustvarjalni naboj in navdih, da gremo v življenju naprej.</p>
            <p>Po psihologu C.G. Jungu ima vsak človek v sebi arhetip homo-kreatorja. To pomeni, da cilj našega življenja ni trpljenje, ampak ciljana ustvarjalnost z namenom reševanja problemov, zdravljenja bolečine in ustvarjanja tehnologij, ki nam olajšujejo vsakdanje življenje. Kje se tega najbolje učiti, če ne od genialnih ustvarjalcev, ki so izumili svet v katerem danes živimo.</p>

            <h2>Cilji</h2>

            <p>V okviru treninga boste šli skozi tri temeljne faze sprememb: 1. rentgen ali samospoznanje, 2. radio frekvence ali način delovanja, 3. tele-avtomatika ali akcija. Vsaka faza vsebuje praktične primere, orodja in tehnike, ki so jih uporabljali uspešni ustvarjalci. Iste boste v okviru treninga preizkusili in se jih naučili uporabljati tudi vi.</p>
            <p>Cilj je opolnomočiti vas, da prepoznate in prevzamete kontrolo nad vsemi svojimi potenciali in jih povzdignete na nivo genialnosti. Naučili se boste reševati poslovne probleme in sprejemajte boljše odločitve s kreativnim razmišljanjem. Pridobili boste praktična orodja za vključevanje kreativnega razmišljanja v vsakodnevne procese. Naučili se boste kako izvajati in upravljati ustvarjalni postopek na vseh področjih vašega življenja.</p>

            <ButtonNextSection link={"/1-kateri-so-moji-potenciali"} nextSection={"1. Kateri so moji potenciali?"} />
        </div>
    )
}

export default Uvod
