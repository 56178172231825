import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function kateriSoMojiPotenciali() {
    return (
        <div>
            <PageHeading heading="1. Kateri so moji potenciali?" img="idea" />
            <p>Ne obstaja človek na svetu, ki ne bi imel prav nobenega talenta. Vsakdo se rodi z določenimi darovi oz. dragocenostmi, ki so svojstvene samo njemu in drugačne kot pri nekom drugem. Enako kot skozi genski zapis podedujemo naš fizični izgled in karakterne lastnosti, na isti način od svojih prednikov prejmemo talente. Gre za specifično sposobnost, ki imetniku omogoča, da doseže maksimalne rezultate na določenem življenjskem in poklicnem področju.</p>
            <p>Pomislite <b>kaj najraje počnete</b> oz. kaj vas v vsakdanjem življenju osrečuje? Preučite odnose s svojimi bližnjimi, prijatelji in sodelavci. Pomislite ali vas je kdo izmed njih v čem določenem kadarkoli pohvalil? Ali vas prosijo za kakšno specifično pomoč pri aktivnostih, katerih sami niso dovolj vešči? V vaših očeh je morda samoumevna, čeprav gre v resnici za talent. Pomislite ali obstaja kakšna aktivnost ob kateri povsem pozabite na čas? Toliko ste ji posvečeni, da ne uspete postoriti številnih drugih obveznosti? Zaradi nje ostajate budni dolgo v noč? Morda ste pravkar odkrili svoj talent. Ali obstaja kakšna tematika, ki je za vas pomembnejša od vseh drugih? O kateri se lahko pogovarjate, čeprav ste utrujeni? Za vas ostaja sveža in aktualna kljub temu, da ste o njej svojim prijateljem razlagali že neštetokrat?</p>
            <p>Pomislite <b>kako preživljate prosti čas</b>. Kakšno vrsto televizijskih ali radijskih oddaj radi spremljate? Ali obstajajo kakšne tematske revije, ki jih strastno prebirate? Ali komaj čakate izid naslednje številke? Izostrite čute in poskusite najti usklajenost med vašo osebnostjo, sposobnostmi in občudovano vsebino. Na obzorju je vaš talent.</p>
            <p>Pomislite <b>v čem ste dobri</b>. Kaj je za vas zlahka dosegljivo medtem ko imajo ostali pri tem težave? Kaj je za vas preprosto medtem, ko se zdi drugim zapleteno? Ali se počutite izpolnjeno, ko prisluhnete osebam v vaši okolici? Jim z veseljem v čemerkoli pomagate? Ali dobronamerno popravljate njihove napake? V tem primeru očitno razpolagate z določenimi znanji, sposobnostmi in veščinami, ki so v funkciji določenega talenta. Poizvedite v čem ste dobri iz zornega kota okolice. Povprašajte za mnenje o vaših mogočih talentih pri vaših bližnjih, prijateljih, sodelavcih in znancih. Kako vas vidijo? Naredite svojevrstne intervjuje ena na ena, iz oči v oči. Vaše slabosti naj tokrat zadržijo zase, povejo naj vam zgolj vaše dobre lastnosti. Največ dve, ki sta najbolj prepoznavni. Naj bodo v svoji oceni realni in iskreni.</p>
            <p><b>Pogovorite se s starši in starimi starši</b>. Mogoče imajo oni skrite talente, katerih se sicer zavedajo, a jim ne pripisujejo kakšnega posebnega pomena. Vprašajte jih o njihovem pogledu na življenje. Kaj so radi počeli, v čem so bili dobri, o čem so sanjali? Poskusite izvedeti zakaj svojih talentov niso uresničili?</p>
            <p>Vrnite se v preteklost in še enkrat <b>sedite v šolske klopi</b>. Pomislite za katere predmete v osnovni in srednji šoli ste kazali izrazit interes. Kateri so tisti predmeti, ki so vas navduševali bolj kot drugi? Katerih predmetov ste se vedno znova veselili? Poskusite opredeliti področje, h kateremu je vaša osebnost nagnjena bolj kot k drugim. Ali ste ljubitelj družboslovja in humanističnih ved? Mogoče kakšnega specifičnega področja? Vas bolj zanima naravoslovje in fizikalni zakoni? Mogoče ste bili izrazito likovno, glasbeno ali športno nadarjeni. So vas učitelji kadarkoli pohvalili zaradi vaše idejnosti, zanimivih zaključkov ali dobrega razmišljanja? Poskusite se spomniti kaj so vam rekli?</p>
            <p>Prepoznajte svoje talente s pomočjo <b>prijetnih slik iz otroštva</b>. Znova se sprehodite po šolskih hodnikih, igriščih, otroških sobah in parkih. Spomnite se najlepših dogodkov, ki imajo posebno mesto v vašem srcu. Spomnite se pozitivnih oseb, ki so soustvarjale vaše dogodivščine. Skratka bodite otroci in sledite magičnim trenutkom sreče, v katerih ste bili nasmejani, energični in zadovoljni.</p>
            <ButtonNextSection link={"/1-1-naloga"} nextSection={"1.1 Naloga"} />
        </div>     
    )
}

export default kateriSoMojiPotenciali
