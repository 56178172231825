import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function UstvarjalniImaginarium() {
    return (
        <div>
            <PageHeading heading="9. Ustvarjalni imaginarium" img="idea" />
            <p>Človekov biološko vgrajeni mehanizem za prepoznavanje nevarnosti nas ob srečanju s potencialnim problemom zaradi obrambnega mehanizma strahu, večkrat paralizira in postavi v stanje nemoči. To se zgodni vsakič, ko se srečamo s situacijo, ki je nekoliko drugačna od situacij s katerimi imamo običajno opravka. Nova situacija zahteva nov pristop, zaradi česar naši možgani hitro pridejo v stisko. Posledično se na problem osredotočimo tako zelo, da izgubimo širšo sliko in zaradi tega ne najdemo hitre in učinkovite rešitve.</p>
            <p>Da bi lažje razumeli vzemimo za primer liste na drevesu, ki se je iz neznanega razloga začelo sušiti. Želimo mu pomagati. Toda če se bomo ukvarjali zgolj z zdravljenjem lista mu ne bomo pomagali. Za celovito – holistično rešitev moramo preučiti tudi stanje debla in krošnje. Preveriti v kakšnem stanju so korenine. Ali ima dovolj svetlobe in kakšna je kakovost prti.</p>
            <p>Po isti analogiji je potrebno pristopiti reševanju vsakega problema, poslovnega ali osebnega. Kajti izvorni problemi in rešitve so običajno izven našega vidnega polja.</p>
            

            <ButtonNextSection link={"/9-1-naloga"} nextSection={"9.1 Naloga"} />
        </div>     
    )
}

export default UstvarjalniImaginarium;
