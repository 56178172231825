import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT2_3 from "./assignments/Assignment2_3";

function naloga2_3(props) {
    return (
        <div>
            <PageHeading heading="2.3 Naloga" img="pencil" />

            <h5>Zdaj opišite trenutek, ko ste se v službi počutili najbolj razočarani. Opišite detajle te situacije;</h5>
            
            <h5>A) kaj konkretno je pripeljalo do občutka obžalovanja in žalosti?</h5>
            <h5>B) Razmislite in zapišite zakaj?</h5>

            <ASSIGNMENT2_3 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/2-4-naloga"} nextSection={"2.4 Naloga"} />
        </div> 
    )
}

export default naloga2_3;