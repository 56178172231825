import React, { useState } from 'react';
import "./assignments.css";
import { Form, Button } from "react-bootstrap";

function Assignment7_1(props) {
    const [naloga7_1, set7_1] = useState(props.data)

    return (
        <div className="assignment">
            <div className="table-row">
                <div className="square10 task-input center-center-flex border-none text-2-rem"><h2>1.</h2></div>
                <div className="square90 task-input border-none">
                    <Form.Control type="text" value={naloga7_1.a1} className="large-number-input" onChange={(evt) => set7_1({ ...naloga7_1, a1: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square10 task-input center-center-flex border-none text-2-rem"><h2>2.</h2></div>
                <div className="square90 task-input border-none">
                    <Form.Control type="text" value={naloga7_1.a2} className="large-number-input" onChange={(evt) => set7_1({ ...naloga7_1, a2: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square10 task-input center-center-flex border-none text-2-rem"><h2>3.</h2></div>
                <div className="square90 task-input border-none">
                    <Form.Control type="text" value={naloga7_1.a3} className="large-number-input" onChange={(evt) => set7_1({ ...naloga7_1, a3: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square10 task-input center-center-flex border-none text-2-rem"><h2>4.</h2></div>
                <div className="square90 task-input border-none">
                    <Form.Control type="text" value={naloga7_1.a4} className="large-number-input" onChange={(evt) => set7_1({ ...naloga7_1, a4: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square10 task-input center-center-flex border-none text-2-rem"><h2>5.</h2></div>
                <div className="square90 task-input border-none">
                    <Form.Control type="text" value={naloga7_1.a5} className="large-number-input" onChange={(evt) => set7_1({ ...naloga7_1, a5: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga07_1",naloga7_1)} >Shrani</Button>
        </div> 
    )
}

export default Assignment7_1;
