import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT7_1 from "./assignments/Assignment7_1";

function naloga7_1(props) {
    return (
        <div>
            <PageHeading heading="7.1 Naloga" img="pencil" />

            <h5>Zapišite misli oz. vprašanja o katerih že dalj časa razmišljate? Mnoga vprašanja se bodo ponavljala ali podvajala. To pomeni, da so tam večja zanimanja, problemi, interesi in da jih je treba resno preučiti. Zapišite pet najpomembnejših in jih skladno s prioritetami ovrednotite z oceno od 1 od 5 (1 kot najpomembnejše).</h5>

            <ASSIGNMENT7_1 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/7-2-naloga"} nextSection={"7.2 Naloga"} />
        </div> 
    )
}

export default naloga7_1;