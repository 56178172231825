import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function SpodbujajRadovednost() {
    return (
        <div>
            <PageHeading heading="7. Spodbujaj radovednost" img="idea" />
            <p>Radost ni minljivo stanje, ki se pojavlja v odvisnosti od življenjskega obdobja in neodvisno od človekove volje. Radost je večna mladost duha, premo sorazmerna je stopnji zainteresiranosti posameznika zase in za svet okoli sebe. Bolj ko izražamo radovednost, večji je občutek radosti in obratno. Če imate možnost, se ob prvi priložnosti pogovorite s katerimkoli govorečim otrokom. Začetno sramežljivost malega sogovornika bo hitro nadomestila odkritost, ki bo tlakovana s številnimi vprašanji izzivala izbruh radosti na otrokovem obrazu. Toda otrok ne postavlja vprašanj iz dolgčasa ali želje, da bi preizkušal vašo potrpežljivost, kot se večkrat popolnoma napačno misli. Želi pridobiti čim več informacij, na podlagi katerih si oblikuje sliko sveta, ki ga obdaja. Tako se otrokova slika sveta nenehno spreminja in dopolnjuje, s čimer se ustvarjajo pogoji za napredek na vseh področjih: telesnem, duševnem, duhovnem in družbenem. Nasprotno pa je večina odraslih izgubila sposobnost postavljanja vprašanj in tako omejila možnosti za razvoj svojega jaza. Danes odrasli ne postavljajo vprašanj. Tudi v najbolj potrebnih ali kritičnih okoliščinah ne.</p>

            <ButtonNextSection link={"/7-1-naloga"} nextSection={"7.1 Naloga"} />
        </div>     
    )
}

export default SpodbujajRadovednost;
