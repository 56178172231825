import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment2_4(props) {
    const [naloga2_4, set2_4] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga2_4} className="notebook-like" onChange={(e) => set2_4(e.target.value)}>
                </textarea>
            </div> 
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga02_4",naloga2_4)} >Shrani</Button>
        </div>
    )
}

export default Assignment2_4;
