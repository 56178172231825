import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT5_6 from "./assignments/Assignment5_6";

function naloga5_6(props) {
    return (
        <div>
            <PageHeading heading="5.6 Naloga" img="pencil" />

            <h5>Opišite svoje občutke, ko pomislite na uresničitev svojih življenjskih ciljev.</h5>
            <h5>Kaj boste takrat naredili?</h5>

            <ASSIGNMENT5_6 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/6-sest-kvantnih-vlog"} nextSection={"6. Šest kvantnih vlog"} />
        </div> 
    )
}

export default naloga5_6;