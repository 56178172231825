import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT12_2 from "../pages/assignments/Assignment12_2"

function naloga12_2(props) {
    return (
        <div>
            <PageHeading heading="12.2 Naloga" img="pencil" />

            <h5>Zapišite vse razloge zakaj ravno to „sovražite“?</h5>

            <ASSIGNMENT12_2 handleSave={props.handleSave} data={props.data} />
            
            <ButtonNextSection link={"/12-3-naloga"} nextSection={"12.3 Naloga"} />
        </div> 
    )
}

export default naloga12_2