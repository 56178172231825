import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function PrevzemiteOdgovornost() {
    return (
        <div>
            <PageHeading heading="8. Prevzemite odgovornost" img="idea" />
            <p>Vsak izmed nas je popolnoma odgovoren za svoje življenje. To je temeljno načelo, ki ga morate sprejeti, če želite biti uspešni v svojem življenju in delu. Za mnoge ljudi je kriv nekdo drug, ker je tako pač lažje. Vsak problem je mogoče razložiti z razlogi, zakaj ne moremo vplivati na situacijo ali rezultat. Zlasti v službi, kjer je enostavno najti izgovore, ker so ljudje tako tesno prepleteni - in vsak projekt ima verigo notranjih strank, ki so odvisne od drugih.</p>
            <p>Toda brez prevzemanja odgovornosti za svoje postopke in dejanja ni moč doseči napredka. Kajti vse v življenju je pravzaprav povezano z dajanjem odgovorov. Odgovori na posamezne probleme, izzive in preizkušnje vodijo v napredek in zadovoljstvo. Odgovornost je direktno povezana s svobodno voljo, ki nam daje moč odločanja. Če se ne bomo odločali sami, bodo o nas odločali drugi.</p>
            <p>Kako do vzpostavitve večje odgovornosti:</p>
            <ul>
                <li>Prevzemite odgovornost za svoje misli, občutke, besede in dejanja</li>
                <li>Prenehajte obsojati</li>
                <li>Prenehajte jamrati</li>
                <li>Ne jemljite stvari osebno</li>
                <li>Živite v tem trenutku</li>
                <li>Uporabite moč namena</li>
                <li>Iščite v ljudeh dobro</li>
            </ul>

            <ButtonNextSection link={"/8-1-naloga"} nextSection={"8.1 Naloga"} />
        </div>     
    )
}

export default PrevzemiteOdgovornost;
