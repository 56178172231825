import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment4_1(props) {
    const [naloga4_1, set4_1] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga4_1} className="notebook-like" onChange={(e) => set4_1(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga04_1",naloga4_1)} >Shrani</Button>
        </div>
    )
}

export default Assignment4_1;
