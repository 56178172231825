import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT6_1 from "./assignments/Assignment6_1";

function naloga6_1(props) {
    return (
        <div>
            <PageHeading heading="6.1 Naloga" img="pencil" />

            <h5>Ocenite kako dobri ste trenutno v vsaki vlogi. Poskusite biti čim bolj objektivni.</h5>

            <ASSIGNMENT6_1 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/6-2-naloga"} nextSection={"6.2 Naloga"} />
        </div> 
    )
}

export default naloga6_1;