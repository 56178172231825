import React, { useState } from 'react';
import "./assignments.css";
import { Form, Button } from "react-bootstrap";

function Assignment11_3(props) {
    const [naloga11_3, set11_3] = useState(props.data)

    return (
        <div className="assignment">
            <div className="table-row">
                <div className="square20 flex-vertical-center border-none"><h3>1. Ljubezen</h3></div>
                <div className="square80 task-input border-none">
                    <Form.Control value={naloga11_3.a1} type="text" className="large-number-input" onChange={(evt) => set11_3({ ...naloga11_3, a1: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square20 flex-vertical-center border-none"><h3>2. Izviren</h3></div>
                <div className="square80 task-input border-none">
                    <Form.Control value={naloga11_3.a2} type="text" className="large-number-input" onChange={(evt) => set11_3({ ...naloga11_3, a2: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square20 flex-vertical-center border-none"><h3>3. Zdravo</h3></div>
                <div className="square80 task-input border-none">
                    <Form.Control value={naloga11_3.a3} type="text" className="large-number-input" onChange={(evt) => set11_3({ ...naloga11_3, a3: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square20 flex-vertical-center border-none"><h3>4. Sreča</h3></div>
                <div className="square80 task-input border-none">
                    <Form.Control value={naloga11_3.a4} type="text" className="large-number-input" onChange={(evt) => set11_3({ ...naloga11_3, a4: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square20 flex-vertical-center border-none"><h3>5. Gozd</h3></div>
                <div className="square80 task-input border-none">
                    <Form.Control value={naloga11_3.a5} type="text" className="large-number-input" onChange={(evt) => set11_3({ ...naloga11_3, a5: evt.target.value })}>
                    </Form.Control>
                </div>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga11_3",naloga11_3)} >Shrani</Button>
        </div> 
    )
}

export default Assignment11_3;
