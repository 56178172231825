import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment6_3(props) {
    const [naloga6_3, set6_3] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga6_3} className="notebook-like" onChange={(e) => set6_3(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga06_3",naloga6_3)} >Shrani</Button>
        </div> 
    )
}

export default Assignment6_3;
