import ButtonNextSection from "../components/ButtonNextSection"
import PageHeading from "../components/PageHeading"
import ASSIGNMENT12_3 from "../pages/assignments/Assignment12_3"

function naloga12_3(props) {
    return (
        <div>
            <PageHeading heading="12.3 Naloga" img="pencil" />

            <h5>Vam je neprijetno opravilo/lastnost ali oseba?</h5>

            <ASSIGNMENT12_3 handleSave={props.handleSave} data={props.data} />
            
            <ButtonNextSection link={"/12-4-naloga"} nextSection={"12.4 Naloga"} />
        </div> 
    )
}

export default naloga12_3