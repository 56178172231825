import React, { useState } from 'react';
import { Form, Button } from "react-bootstrap";
import "./assignments.css";

function Assignment1_1(props) {
    const [naloga1_1, set1_1] = useState(props.data)

    return (
        <div>
            <div className="assignment">
                <div className="table-row">
                    <div className="square25">1. Talenti, darovi, potenciali</div>
                    <div className="square25">2. Stopnja razvitosti</div>
                    <div className="square25">3. Intenzivnost uporabe</div>
                    <div className="square25">4. Katere uporabljate pri svojem delu</div>
                </div>
                <div className="table-row">
                    <div className="square25 task-input"><Form.Control value={naloga1_1.a1} onChange={(evt) => set1_1({ ...naloga1_1, a1: evt.target.value })} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.a2} onChange={(evt) => set1_1({ ...naloga1_1, a2: evt.target.value })} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.a3} onChange={(evt) => set1_1({ ...naloga1_1, a3: evt.target.value })} as="textarea" rows={2} /></div>
                    <div className="square25 clickable" onClick={() => set1_1({ ...naloga1_1, a4: !naloga1_1.a4})}>{naloga1_1.a4 && <span className="x">X</span> }
                    </div>
                </div>
                <div className="table-row">
                    <div className="square25 task-input"><Form.Control value={naloga1_1.b1} onChange={(evt) => set1_1( { ...naloga1_1, b1: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.b2} onChange={(evt) => set1_1( { ...naloga1_1, b2: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.b3} onChange={(evt) => set1_1( { ...naloga1_1, b3: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 clickable" onClick={() => set1_1( { ...naloga1_1, b4: !naloga1_1.b4} )}>{naloga1_1.b4 && <span className="x">X</span> }
                    </div>
                </div>
                <div className="table-row">
                    <div className="square25 task-input"><Form.Control value={naloga1_1.c1} onChange={(evt) => set1_1( { ...naloga1_1, c1: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.c2} onChange={(evt) => set1_1( { ...naloga1_1, c2: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.c3} onChange={(evt) => set1_1( { ...naloga1_1, c3: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 clickable" onClick={() => set1_1( { ...naloga1_1, c4: !naloga1_1.c4} )}>{naloga1_1.c4 && <span className="x">X</span> }
                    </div>
                </div>
                <div className="table-row">
                    <div className="square25 task-input"><Form.Control value={naloga1_1.d1} onChange={(evt) => set1_1( { ...naloga1_1, d1: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.d2} onChange={(evt) => set1_1( { ...naloga1_1, d2: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.d3} onChange={(evt) => set1_1( { ...naloga1_1, d3: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 clickable" onClick={() => set1_1( { ...naloga1_1, d4: !naloga1_1.d4} )}>{naloga1_1.d4 && <span className="x">X</span> }
                    </div>
                </div>
                <div className="table-row">
                    <div className="square25 task-input"><Form.Control value={naloga1_1.e1} onChange={(evt) => set1_1( { ...naloga1_1, e1: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.e2} onChange={(evt) => set1_1( { ...naloga1_1, e2: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.e3} onChange={(evt) => set1_1( { ...naloga1_1, e3: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 clickable" onClick={() => set1_1( { ...naloga1_1, e4: !naloga1_1.e4} )}>{naloga1_1.e4 && <span className="x">X</span> }
                    </div>
                </div>
                <div className="table-row">
                    <div className="square25 task-input"><Form.Control value={naloga1_1.f1} onChange={(evt) => set1_1( { ...naloga1_1, f1: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.f2} onChange={(evt) => set1_1( { ...naloga1_1, f2: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 task-input"><Form.Control value={naloga1_1.f3} onChange={(evt) => set1_1( { ...naloga1_1, f3: evt.target.value})} as="textarea" rows={2} /></div>
                    <div className="square25 clickable" onClick={() => set1_1( { ...naloga1_1, f4: !naloga1_1.f4} )}>{naloga1_1.f4 && <span className="x">X</span> }
                    </div>
                </div>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga01_1",naloga1_1)} >Shrani</Button>
        </div> 
    )
}

export default Assignment1_1
