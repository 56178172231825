import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import "./pages.css";

function TransfromacijaMindseta() {
    return (
        <div>
            <PageHeading heading="12. Transformacija mindseta" img="idea" />
            <p>Zakaj v določenih okoliščinah namesto resničnosti vidimo to, kar si želimo? Preprosto. Gre za to, da naše oči velikokrat vidijo tisto, kar si želijo videti naša čustva. Zato nismo sposobni prepoznati razhajanj med dejanskim in čustveno obarvanim doživetjem. Tako se nam zaradi številnih programov, ki upravljajo našo podzavest, okoliščina zazdi tako pristna, da ustvarimo projekcijo stvarnosti. Novooblikovana stvarnost je torej fikcija, podprta z določenimi lastnostmi, ki niso resnične, temveč vsebujejo samo vzorce resničnosti. Na tem temeljijo gledališke predstave, glasbeni spoti, filmi in računalniške igre.</p>
            <p>Najlažje je reči: jaz sem takšen in ne morem se spreminjati. Ali je temu res tako? Zalotili smo vas pri letih, ki jih imate in sedaj ste taki. Dvajset let nazaj ste bili drugačni. Že jutri se vam lahko zgodi nekaj zaradi česar lahko za delček, deloma ali pa popolnoma spremenite svoj pogled na svet okoli sebe. Torej, treba je spoznati, da so vsa čustva nekaj nestalnega in spremenljivega.</p>
            <p>Naloga posameznika je, da se v procesu transformacije, tj. individuacije nauči razlikovati svojo masko, ki ga ščiti pred pritiski in zahtevami zunanjega sveta in svojo avtentično osebnostjo. Pri celotnem procesu gre za odpiranje posameznika k čustvovanju in širši duhovnosti s ciljem oblikovanja novega zavedanja, ki zaobjema intuitivne procese, ustvarjalnost, domišljijo in večjo psihično občutljivost v odnosu do sebe in drugih.</p>
            
            <ButtonNextSection link={"/12-1-naloga"} nextSection={"12.1 Naloga"} />
        </div>     
    )
}

export default TransfromacijaMindseta;
