import React, { useState } from 'react';
import { naloga2_1_vrednote } from "../constants";
import { Alert, Button } from "react-bootstrap"
import "./assignments.css";

function Assignment2_1(props) {
    const [naloga2_1, set2_1] = useState(props.data);

    return (
        <div className="assignment">
            { naloga2_1.length === 5 && <Alert className="alert-fade-fix" variant="warning"><p>Izbrali ste že 5 vrednot</p></Alert> }
            
            <div className="obkrozi-vrednote">
                { naloga2_1_vrednote.map((item, index) => 
                    <div
                        key={index}
                        className={naloga2_1.includes(item) ? "circled" : "" }
                        onClick={ () => (naloga2_1.includes(item) ? set2_1(naloga2_1.filter((e) => e !== item)) : naloga2_1.length < 5 && set2_1(oldArray => [...oldArray, item]))} 
                    >
                        {item}
                    </div>
                ) }
            </div> 
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga02_1",naloga2_1)} >Shrani</Button>
        </div>
        
    )
}

export default Assignment2_1
