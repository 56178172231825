import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment10_2(props) {
    const [naloga10_2, set10_2] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga10_2} className="notebook-like" onChange={(e) => set10_2(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga10_2",naloga10_2)} >Shrani</Button>
        </div> 
    )
}

export default Assignment10_2;
