import React from 'react'
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./components.css";

function ButtonNextSection(props) {
    return (
        <div className="button-next-section">
            <Link to={props.link}>
                <Button variant="outline-dark" >
                    Nadaljujte na <br /> {props.nextSection}
                </Button>
            </Link>
        </div>
    )
}

export default ButtonNextSection
