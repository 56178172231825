import PageHeading from "../components/PageHeading"
import ASSIGNMENT13_1 from "../pages/assignments/Assignment13_1"

function naloga13_1(props) {
    return (
        <div>
            <PageHeading heading="13.1 Naloga" img="pencil" />

            <h5>Danes zvečer, preden zaspite, razmislite o svojih ciljih in željah ter vseh tistih vzgibih, ki ste jih občutili na današnji delavnici. Naslednje jutro, ko se boste zbudili, bodite zelo pozorni na svoje prve misli. Ne dovolite jim, da uidejo, ampak jih zadržite in zapišite na list papirja. Številni med vami bodo prepoznali svojo zamisel.</h5>

            <ASSIGNMENT13_1 handleSave={props.handleSave} data={props.data} />
            
        </div> 
    )
}

export default naloga13_1