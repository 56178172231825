import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT6_3 from "./assignments/Assignment6_3";

function naloga6_3(props) {
    return (
        <div>
            <PageHeading heading="6.3 Naloga" img="pencil" />

            <h5>Razmislite kako lahko izboljšate komunikacijo s to osebo, da bi jo bolje spoznali in dopolnili njeno kvantno strukturo?</h5>

            <ASSIGNMENT6_3 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/6-4-naloga"} nextSection={"6.4 Naloga"} />
        </div> 
    )
}

export default naloga6_3;