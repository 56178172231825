import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT4_1 from "./assignments/Assignment4_1";

function naloga4_1(props) {
    return (
        <div>
            <PageHeading heading="4.1 Naloga" img="pencil" />

            <h5>Poiščite in zapišite svoj moto. Stavek, ki vam vlije upanje in navda s pogumom. Ki vas drži pokonci in spodbuja, da vztrajate.</h5>

            <ASSIGNMENT4_1 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/5-osebna-kvantna-struktura"} nextSection={"5. Osebna kvantna struktura"} />
        </div> 
    )
}

export default naloga4_1;