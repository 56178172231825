import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./assignments.css";

function Assignment2_3(props) {
    const [naloga2_3, set2_3] = useState(props.data)

    return (
        <div className="assignment">
            <div>
                <textarea value={naloga2_3} className="notebook-like" onChange={(e) => set2_3(e.target.value)}>
                </textarea>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga02_3",naloga2_3)} >Shrani</Button>
        </div>
    )
}

export default Assignment2_3;
