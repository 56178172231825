import React, { useState } from 'react';
import "./assignments.css";
import { Form, Button } from "react-bootstrap";

function Assignment6_1(props) {
    const [naloga6_1, set6_1] = useState(props.data)

    return (
        <div className="assignment">
            <div className="table-row uppercase">
                <div className="square60 center-center-flex"><h4>Življenjska vloga</h4></div>
                <div className="square40 center-center-flex text-center"><h4>Vaša ocena <br />1-10</h4></div>
            </div>
            <div className="table-row">
                <div className="square60 task-input"><Form.Control value={naloga6_1.a1} onChange={(evt) => set6_1({ ...naloga6_1, a1: evt.target.value })} as="textarea" rows={2} /></div>
                <div className="square40 task-input">
                    <Form.Control as="select" value={naloga6_1.a2} className="large-number-input" onChange={(evt) => set6_1({ ...naloga6_1, a2: evt.target.value })}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square60 task-input"><Form.Control value={naloga6_1.b1} onChange={(evt) => set6_1({ ...naloga6_1, b1: evt.target.value })} as="textarea" rows={2} /></div>
                <div className="square40 task-input">
                    <Form.Control as="select" value={naloga6_1.b2} className="large-number-input" onChange={(evt) => set6_1({ ...naloga6_1, b2: evt.target.value })}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square60 task-input"><Form.Control value={naloga6_1.c1} onChange={(evt) => set6_1({ ...naloga6_1, c1: evt.target.value })} as="textarea" rows={2} /></div>
                <div className="square40 task-input">
                    <Form.Control as="select" value={naloga6_1.c2} className="large-number-input" onChange={(evt) => set6_1({ ...naloga6_1, c2: evt.target.value })}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square60 task-input"><Form.Control value={naloga6_1.d1} onChange={(evt) => set6_1({ ...naloga6_1, d1: evt.target.value })} as="textarea" rows={2} /></div>
                <div className="square40 task-input">
                    <Form.Control as="select" value={naloga6_1.d2} className="large-number-input" onChange={(evt) => set6_1({ ...naloga6_1, d2: evt.target.value })}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square60 task-input"><Form.Control value={naloga6_1.e1} onChange={(evt) => set6_1({ ...naloga6_1, e1: evt.target.value })} as="textarea" rows={2} /></div>
                <div className="square40 task-input">
                    <Form.Control as="select" value={naloga6_1.e2} className="large-number-input" onChange={(evt) => set6_1({ ...naloga6_1, e2: evt.target.value })}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                    </Form.Control>
                </div>
            </div>
            <div className="table-row">
                <div className="square60 task-input"><Form.Control value={naloga6_1.f1} onChange={(evt) => set6_1({ ...naloga6_1, f1: evt.target.value })} as="textarea" rows={2} /></div>
                <div className="square40 task-input">
                    <Form.Control as="select" value={naloga6_1.f2} className="large-number-input" onChange={(evt) => set6_1({ ...naloga6_1, f2: evt.target.value })}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                    </Form.Control>
                </div>
            </div>
            <Button className="button-save" variant="outline-success" onClick={() => props.handleSave("naloga06_1",naloga6_1)} >Shrani</Button>
        </div> 
    )
}

export default Assignment6_1;
