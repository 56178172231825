import React from 'react';
import ButtonNextSection from "../components/ButtonNextSection";
import PageHeading from "../components/PageHeading";
import ASSIGNMENT5_2 from "./assignments/Assignment5_2";

function naloga5_2(props) {
    return (
        <div>
            <PageHeading heading="5.2 Naloga" img="pencil" />

            <h5>Prikličite v misli svoje rojstno mestno. Kje se najrajši zadržujete? Kje vam je najprijetnejše? Kakšni so vaši občutki?</h5>

            <ASSIGNMENT5_2 handleSave={props.handleSave} data={props.data} />

            <ButtonNextSection link={"/5-3-naloga"} nextSection={"5.3 Naloga"} />
        </div> 
    )
}

export default naloga5_2;